/* eslint-disable no-unused-vars */
import {useCurrentAlertCondition} from "../../../../stores/zustand/AlertConditionStore";
import FrequencyConverter from "../../../../helpers/frequency-converter";

export const useBandAxisCut = ({options}) => {
    const currentAlert = useCurrentAlertCondition();

    const convertedBandFrom =
        FrequencyConverter.fromType(currentAlert.fftBandFrequencyType, +currentAlert.fftBandFrom, options.series?.[0]?.speed || 0).toHz().value || 0;

    const convertedBandTo =
        FrequencyConverter.fromType(currentAlert.fftBandFrequencyType, +currentAlert.fftBandTo, options.series?.[0]?.speed || 0).toHz().value || 1500;

    return {options: {...options, xAxis: {...options.xAxis, max: convertedBandTo, min: convertedBandFrom}}};
};
