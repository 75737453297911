/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import RmsOdTemplate from "./rms-od-template";
import EmailTemplate from "./email-template";
import SelectWrapper from "../../../helpers/select-wrapper";
import useGroupEmailUsersQuery from "../../../hooks/api/Alert/useGroupEmailUsersQuery";
import {get as _get} from "lodash";

const SimpleAlertTemplate = ({
    isCurrent,
    currentAxisId,
    inProgress,
    isChanged,
    isProfile,
    alert,
    alertType,
    cautionValue,
    users,
    alias,
    level,
    units,
    conditions,
    versionType,
    update,
    remove,
    reset,
    save,
    setCurrent,
    specs,
    validErrorMsg,
}) => {
    const [percentage, setPercentage] = useState("");

    useEffect(() => {
        calcPercentage();
    }, [alert.value, cautionValue]);

    const calcPercentage = (value, commit) => {
        const newPercentage = value || (cautionValue ? Math.round((-(cautionValue - alert.value) / (cautionValue / 100)) * 100) / 100 : 0).toString();
        if (commit) update({value: (Math.round((cautionValue / 100) * (100 + +newPercentage) * 10000) / 10000).toString()});
        setPercentage(newPercentage);
    };

    return (
        <div
            className={`form-group mb-3 alert-level-block-big alert-level-block${isCurrent ? " current" : ""}`}
            onClick={() => (isCurrent ? {} : setCurrent(alert))}
        >
            <div className="row flex-xl-nowrap justify-content-end align-items-start">
                <div className={"col-auto col-12 col-xl-auto flex-grow-1 mb-3 mb-xl-0"}>
                    <label className={level.name === "Caution" ? "level-label caution-level" : "level-label warning-level"}>{level.name || ""}</label>
                </div>
                <div className={level.name === "Caution" ? "col-12 col-md-6 col-xl-2 mb-3 mb-md-0" : "col-12 col-md-3 col-xl-2 mb-3 mb-md-0"}>
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.condition || ""}
                        onChange={(ev) => update({condition: ev.target.value})}
                        disabled={!isCurrent}
                        name={"level"}
                    >
                        {Object.keys(conditions || {}).map((key) => (
                            <option
                                key={key}
                                value={key}
                            >
                                {key}
                            </option>
                        ))}
                    </SelectWrapper>
                </div>
                {level.name !== "Caution" && currentAxisId > 0 && (
                    <div className={"col-12 col-md-4 col-xl-3 mb-3 mb-md-0"}>
                        <div className="input-group">
                            <input
                                className="form-control request-form-control"
                                type="number"
                                step={1}
                                value={percentage || ""}
                                title="A percentage of caution level"
                                onChange={(ev) => calcPercentage(ev.target.value, true)}
                                disabled={!isCurrent}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">%</span>
                            </div>
                        </div>
                    </div>
                )}
                <div className={level.name === "Caution" ? "col-12 col-md-6 col-xl-5" : "col-12 col-md-5 col-xl-5"}>
                    <div className={"input-group input-group-sm"}>
                        <input
                            className={`form-control request-form-control${isCurrent ? " current-alert-value" : ""} ${
                                alert.value === "" && validErrorMsg && isCurrent ? "is-invalid" : ""
                            }`}
                            type="number"
                            value={alert.value.toString() || ""}
                            step={0.1}
                            min={0}
                            onChange={(ev) => update({value: ev.target.value})}
                            disabled={!isCurrent}
                        />
                        {!!units && (
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">{alertType === "delta-t" ? "" + units : units}</span>
                            </div>
                        )}
                    </div>

                    {alert.value === "" && validErrorMsg && isCurrent && (
                        <span className="error-msg d-block color-danger mt-1 text-right">This field is required.</span>
                    )}
                </div>
            </div>
            <div className="row mt-2 align-items-start">
                <div className="col-12 col-md-5 mb-1 mb-md-0">
                    <span className="trigger-type label-horizontal-input d-block mt-2">Trigger Type</span>
                </div>
                <div className="col-12 col-md-7">
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.notificationType || ""}
                        onChange={(ev) => update({notificationType: +ev.target.value})}
                        disabled={!isCurrent}
                        name={"notification-type"}
                    >
                        <option value={1}>Alert only when reading exceeds the last active alert</option>
                        <option value={2}>Alert for every reading</option>
                    </SelectWrapper>
                </div>
            </div>
            <div className="row mt-2 align-items-start">
                <div className="col-md-5">
                    <span>
                        Consecutive Readings Required <br /> Before Trigger
                    </span>
                </div>
                <div className="col-12 col-md-7">
                    <input
                        className={`form-control request-form-control ${
                            alert.requireReadings === "" && validErrorMsg && isCurrent
                                ? "is-invalid"
                                : alert.requireReadings !== "" &&
                                  (alert.requireReadings < 1 || +alert.requireReadings !== parseInt("" + alert.requireReadings)) &&
                                  validErrorMsg &&
                                  isCurrent
                                ? "is-invalid"
                                : ""
                        }`}
                        value={alert.requireReadings || alert.requireReadings === 0 ? alert.requireReadings : ""}
                        onChange={(ev) =>
                            update({
                                requireReadings: ev.target.value === "" ? "" : +ev.target.value,
                                require_readings: ev.target.value === "" ? "" : +ev.target.value,
                            })
                        }
                        disabled={!isCurrent}
                        type={"number"}
                        min={1}
                    />
                    {alert.requireReadings === "" && validErrorMsg && isCurrent && (
                        <span className="error-msg d-block color-danger mt-1 text-right">This field is required.</span>
                    )}
                    {alert.requireReadings !== "" &&
                        (alert.requireReadings < 1 || +alert.requireReadings !== parseInt("" + alert.requireReadings)) &&
                        validErrorMsg &&
                        isCurrent && (
                            <span className="error-msg d-block color-danger mt-1 text-right">
                                This field only accepts integers and cannot be less than 1.
                            </span>
                        )}
                </div>
            </div>
            {alertType === "delta-t" && (
                <div className="row mt-2 align-items-start">
                    <div className="col-md-5">
                        <span className="d-block mt-2">Average Deviation Period</span>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className={"input-group input-group-sm"}>
                            <input
                                className={`form-control request-form-control ${
                                    alert.groupInterval === "" && validErrorMsg && isCurrent
                                        ? "is-invalid"
                                        : alert.groupInterval !== "" &&
                                          (alert.groupInterval < 1 || +alert.groupInterval !== parseInt("" + alert.groupInterval)) &&
                                          validErrorMsg &&
                                          isCurrent
                                        ? "is-invalid"
                                        : ""
                                }`}
                                value={alert.groupInterval ? alert.groupInterval : ""}
                                onChange={(ev) => update({groupInterval: ev.target.value})}
                                disabled={!isCurrent}
                                type={"number"}
                                min={1}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">hours</span>
                            </div>
                        </div>
                        {alert.groupInterval === "" && validErrorMsg && isCurrent && (
                            <span className="error-msg d-block color-danger mt-1 text-right">This field is required and cannot be less than 1.</span>
                        )}
                        {alert.groupInterval !== "" &&
                            (alert.groupInterval < 1 || +alert.groupInterval !== parseInt("" + alert.groupInterval)) &&
                            validErrorMsg &&
                            isCurrent && (
                                <span className="error-msg d-block color-danger mt-1 text-right">
                                    This field only accepts integers and cannot be less than 1.
                                </span>
                            )}
                    </div>
                </div>
            )}
            {alertType !== "delta-t" && (
                <RmsOdTemplate
                    alert={alert}
                    alias={alias}
                    versionType={versionType}
                    isCurrent={isCurrent}
                    update={update}
                    specs={specs}
                />
            )}
            {!isProfile && (
                <EmailTemplate
                    isCurrent={isCurrent}
                    users={users}
                    alert={alert}
                    level={level}
                    update={update}
                />
            )}
            <div>
                <AlertActions
                    inProgress={inProgress}
                    isCurrent={isCurrent}
                    isChanged={isChanged}
                    isProfile={isProfile}
                    reset={reset}
                    save={save}
                    level={level}
                    remove={remove}
                    alert={alert}
                />
            </div>
        </div>
    );
};

SimpleAlertTemplate.propTypes = {
    inProgress: PropTypes.bool,
    isCurrent: PropTypes.bool,
    currentAxisId: PropTypes.any,
    isChanged: PropTypes.bool,
    isProfile: PropTypes.bool,
    alertType: PropTypes.string,
    alias: PropTypes.string,
    units: PropTypes.string,
    users: PropTypes.array,
    alert: PropTypes.object,
    level: PropTypes.object,
    conditions: PropTypes.object,
    cautionValue: PropTypes.number,
    versionType: PropTypes.number,
    setCurrent: PropTypes.func,
    update: PropTypes.func,
    remove: PropTypes.func,
    reset: PropTypes.func,
    save: PropTypes.func,
    specs: PropTypes.object,
    validErrorMsg: PropTypes.bool,
};

export default SimpleAlertTemplate;

const AlertActions = ({reset, save, remove, isEnvelope, inProgress, isCurrent, isProfile, isChanged, alert, level}) => {
    const locationId = _get(alert, "installationPoint.equipment.location_id");
    const {isSuccess} = useGroupEmailUsersQuery({
        alertLevelId: +level.id,
        equipmentId: _get(alert, ["installationPoint", "equipment_id"]),
        instPointId: _get(alert, ["installationPointId"]),
        locationId: locationId,
        chartType: _get(alert, "chartType"),
    });
    if (!isSuccess) {
        return null;
    }

    return (
        <>
            {(alert.id || (isCurrent && isChanged)) && <hr className="my-2" />}

            <div className="row controls-button-alerts-level">
                <div className="col-md-12">
                    {isCurrent && isChanged && (
                        <React.Fragment>
                            <button
                                className="btn btn-sm btn-primary pull-right"
                                style={{padding: "4px 8px"}}
                                disabled={isEnvelope ? !!inProgress || (alert.fftEnvelopeData && alert.fftEnvelopeData.length <= 0) : !!inProgress}
                                onClick={save}
                            >
                                {isEnvelope ? (alert.fftEnvelopeData && alert.fftEnvelopeData.length > 0 ? "Save" : "Calculating...") : "Save"}
                            </button>
                            <button
                                className="btn btn-sm btn-secondary pull-right"
                                style={{marginRight: 10, padding: "4px 8px"}}
                                onClick={reset}
                            >
                                Cancel
                            </button>
                        </React.Fragment>
                    )}
                    {(alert.id || isProfile) && (
                        <button
                            className="btn btn-sm btn-danger pull-right btn-remove"
                            style={{padding: "4px 8px"}}
                            onClick={remove}
                        >
                            Delete
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

AlertActions.propTypes = {
    reset: PropTypes.func,
    save: PropTypes.func,
    remove: PropTypes.func,
    isEnvelope: PropTypes.bool,
    isCurrent: PropTypes.bool,
    inProgress: PropTypes.bool,
    isProfile: PropTypes.bool,
    isChanged: PropTypes.bool,
    alert: PropTypes.object,
    level: PropTypes.object,
};
