import React from "react";
import {Modal} from "../../../../shared";
import PropTypes from "prop-types";

import iosQr from "../../../../assets/media/apps/ios-qr.png";
import appStore from "../../../../assets/media/apps/appStore.png";
import androidQr from "../../../../assets/media/apps/android-qr.png";
import googlePlay from "../../../../assets/media/apps/googlePlay.png";

import styles from "./style.module.scss";

const appInfo = [
    {
        link: "https://apps.apple.com/us/app/waites-self-service-dashboard/id1540773169",
        qrCode: iosQr,
        img: appStore,
        text: "Install our Self-Service Dashboard mobile app on your iOS device.",
    },
    {
        link: "https://play.google.com/store/apps/details?id=com.waites.app&hl=en",
        qrCode: androidQr,
        img: googlePlay,
        text: "Install our Self-Service Dashboard mobile app on your Android device.",
    },
];

const AppModal = ({showModal, onClose}) => {
    return (
        <Modal
            showModal={showModal}
            onClose={onClose}
            size="lg"
            title={"App"}
            withoutFooter={true}
            className="app-modal"
        >
            <div className={styles.modal}>
                {appInfo.map((res, i) => (
                    <div
                        key={i}
                        className={styles.modalItem}
                    >
                        <a
                            href={res.link}
                            target="_blank"
                            className={styles.modalLink}
                        >
                            <img
                                src={res.qrCode}
                                alt=""
                                className={styles.modalImg}
                            />
                            <img
                                src={res.img}
                                alt=""
                                className={styles.modalImg}
                            />
                        </a>
                        <div className={styles.modalBlock}>
                            <p className={styles.modalText}>{res.text}</p>
                            <a
                                href={res.link}
                                target="_blank"
                                className={styles.modalBtn}
                            >
                                download
                            </a>
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

AppModal.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
};

export default AppModal;
