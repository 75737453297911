import {filter as _filter} from "lodash";
import moment from "moment/moment";
import {FREQUENCY_TYPES, SERVER_DATETIME_FORMAT} from "../../../../constants/constants";
import {isEqual as _isEqual} from "lodash";
import {useChartComputed, useChartType, useIsTachometer} from "./chart";
import {useInstallationPointIds} from "../../../../hooks/helpers/useInstallationPointIds";
import {useInstallationPoint} from "../useInstallationPoint";
import {useAlertConditionListQuery} from "../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useCurrentAlertGroup} from "../../../../stores/zustand/AlertConditionStore";
import useAlertLevelsConditionsListQuery from "../../../../hooks/api/Alert/useAlarmConditionLevelsListQuery";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";

export const isEqualSimpleAlerts = (alerts) =>
    alerts.length === 3 &&
    alerts[0].condition === alerts[1].condition &&
    alerts[0].condition === alerts[2].condition &&
    alerts[0].value === alerts[1].value &&
    alerts[0].value === alerts[2].value &&
    alerts[0].notificationType === alerts[1].notificationType &&
    alerts[0].notificationType === alerts[2].notificationType &&
    alerts[0].rmsOd === alerts[1].rmsOd &&
    alerts[0].rmsOd === alerts[2].rmsOd &&
    alerts[0].f_max === alerts[1].f_max &&
    alerts[0].f_max === alerts[2].f_max &&
    alerts[0].lines_of_resolution === alerts[1].lines_of_resolution &&
    alerts[0].lines_of_resolution === alerts[2].lines_of_resolution &&
    alerts[0].email === alerts[1].email &&
    alerts[0].email === alerts[2].email &&
    _isEqual(alerts[0].emailUsers, alerts[1].emailUsers) &&
    _isEqual(alerts[0].emailUsers, alerts[2].emailUsers);

export const useSetAlert = (chartTypes, alertType) => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {isChartFFT, chartType, fftAlertType, isEnvelope, isBand} = useChartType(chartTypes);
    const {axisId, readingType} = useChartComputed(chartTypes, equipment);
    const isTachometer = useIsTachometer(alertType);

    const installationPoint = useInstallationPoint(equipment, chartType);

    const installationPointId = installationPoint.id;
    const installationPointIds = useInstallationPointIds(equipment);

    const currentGroup = useCurrentAlertGroup();

    const {alertConditions} = useAlertConditionListQuery(chartType, installationPointIds);
    const {alertLevelsConditionsList: conditions} = useAlertLevelsConditionsListQuery();

    const setAlert = (level, list) => {
        list = list || alertConditions;

        if (!isChartFFT && +axisId === 0) {
            const alerts = _filter(list, (item) => +item.alert_level_id === +level.id && item.installationPointId === installationPointId);
            if (isEqualSimpleAlerts(alerts)) return {...alerts[0], id: undefined};
        }

        let alert = {
            axis_id: axisId,
            groupId: currentGroup.id || false,
            alertLevel: level,
            alertLevelId: level.id,
            alert_level_id: level.id,
            sms: "0",
            email: "0",
            smsUsers: [],
            emailUsers: [],
            messenger: "0",
            phone: "0",
            chartType,
            installationPointId,
            installationPoint: {
                ...installationPoint,
                equipment: {
                    location_id: equipment.location_id,
                },
            },
            reading_type_id: readingType,
            ...(isChartFFT ? {fftAlertType} : {}),
            axisId,
            rmsOd: "0",
            f_max: undefined,
            lines_of_resolution: undefined,
            groupInterval: 1,
        };

        if (isEnvelope) {
            alert = {
                ...alert,
                existingEnvelopeData: [],
                fftEnvelopeType: "1",
                fftEnvelopePercent: "5",
                fftEnvelopeCalcType: "1",
                fftEnvelopeStd: "1",
                fftEnvelopeSpectralWindow: "0",
                fftEnvelopeSpectralWindowWidth: "0",
                fftEnvelopeMinimum: "0",
                fftEnvelopeMinimumValue: "0",
                followingType: 1,
                avgLastRCount: "",
                avgLastDCount: "",
                avgDateFrom: moment().format(SERVER_DATETIME_FORMAT),
                avgDateTo: moment().format(SERVER_DATETIME_FORMAT),
            };
        } else {
            alert = {
                ...alert,
                ...(isBand
                    ? {
                          fftBandFrequencyType: FREQUENCY_TYPES.HZ,
                          fftBandFrom: "0",
                          fftBandTo: "1500",
                          index: 0,
                      }
                    : {}),
                ...(isTachometer
                    ? {
                          isTachometerRpm: "1",
                          is_tachometer_rpm: "1",
                          tachometerRpmFrom: "",
                          tachometerRpmTo: "",
                          index: 0,
                      }
                    : {}),
                condition: Object.keys(conditions || {})[0] || "",
                value: "",
                notificationType: 1,
                requireReadings: 1,
            };
        }

        return alert;
    };

    return setAlert;
};
