import React, {Component, useState} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {find as _find, get as _get, filter as _filter, includes as _includes} from "lodash";
import EquipmentProfileApi from "../../../api/equipmentProfile";
import AlertLevelApi from "../../../api/alertLevel";
import ApiReadingTypes from "../../../api/readingTypes";
import {ALERT_LEVELS_LIGHTER_COLORS, FREQUENCY_TYPES, SPEED_TYPES} from "../../../constants/constants";
import Loader from "../../../shared/loader/loader";
import "../../../assets/scss/components/equipment/equipment-profile.scss";
import {Link} from "react-router-dom";
import Toast from "../../shared/toast";
import Helper from "../../../helpers/helper";
import {withGlobalStore} from "../../../stores/GlobalStore";
import styles from "./updateEquip.module.scss";

const conditionMap = {
    ">=": ">",
    "<=": "<",
};

class CompareUpdateEquipment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profileId: null,
            alertLevels: [],
            readingTypes: [],
            profileEquipment: {},
            profileInfo: {},
            inProgress: false,
            loader: true,
        };
    }

    componentDidMount() {
        Promise.all([this.fetchProfileInfo(), this.fetchEquipmentInfo(), this.fetchAlertLevels(), this.fetchReadingTypes()]).then(() =>
            this.setState({loader: false})
        );
    }

    fetchAlertLevels() {
        return AlertLevelApi.getList().then((response) => {
            if (response) {
                const {list} = response;
                this.setState({alertLevels: list || []});
            }
        });
    }

    fetchReadingTypes() {
        return ApiReadingTypes.forAlerts().then((response) => {
            if (response) {
                const {list} = response;
                this.setState({readingTypes: _filter(list, (type) => !_includes(["dis", "cf"], _get(type, "alias")))});
            }
        });
    }

    fetchProfileInfo() {
        const InfoId = this.props.match.params.InfoId;
        return EquipmentProfileApi.getInfo(InfoId).then((response) => {
            if (response) {
                this.setState({
                    profileInfo: _get(response, "profile", {}),
                });
            }
        });
    }

    fetchEquipmentInfo() {
        const EquipmentId = this.props.match.params.EquipmentId;
        return EquipmentProfileApi.getEquipmentInfo(EquipmentId).then((response) => {
            if (response) {
                this.setState({
                    profileEquipment: _get(response, "info", []),
                });
            }
        });
    }

    getAlert(alertConditions, axisId, alertLevelId, readingTypeId) {
        return (
            _find(
                alertConditions,
                (installationPoint) =>
                    +installationPoint.axis_id === axisId &&
                    +installationPoint.alert_level_id === alertLevelId &&
                    +installationPoint.reading_type_id === readingTypeId
            ) || {}
        );
    }

    updateEquipment(equipmentId) {
        const {id: profileId} = this.state.profileInfo;
        this.setState({inProgress: true});
        EquipmentProfileApi.updateEquipmentProfile(profileId, equipmentId)
            .then(() => {
                Toast.success("The equipment has been updated.");
                this.props.history.push("/equipment-profile");
            })
            .catch(() => {
                this.setState({inProgress: false});
            });
    }

    render() {
        const {profileEquipment, profileInfo, inProgress, alertLevels, readingTypes, loader} = this.state;
        const {chartTypes} = this.props;

        return (
            <div className="wrapper-equipment-compare custom-scroll">
                <div className="subheader">
                    <div className="subheader-title">Update Equipment Settings</div>
                </div>
                {loader ? (
                    <Loader />
                ) : (
                    <div className="block">
                        <div className="expl px-4 py-3">
                            Note: This page allows you to update the equipment based on the equipment profile. Differences are highlighted in the right
                            column using the following colors:
                        </div>
                        <div className="block-label">
                            <div className="nodes-ports">
                                <div className="nodes-ports-item">
                                    <div className={"nodes-ports-badge badge will-create"} />
                                    <div className="nodes-ports-label">- will be added</div>
                                </div>
                                <div className="nodes-ports-item">
                                    <div className={"nodes-ports-badge badge will-change"} />
                                    <div className="nodes-ports-label">- will be changed</div>
                                </div>
                                <div className="nodes-ports-item">
                                    <div className={"nodes-ports-badge badge will-delete"} />
                                    <div className="nodes-ports-label">- will be deleted</div>
                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="block-body">
                            <div className="custom-compare">
                                <div className="profile">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="w-50">Equipment Profile - {_get(profileInfo, "name")}</h5>
                                        <h5 className="w-50">Equipment - {_get(profileEquipment, "name")}</h5>
                                    </div>

                                    <GeneralStep
                                        profile={profileInfo}
                                        equipment={profileEquipment}
                                        chartTypes={chartTypes}
                                    />

                                    <InstallationPointsStep
                                        profile={profileInfo}
                                        equipment={profileEquipment}
                                        readingTypes={readingTypes}
                                        alertLevels={alertLevels}
                                    />

                                    <span className={"ml-2"}>
                                        * Note: Alert conditions that are in the equipment - but not in the equipment profile - will not be deleted.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end controls-btns-equipment">
                            <Link
                                onClick={() => this.props.history.goBack()}
                                className={"btn btn-secondary mr-4" + (inProgress ? " disabled" : "")}
                            >
                                Cancel
                                {!!inProgress && <i className="fa fa-spinner ml-2" />}
                            </Link>
                            <div
                                // to="/equipment-profile"
                                className={"btn btn-primary" + (inProgress ? " disabled" : "")}
                                onClick={() => this.updateEquipment(this.props.match.params.EquipmentId)}
                            >
                                Update
                                {!!inProgress && <i className="fa fa-spinner ml-2" />}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const GeneralStep = ({profile, equipment, chartTypes}) => {
    let speedPartFields = [{key: "equipmentSpeedTypeName", label: "Speed Type"}];

    if (+_get(profile, "equipmentSpeedTypeId") === SPEED_TYPES.VARIABLE) {
        speedPartFields.push({key: "speedMin", label: "Minimum RPM", onlyForProfile: +_get(equipment, "equipmentSpeedTypeId") !== SPEED_TYPES.VARIABLE});
        speedPartFields.push({key: "speedMax", label: "Maximum RPM", onlyForProfile: +_get(equipment, "equipmentSpeedTypeId") !== SPEED_TYPES.VARIABLE});
    } else {
        speedPartFields.push({
            key: "currentSpeed",
            label: +_get(profile, "equipmentSpeedTypeId") === SPEED_TYPES.FIXED ? "Default Running Speed" : "Base Running Speed",
        });
    }

    const generalStepFields = [
        {
            partTitle: "Basic",
            partFields: [{key: "equipmentTypeName", label: "Equipment Type"}],
        },
        {
            partTitle: "Idle Threshold",
            partFields: [
                {key: "idleThresholdType", label: "Idle Threshold Type"},
                {key: "idleThreshold", label: "Idle Threshold"},
            ],
        },
        {
            partTitle: "Speed settings",
            partFields: speedPartFields,
        },
    ];

    const getValue = (field, object) => {
        let key = _get(field, "key");
        let value = _get(object, key, "---");

        if (value && key === "idleThreshold") {
            value = value + " " + Helper.getIdleThresholdMeasure(chartTypes, _get(object, "idleThresholdType"));
        }

        return value;
    };

    return (
        <React.Fragment>
            <div className="subheader">
                <div className="subheader-title">General</div>
                <div className="clear" />
            </div>
            <div className={"general-block pl-2 pr-2"}>
                {generalStepFields.map((part, index) => (
                    <div
                        key={index}
                        className={"part"}
                    >
                        <div className="d-flex justify-content-between">
                            <div className={equipment ? "w-50" : ""}>
                                <div className="font-weight-bold text-uppercase">{_get(part, "partTitle", "")}</div>
                            </div>
                            {!!equipment && (
                                <div className="w-50">
                                    <div className="font-weight-bold text-uppercase">{_get(part, "partTitle", "")}</div>
                                </div>
                            )}
                        </div>
                        {_get(part, "partFields", []).map((field, fieldIndex) => (
                            <EquipmentField
                                key={fieldIndex}
                                field={field}
                                profileValue={getValue(field, profile)}
                                equipmentValue={getValue(field, equipment)}
                                withEquipment={!!equipment}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

GeneralStep.propTypes = {
    profile: PropTypes.object,
    equipment: PropTypes.object,
    chartTypes: PropTypes.array,
};

const EquipmentField = ({field, profileValue, equipmentValue, withEquipment}) => {
    let className = equipmentValue !== profileValue ? "will-change" : "";

    return (
        <div className="d-flex justify-content-between">
            <div className={withEquipment ? "w-50" : ""}>
                <ValueBlock
                    label={_get(field, "label", "")}
                    value={profileValue || "---"}
                />
            </div>
            {!!withEquipment && !_get(field, "onlyForProfile") && (
                <div className="w-50">
                    <ValueBlock
                        label={_get(field, "label", "")}
                        value={equipmentValue || "---"}
                        className={className}
                        title={"This setting will be changed to " + (profileValue || "---")}
                    />
                </div>
            )}
        </div>
    );
};

EquipmentField.propTypes = {
    field: PropTypes.object,
    profileValue: PropTypes.object,
    equipmentValue: PropTypes.object,
    withEquipment: PropTypes.bool,
};

const InstallationPointsStep = ({profile, equipment, alertLevels, readingTypes}) => {
    const profileInstallationPoints = _get(profile, "profileInstallationPoints", []);
    const equipmentInstallationPoints = _get(equipment, "installationPoints", []);

    return (
        <React.Fragment>
            <div className="subheader">
                <div className="subheader-title">Installation Points</div>
                <div className="clear" />
            </div>
            {profileInstallationPoints.map((installationPoint, index) => (
                <InstallationPointRow
                    key={index}
                    profileInstallationPoint={installationPoint}
                    equipmentInstallationPoint={_find(equipmentInstallationPoints, {profile_inst_point_id: _get(installationPoint, "id")}) || {}}
                    alertLevels={alertLevels}
                    readingTypes={readingTypes}
                    withEquipment={!!equipment}
                    profile={profile}
                    equipment={equipment}
                />
            ))}
        </React.Fragment>
    );
};

InstallationPointsStep.propTypes = {
    profile: PropTypes.object,
    equipment: PropTypes.object,
    alertLevels: PropTypes.array,
    readingTypes: PropTypes.array,
};

const InstallationPointRow = ({profileInstallationPoint, equipmentInstallationPoint, alertLevels, readingTypes, withEquipment, profile, equipment}) => {
    const [openInstallationPoint, setOpenInstallationPoint] = useState(false);

    const profileBearings = _get(profileInstallationPoint, "profileBearings", []);
    const equipmentBearings = _get(equipmentInstallationPoint, "installationPointBearing", []);

    const profileAlerts = _get(profileInstallationPoint, "profileAlertConditions", []);
    const equipmentAlerts = _get(equipmentInstallationPoint, "alertCondition", []);

    const profileName = _get(profileInstallationPoint, "name");
    const equipmentName = _get(equipmentInstallationPoint, "name");

    const profileType = _get(profileInstallationPoint, "installationPointCustomTypeName");
    const equipmentType = _get(equipmentInstallationPoint, "installationPointCustomTypeName");

    const isSpeedProfileFixed = +_get(profile, "equipmentSpeedTypeId") === SPEED_TYPES.FIXED;
    const isSpeedEquipmentFixed = +_get(equipment, "equipmentSpeedTypeId") === SPEED_TYPES.FIXED;

    const profileSpeed = isSpeedProfileFixed ? _get(profileInstallationPoint, "speed") : _get(profileInstallationPoint, "speed_ratio");
    const equipmentSpeed = isSpeedEquipmentFixed ? _get(equipmentInstallationPoint, "speed") : _get(equipmentInstallationPoint, "speed_ratio");

    return (
        <div className="d-flex justify-content-around">
            {Object.keys(profileInstallationPoint).length !== 0 ? (
                <div className={"inst-point-block position-relative"}>
                    <ValueBlock
                        label={"Name"}
                        value={profileName || "---"}
                    />

                    {(withEquipment || openInstallationPoint) && (
                        <React.Fragment>
                            <ValueBlock
                                label={"Type"}
                                value={profileType || "---"}
                            />

                            <ValueBlock
                                label={isSpeedProfileFixed ? FREQUENCY_TYPES.CPM + " Value" : "Ratio Value"}
                                value={profileSpeed || "---"}
                            />

                            <ProfileBearingsBlock
                                profileBearings={profileBearings}
                                equipmentBearings={equipmentBearings}
                            />

                            <ProfileAlertsBlock
                                profileAlerts={profileAlerts}
                                alertLevels={alertLevels}
                                readingTypes={readingTypes}
                            />
                        </React.Fragment>
                    )}

                    {!withEquipment && (
                        <div
                            className={"modal-installation-dropdown" + (openInstallationPoint ? " top" : "")}
                            onClick={() => setOpenInstallationPoint(!openInstallationPoint)}
                        />
                    )}
                </div>
            ) : (
                <div className={"inst-point-block empty"} />
            )}

            {withEquipment && (
                <React.Fragment>
                    {Object.keys(equipmentInstallationPoint).length !== 0 ? (
                        <div
                            className={"inst-point-block" + (!profileInstallationPoint ? " will-delete" : "")}
                            title={!profileInstallationPoint ? "This information will be deleted." : ""}
                        >
                            <ValueBlock
                                label={"Name"}
                                value={equipmentName || "---"}
                            />

                            <ValueBlock
                                label={"Type"}
                                value={equipmentType || "---"}
                                className={profileType !== equipmentType ? "will-change" : ""}
                                title={profileType !== equipmentType ? "Will be change to " + profileType : ""}
                            />

                            <ValueBlock
                                label={isSpeedEquipmentFixed ? FREQUENCY_TYPES.CPM + " Value" : "Ratio Value"}
                                value={equipmentSpeed || "---"}
                                className={profileSpeed !== equipmentSpeed || isSpeedProfileFixed !== isSpeedEquipmentFixed ? "will-change" : ""}
                                title={
                                    profileSpeed !== equipmentSpeed || isSpeedProfileFixed !== isSpeedEquipmentFixed
                                        ? "Will be change to " + (isSpeedProfileFixed ? FREQUENCY_TYPES.CPM + " Value " : "Ratio Value ") + profileSpeed
                                        : ""
                                }
                            />

                            <EquipmentBearingsBlock
                                profileBearings={profileBearings}
                                equipmentBearings={equipmentBearings}
                            />

                            <ProfileAlertsBlock
                                profileAlerts={profileAlerts}
                                alertLevels={alertLevels}
                                readingTypes={readingTypes}
                                equipmentAlerts={equipmentAlerts}
                            />
                        </div>
                    ) : (
                        <div
                            className={"inst-point-block will-create"}
                            title={"Details from the equipment profile will be added."}
                        />
                    )}
                </React.Fragment>
            )}
        </div>
    );
};

InstallationPointRow.propTypes = {
    profileInstallationPoint: PropTypes.object,
    equipmentInstallationPoint: PropTypes.object,
    alertLevels: PropTypes.array,
    readingTypes: PropTypes.array,
    withEquipment: PropTypes.bool,
    profile: PropTypes.object,
    equipment: PropTypes.object,
};

const ProfileBearingsBlock = ({profileBearings, equipmentBearings}) => {
    return (
        <React.Fragment>
            <div className={"d-flex p-2 equipment"}>
                <div className="basic-name pr-1">Bearings:</div>
                {!profileBearings.length && <div className="basic-value font-weight-bold">No Bearings associated</div>}
            </div>
            {profileBearings.map((profileBearing, index) => (
                <div
                    className={"bearing-block"}
                    key={index}
                >
                    <BearingValues bearing={profileBearing} />
                </div>
            ))}
            {equipmentBearings.map((equipmentBearing, index) => {
                if (!_get(equipmentBearing, "profile_id") || !_find(profileBearings, {id: _get(equipmentBearing, "profile_bearing_id")})) {
                    return (
                        <div
                            key={index}
                            className={"bearing-block invisible"}
                        />
                    );
                }

                return "";
            })}
        </React.Fragment>
    );
};

ProfileBearingsBlock.propTypes = {
    profileBearings: PropTypes.array,
    equipmentBearings: PropTypes.array,
};

const EquipmentBearingsBlock = ({profileBearings, equipmentBearings}) => {
    return (
        <React.Fragment>
            <div className={"d-flex p-2 equipment"}>
                <div className="basic-name pr-1">Bearings:</div>
                {!profileBearings.length && !equipmentBearings.length && <div className="basic-value font-weight-bold">No Bearings associated</div>}
            </div>

            {profileBearings.map((profileBearing, index) => {
                const equipmentBearing = _find(equipmentBearings, {profile_bearing_id: _get(profileBearing, "id")});

                if (!equipmentBearing) {
                    return (
                        <div
                            key={index}
                            className={"bearing-block will-create"}
                            title={"Details from the equipment profile will be added."}
                        />
                    );
                }

                let className = "";
                let title = "";
                if (
                    _get(profileBearing, "bearing_id") !== _get(equipmentBearing, "bearing_id") ||
                    _get(profileBearing, "plot_lines_count") !== _get(equipmentBearing, "plot_lines_count")
                ) {
                    className = " will-change";
                    title = "Will be update from profile";
                }

                return (
                    <div
                        key={index}
                        className={"bearing-block" + (className ? className : "")}
                        title={title ? title : ""}
                    >
                        <BearingValues
                            key={index}
                            bearing={equipmentBearing}
                        />
                    </div>
                );
            })}

            {equipmentBearings.map((equipmentBearing, index) => {
                if (!_get(equipmentBearing, "profile_id") || !_find(profileBearings, {id: _get(equipmentBearing, "profile_bearing_id")})) {
                    return (
                        <div
                            key={index}
                            className={"bearing-block will-delete"}
                            title={"This information will be deleted."}
                        >
                            <BearingValues
                                bearing={equipmentBearing}
                                key={index}
                            />
                        </div>
                    );
                }

                return "";
            })}
        </React.Fragment>
    );
};

EquipmentBearingsBlock.propTypes = {
    profileBearings: PropTypes.array,
    equipmentBearings: PropTypes.array,
};

const BearingValues = ({bearing}) => {
    const bearingFields = [
        {key: "bearing.name", label: "Name"},
        {key: "bearing.mfr", label: "Manufacturer"},
        {key: "bearing.balls_rollers", label: "Balls/Rollers"},
        {key: "bearing.ftf", label: "FTF"},
        {key: "bearing.bsf", label: "BSF"},
        {key: "bearing.bpfo", label: "BPFO"},
        {key: "bearing.bpfi", label: "BPFI"},
        {key: "plot_lines_count", label: "Plot Lines"},
    ];

    return (
        <React.Fragment>
            {bearingFields.map((field, fieldIndex) => (
                <ValueBlock
                    key={fieldIndex}
                    value={_get(bearing, _get(field, "key"))}
                    label={_get(field, "label")}
                />
            ))}
        </React.Fragment>
    );
};

BearingValues.propTypes = {
    bearing: PropTypes.object,
};

const ProfileAlertsBlock = ({profileAlerts, alertLevels, readingTypes, equipmentAlerts}) => {
    const getAlert = ({alertConditions, alertLevelId, readingTypeId, condition}) => {
        return (
            _find(
                alertConditions,
                (alertCondition) =>
                    +alertCondition.alert_level_id === alertLevelId &&
                    +alertCondition.reading_type_id === readingTypeId &&
                    (condition ? alertCondition.condition === condition || alertCondition.condition === conditionMap[condition] : true)
            ) || {}
        );
    };

    return (
        <React.Fragment>
            <div className={"p-2"}>
                <div className="basic-name pr-1">Measurement Alerts:</div>
            </div>

            <div className="d-flex justify-content-around flex-wrap">
                {readingTypes.map((type, index) => (
                    <div
                        className="pr-2 alert-type-block"
                        key={index}
                    >
                        <div className="pb-2">{_get(type, "label")}</div>
                        <div>
                            {Object.keys(_get(type, "axis", {})).map((axisId, index) => {
                                const alertsPerCondition = (equipmentAlerts ? [">=", "<="] : [null]).map((condition) => {
                                    return (
                                        <div
                                            key={index}
                                            className={styles.badgeWrapper}
                                        >
                                            {alertLevels.map((level, index) => {
                                                const unit = _get(type, "unit");
                                                const emptyColor = _get(ALERT_LEVELS_LIGHTER_COLORS, _get(level, "name"));
                                                const styleEmpty = {
                                                    background: emptyColor ? emptyColor : "#fff",
                                                    color: _get(level, "color"),
                                                    border: "1px solid " + _get(level, "color"),
                                                };
                                                const readingTypeId = _get(type, "axis." + axisId + ".readingType");

                                                const equipmentAlertCondition = equipmentAlerts
                                                    ? getAlert({
                                                          alertConditions: equipmentAlerts,
                                                          alertLevelId: +level.id,
                                                          readingTypeId: +readingTypeId,
                                                          condition,
                                                      })
                                                    : null;

                                                const profileAlertCondition = getAlert({
                                                    alertConditions: profileAlerts,
                                                    alertLevelId: +level.id,
                                                    readingTypeId: +readingTypeId,
                                                    condition,
                                                });

                                                const profileValue = _get(profileAlertCondition, "value");
                                                const equipmentValue = _get(equipmentAlertCondition, "value");

                                                const value = equipmentAlerts ? equipmentValue : profileValue;

                                                let className = "";
                                                if (Object.values(profileAlertCondition || {})?.length) {
                                                    if (profileValue && !equipmentValue && condition === profileAlertCondition?.condition) {
                                                        className = " will-create";
                                                    } else if (
                                                        profileValue &&
                                                        profileValue !== equipmentValue &&
                                                        condition === equipmentAlertCondition?.condition
                                                    ) {
                                                        className = " will-change";
                                                    }
                                                }

                                                let title = "";
                                                if (Object.values(profileAlertCondition || {})?.length) {
                                                    if (profileValue && !equipmentValue && condition === profileAlertCondition?.condition) {
                                                        title = "Details from the equipment profile will be added.";
                                                    } else if (
                                                        profileValue &&
                                                        profileValue !== equipmentValue &&
                                                        condition === equipmentAlertCondition?.condition
                                                    ) {
                                                        title = "This setting will be changed to " + profileValue;
                                                    }
                                                }

                                                return (
                                                    <>
                                                        {equipmentAlerts ? <span>{condition}</span> : <span>{profileAlertCondition.condition}</span>}

                                                        <div
                                                            key={index}
                                                            className={`m-1 pt-3 pb-3 pl-1 pr-1 ${className ? className : ""}`}
                                                            title={title}
                                                        >
                                                            <span
                                                                className={"alert-level"}
                                                                style={value ? {background: _get(level, "color")} : styleEmpty}
                                                                key={index}
                                                            >
                                                                <span>{value ? value : String.fromCharCode(8212)}</span>
                                                                {!!value && <span className={"ml-1"}>{unit}</span>}
                                                            </span>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    );
                                });

                                return (
                                    <div
                                        className={"alert-axis pb-3 pt-1"}
                                        key={index}
                                    >
                                        <span className={"axis-name"}>{_get(type, "axis." + axisId + ".axisName", "")}</span>
                                        {alertsPerCondition}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

ProfileAlertsBlock.propTypes = {
    profileAlerts: PropTypes.array,
    alertLevels: PropTypes.array,
    readingTypes: PropTypes.array,
    equipmentAlerts: PropTypes.array,
};

const ValueBlock = ({label, value, className, title}) => {
    return (
        <div
            className={"d-flex p-2 " + (className ? className : "")}
            title={title ? title : ""}
        >
            <div className="basic-name pr-1">{label}:</div>
            <div className="basic-value font-weight-bold">{value}</div>
        </div>
    );
};

ValueBlock.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
};

const UpdateButton = ({profileInfo, equipment, inProgress}) => {
    const equipmentFields = ["currentSpeed", "equipmentSpeedTypeId", "equipmentTypeId", "idleThreshold", "idleThresholdType", "speedMax", "speedMin"];

    const alertFields = ["condition", "notification_type", "value"];

    const getDiffExist = (equipment) => {
        for (let field of equipmentFields) {
            if (profileInfo[field] !== equipment[field]) {
                return true;
            }
        }

        for (let profileInstallationPoint of profileInfo.profileInstallationPoints) {
            const installationPoint = _find(equipment.installationPoints, {profile_inst_point_id: profileInstallationPoint.id});

            if (
                installationPoint &&
                profileInstallationPoint["installation_point_custom_type_id"] !== installationPoint["installation_point_custom_type_id"]
            ) {
                return true;
            }

            if (
                installationPoint &&
                (profileInstallationPoint["speed"] !== installationPoint["speed"] ||
                    profileInstallationPoint["speed_ratio"] !== installationPoint["speed_ratio"])
            ) {
                return true;
            }

            if (installationPoint) {
                for (let profileAlert of profileInstallationPoint.profileAlertConditions) {
                    const alert = _find(
                        installationPoint.alertCondition,
                        (alertCondition) =>
                            +alertCondition.alert_level_id === +profileAlert.alert_level_id &&
                            +alertCondition.reading_type_id === +profileAlert.reading_type_id &&
                            alertCondition.condition === profileAlert.condition
                    );

                    if (alert) {
                        for (let field of alertFields) {
                            if (profileAlert[field] !== alert[field]) {
                                return true;
                            }
                        }
                    } else {
                        return true;
                    }
                }

                for (let profileBearing of profileInstallationPoint.profileBearings) {
                    const bearing = _find(installationPoint.installationPointBearing, {profile_bearing_id: profileBearing.id});

                    if (
                        bearing &&
                        (profileBearing["bearing_id"] !== bearing["bearing_id"] || profileBearing["plot_lines_count"] !== bearing["plot_lines_count"])
                    ) {
                        return true;
                    } else if (!bearing) {
                        return true;
                    }
                }

                for (let equipmentBearing of installationPoint.installationPointBearing) {
                    const profileBearing = _find(profileInstallationPoint.profileBearings, {id: equipmentBearing.profile_bearing_id});
                    if (!profileBearing) {
                        return true;
                    }
                }
            } else {
                return true;
            }
        }

        for (let installationPoint of equipment.installationPoints) {
            if (installationPoint.profile_inst_point_id) {
                const profileInstallationPoint = _find(profileInfo.profileInstallationPoints, {id: installationPoint.profile_inst_point_id});
                if (!profileInstallationPoint) {
                    return true;
                }
            }
        }

        return false;
    };

    const diffExist = getDiffExist(equipment);

    return diffExist ? (
        <Link
            to={"/compare/" + _get(profileInfo, "id") + "/" + _get(equipment, "id")}
            className={"btn btn-sm mr-1 mb-1" + (diffExist ? " btn-success" : " btn-secondary") + (inProgress ? " disabled" : "")}
            title={"View Differences"}
        >
            View Differences
        </Link>
    ) : (
        <span className={"mr-2 mb-1 btn-sm"}>No Differences</span>
    );
};

UpdateButton.propTypes = {
    profileInfo: PropTypes.object,
    equipment: PropTypes.object,
    inProgress: PropTypes.bool,
};

CompareUpdateEquipment.propTypes = {
    profiles: PropTypes.array,
    fetchProfiles: PropTypes.func,
    onCreateEquipment: PropTypes.func,
    auth: PropTypes.object,
    id: PropTypes.number,
    profileId: PropTypes.object,
    alertLevels: PropTypes.array,
    readingTypes: PropTypes.array,
    pointIndex: PropTypes.number,
    profile: PropTypes.object,
    InfoId: PropTypes.number,
    EquipmentId: PropTypes.number,
    match: PropTypes.object,
    diff: PropTypes.object,
    getDiff: PropTypes.func,
    history: PropTypes.func,
    chartTypes: PropTypes.object,
};

export {GeneralStep, InstallationPointsStep, UpdateButton};

export default withGlobalStore(withRouter(CompareUpdateEquipment));
