
import React from "react";
import {AlertList} from "../AlertList/AlertList";
import PropTypes from "prop-types";

export const TabBand = () => {
    return <AlertList variant="band" />;
};

TabBand.propTypes = {
    chartTypes: PropTypes.array,
};
