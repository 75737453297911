import React, {Component} from "react";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import {AI_COLOR} from "../../../constants/constants";
import Helper from "../../../helpers/helper";

class Row extends Component {
    render() {
        const {actionItem, info, facilities, isArchive, user, onShowActionItem} = this.props;
        const urgency = _get(info, "action_list_urgencies." + _get(actionItem, "urgency") + ".name");

        const sensorId = _get(actionItem, "sensor_id");

        return (
            <React.Fragment>
                <tr>
                    <td>
                        <div
                            className={"badge badge-pill badge-pill-items"}
                            style={isArchive ? {background: _get(AI_COLOR, urgency)} : {background: _get(AI_COLOR, urgency)}}
                        >
                            {urgency}
                        </div>
                    </td>
                    <td>{_get(actionItem, "hardware")}</td>
                    <td>{_get(facilities, _get(actionItem, "facility_id"))}</td>
                    <td>{_get(actionItem, "locationName")}</td>
                    <td>{sensorId ? sensorId.toString(16).toUpperCase() : _get(actionItem, "deviceSerial")}</td>
                    <td>{Helper.dateToUserFormat(_get(actionItem, "time_created"), user)}</td>
                    <td>{Helper.dateToUserFormat(_get(actionItem, "updated_at"), user)}</td>
                    <td>{_get(info, "action_list_states." + _get(actionItem, "state"))}</td>
                    <td className="action-item-actions">
                        <div
                            onClick={() => onShowActionItem(_get(actionItem, "id"))}
                            className="btn btn-sm btn-primary waves-effect btn-view-action-item"
                        >
                            View
                            {_get(info, "action_list_states." + _get(actionItem, "state")) === "NEW" && <span>!</span>}
                        </div>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

Row.propTypes = {
    actionItem: PropTypes.object,
    info: PropTypes.object,
    facilities: PropTypes.object,
    props: PropTypes.object,
    query: PropTypes.string,
    isArchive: PropTypes.bool,
    user: PropTypes.object,
    onShowActionItem: PropTypes.func,
};

export default Row;
