const nameMap = {
    caution: "Caution",
    warning: "Warning",
};

export const mapDefaultAlert = (item) => {
    const variant = Object.hasOwn(item, "warning") ? "warning" : "caution";
    const value = item[variant];
    const condition = item[`${variant}_condition`];
    const notificationType = item[`${variant}_notification_type`];
    const requireReadings = item[`${variant}_require_readings`];
    const rmsOd = item[`${variant}_rms_od`];
    const fMax = item[`${variant}_f_max`];
    const linesOfResolution = item[`${variant}_lines_of_resolution`];

    return {variant, value, condition, alertLevel: {name: nameMap[variant]}, notificationType, requireReadings, rmsOd, fMax, linesOfResolution};
};
