import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {ToggleModal} from "../../../../shared";
import {withGlobalStore} from "../../../../stores/GlobalStore";
import {ChartContext, ChartFactoryContext} from "./context/context";
import ChartWrapper from "./layouts/chartWrapper/chartWrapper";
import {WithChartLocalStore} from "./hoc/withChartLocalStore";
import {useChartActions, useChartStateStoreContext} from "./store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useChartRef} from "./hooks/useChartRef";
import ChartBody from "./components/chartBody/chartBody";
import useCustomChartTypes from "../../../../hooks/api/Chart/UseCustomChartTypes";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import {useChartTypesStore} from "../../../../stores/zustand/ChartSelectedChartTypeStore";

const propTypes = {
    chartType: PropTypes.string,
    showOutlierDialog: PropTypes.func,
    handleSetIdleThresholdModal: PropTypes.func,
    user: PropTypes.object,
};

const SimpleChart = (props) => {
    const {chartType} = props;
    const chartRef = useChartRef();
    const factoryResult = useChartStateStoreContext((state) => state.factory, shallow);

    const fullScreenMode = useChartStateStoreContext((state) => state.settings.fullScreenMode);

    const {setSelectedPoint} = useChartActions();
    const {equipmentItem} = useEquipmentByParams();
    const chartTypes = useChartTypesStore();
    const {customChartTypes, isLoading} = useCustomChartTypes(equipmentItem.id, chartTypes);

    useEffect(() => {
        if (!customChartTypes?.[chartType]) {
            return;
        }
        const adapterInstallationPoint = equipmentItem.installationPoints.find(
            (item) => item?.universalAdapterParameter?.wuaSensor?.reading_type_id === +chartType
        );

        setSelectedPoint([adapterInstallationPoint.id], false);
    }, [isLoading]);

    return (
        <ChartFactoryContext.Provider value={factoryResult}>
            <ChartContext.Provider
                value={{
                    chartRef,
                    chartProps: props,
                }}
            >
                <React.Fragment>
                    <ToggleModal
                        centered={true}
                        size="full-screen"
                        showModal={true}
                        enabled={fullScreenMode}
                        title={""}
                        withoutFooter={true}
                    >
                        <ChartWrapper>
                            <ChartBody
                                user={props.user}
                                handleSetIdleThresholdModal={props.handleSetIdleThresholdModal}
                                chartType={chartType}
                                {...props}
                            />
                        </ChartWrapper>
                    </ToggleModal>
                </React.Fragment>
            </ChartContext.Provider>
        </ChartFactoryContext.Provider>
    );
};

SimpleChart.propTypes = propTypes;

export default withGlobalStore(withRouter(WithChartLocalStore(SimpleChart)));
