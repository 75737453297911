import {useMutation} from "@tanstack/react-query";
import AlertDefaultCondition from "../../../api/alertDefaultCondition";
import Toast from "../../../pages/shared/toast";

export const useDeleteDefaultAlertMutation = ({onSuccess}) => {
    return useMutation({
        mutationFn: AlertDefaultCondition.delete,
        onSuccess: () => {
            onSuccess();
            Toast.success("The alert conditions have been removed.");
        },
        onError: (res) => {
            Toast.error(res.message || "Server Error. Please contact to administrator.");
        },
    });
};
