import {find as _find} from "lodash";
import {useMemo} from "react";
import Helper from "../../../helpers/helper";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";

export const useInstallationPoint = (_, chartType) => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const isTach = +chartType === 14;
    // Memo hash params
    const hashParams = Helper.getHashParams();

    const installationPoint = useMemo(() => {
        let installationPoint;
        if (isTach) {
            installationPoint = _find(equipment.installationPoints, (item) => item.point_type === "tach") || {};
        } else {
            installationPoint = _find(equipment.installationPoints, (point) => +point.id === +hashParams.installationPointId) || {};
            if (!installationPoint.id) installationPoint = equipment.installationPoints[0];
        }
        return installationPoint;
    }, [equipment?.installationPoints, hashParams, hashParams.installationPointId]);

    return installationPoint;
};
