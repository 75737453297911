import React from "react";
import PropTypes from "prop-types";

const isShowAddNewLevel = ({alertIndex, alerts, additionalAlerts, isAllowEmptyAlerts = false, isWithoutLimit = false}) => {
    const currentAlert = alerts[alertIndex];
    const nextAlert = alerts[alertIndex + 1];
    const prevAlert = alerts[alertIndex - 1];

    if (currentAlert.axisId === 0 && currentAlert.fftAlertType === 1) {
        return false;
    }

    if (additionalAlerts[currentAlert.alertLevel.name]) {
        return false;
    }

    if (!Number.isFinite(currentAlert?.value) && !isAllowEmptyAlerts) {
        return false;
    }

    if (isWithoutLimit && !currentAlert.isBlank && currentAlert?.alertLevel?.id !== nextAlert?.alertLevel?.id) {
        return true;
    }

    if (!currentAlert.isBlank && prevAlert?.alertLevel?.id === currentAlert.alertLevel.id) {
        return false;
    }

    return !nextAlert || currentAlert?.alertLevel?.id !== nextAlert?.alertLevel?.id;
};

export const AddNewAlertButton = ({index, alerts, additionalAlerts, onClick, isAllowEmptyAlerts = false, isWithoutLimit}) => {
    const item = alerts[index];

    if (!isShowAddNewLevel({alertIndex: index, alerts, additionalAlerts, isAllowEmptyAlerts, isWithoutLimit})) {
        return null;
    }

    return (
        <span
            onClick={onClick}
            className="add-new-link-manage mb-3"
        >
            <i className="fa fa-plus-circle" />
            <span>Add New {item.alertLevel.name || ""} Level</span>
        </span>
    );
};

AddNewAlertButton.propTypes = {
    isAllowEmptyAlerts: PropTypes.bool,
    index: PropTypes.number,
    alerts: PropTypes.array,
    additionalAlerts: PropTypes.object,
    onClick: PropTypes.func,
    isWithoutLimit: PropTypes.bool,
};
