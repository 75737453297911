import {useFFTChartSeriesBuilder} from "../../../../hooks/factory/useFFTChartSeriesBuilder";
import {useIsImpactVue} from "../../../../pages/chart/highcharts/hooks/useChartTypes";
import Helper from "../../../../helpers/helper";

export const useFftReadingOptions = ({options}) => {
    const searchParams = Helper.getHashParams();

    // TODO: remove legacy builder and rewrite on new
    const {series, isLoading, readingsLoaded} = useFFTChartSeriesBuilder({
        axis: [+searchParams.axisId],
        chartType: +searchParams.type,
        installationPointId: +searchParams.installationPointId,
        isImpactVue: useIsImpactVue(+searchParams.type),
    });

    const updatedOptions = {...options, series: [...(options.series || []), ...series]};

    return {options: updatedOptions, isLoading, readingsLoaded};
};
