/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import {withGlobalStore} from "../../stores/GlobalStore";
import {useHistory, withRouter} from "react-router";
import Helper from "../../helpers/helper";
import {Modal} from "../../shared";
import "../../assets/scss/components/envelope-settings/envelope-settings.scss";
import {
    useAlertConditionStoreActions,
    useConfirmRemove,
    useCurrentAlertCondition,
    useDeleteId,
    useSelectedTab,
} from "../../stores/zustand/AlertConditionStore";
import AlertTabs from "./shared/alertTabs";
import {ALERT_TABS, ALERT_TABS_NAME, FFT_ALERT_TABS} from "./constants/tabs";
import AlertModalHeader from "./shared/alertModalHeader";
import {useChartComputed, useChartType} from "./hooks/helpers/chart";
import {useEquipmentByParams} from "../../hooks/api/equipment/useEquipmentQuery";
import {useChartTypesStore} from "../../stores/zustand/ChartSelectedChartTypeStore";
import useAxisLabelQuery from "../../hooks/api/axisLabel/useAxisLabelQuery";
import useCustomChartTypes from "../../hooks/api/Chart/UseCustomChartTypes";
import {useInstallationPoint} from "./hooks/useInstallationPoint";
import {getSeriesList} from "../../pages/chart/highcharts/helpers/getSeriesList";
import {FFT_CHART_TYPES_SET} from "../../constants/constants";
import {useTabAlerts} from "./hooks/useTabAlerts";
import SweetAlert from "react-bootstrap-sweetalert";
import {isEqual as _isEqual, omit as _omit, cloneDeep as _cloneDeep, findIndex as _findIndex} from "lodash";
import {useAlertConditionDeleteMutation} from "../../hooks/api/alertCondition/useAlertConditionDeleteMutation";
import AlertSensorSelect from "./shared/alertSensorSelect";
import {FFT_TABS} from "./constants/fftTabs";

const ManageAlertModal = () => {
    const history = useHistory();
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(equipment.id);
    const chartTypes = useChartTypesStore();
    const {isTach} = useChartType(chartTypes);
    const {setConfirmRemove} = useAlertConditionStoreActions();
    const [isCloseConfirm, setIsCloseConfirm] = useState(false);
    const [confirmEvent, setConfirmEvent] = useState(null);

    const {chartType} = useChartComputed(chartTypes);
    const installationPoint = useInstallationPoint(equipment, chartType);
    const {customChartTypes, isLoading: isCustomChartTypesLoading} = useCustomChartTypes(equipment.id, chartTypes);
    const isAdapterChartType = customChartTypes?.[chartType] || installationPoint.universalAdapter;
    const confirmRemove = useConfirmRemove();
    const deleteId = useDeleteId();

    const {setTab, flushStore, setCurrent} = useAlertConditionStoreActions();
    const {isTachometerExist} = useChartComputed(chartTypes, equipment);

    const isFFT = !!FFT_CHART_TYPES_SET[+chartType];
    const currentTab = useSelectedTab();

    const {alerts} = useTabAlerts({withAlertGroup: currentTab === ALERT_TABS_NAME.DELTA_T});
    const currentAlert = useCurrentAlertCondition();

    const onClose = () => {
        setConfirmRemove(false);

        const alertFromList = alerts.find((item) => item.id === currentAlert?.id);

        if (
            currentAlert?.id &&
            !_isEqual(_omit(alertFromList, ["availableConditions", "fftEnvelopeData"]), _omit(currentAlert, ["availableConditions", "fftEnvelopeData"]))
        ) {
            setConfirmEvent({name: "close"});
            setIsCloseConfirm(true);
            return;
        }

        handleClose();
    };

    const onDeleteSuccess = () => {
        setCurrent({});
        setConfirmRemove(false);
    };

    const handleClose = () => history.push(Helper.deleteHashParams(["modal", "type", "axisId", "installationPointId", "fftAlertType"]));

    const {mutateAsync: deleteAlertCondition, isLoading: isAlertDeleteLoading} = useAlertConditionDeleteMutation({chartType, onSuccess: onDeleteSuccess});

    const removeCurrent = () => deleteAlertCondition({alert_condition_id: deleteId, isGroupAlert: true});

    const getTabConfig = () => {
        const tabsLocal = _cloneDeep(isFFT ? FFT_ALERT_TABS : ALERT_TABS);

        if (!isTachometerExist || isAdapterChartType) {
            delete tabsLocal[ALERT_TABS_NAME.TACHOMETER_RPM];
        }
        if (isAdapterChartType) {
            delete tabsLocal[ALERT_TABS_NAME.DELTA_T];
        }

        return tabsLocal;
    };

    const tabsConfig = getTabConfig();

    useEffect(() => {
        if (isTach) {
            setTab(ALERT_TABS_NAME.TACHOMETER_RPM);

            return;
        }
        setTab(isFFT ? ALERT_TABS_NAME.BAND : ALERT_TABS_NAME.LINEAR);
        return flushStore;
    }, [isFFT, isTach]);

    const {AlertComponent: TabRenderer, ChartComponent} = tabsConfig[currentTab] || {};

    useEffect(() => flushStore, []);
    let seriesList = getSeriesList(chartType, false, chartTypes);

    if (!tabsConfig[currentTab]) {
        return null;
    }

    const handleTabChange = (tab) => {
        const axisId = Object.keys(chartTypes?.[chartType]?.series || {})[0] || "1";
        const alertFromList = alerts.find((item) => item.id === currentAlert?.id);

        if (
            currentAlert?.id &&
            !_isEqual(_omit(alertFromList, ["availableConditions", "fftEnvelopeData"]), _omit(currentAlert, ["availableConditions", "fftEnvelopeData"]))
        ) {
            setConfirmEvent({name: "tab-switch", tab});
            setIsCloseConfirm(true);
            return;
        }
        setTab(tab);
        setCurrent({});
        history.push(Helper.setHashParams({axisId}));
    };

    const handleAlertConfirm = () => {
        if (confirmEvent.name === "close") {
            history.push(Helper.deleteHashParams(["modal", "type", "axisId", "installationPointId", "fftAlertType"]));
            return;
        }

        if (confirmEvent.name === "tab-switch") {
            const axisId = Object.keys(chartTypes?.[chartType]?.series || {})[0] || "1";

            setTab(confirmEvent.tab);
            setCurrent({});
            history.push(Helper.setHashParams({axisId}));
        }
        setIsCloseConfirm(false);
    };

    return (
        <Modal
            className="custom-modal manage-alerts-modal modal-manage-alert modal-xl"
            bodyClass="d-flex align-items-start"
            title={"Manage " + seriesList.label + " Alerts"}
            size="manage-alert"
            showModal={true}
            withoutFooter={true}
            onClose={onClose}
            inProgress={false}
            loader={false}
        >
            <div className="mb-3 col-5">
                {FFT_TABS[currentTab] && (
                    <AlertSensorSelect
                        chartType={chartType}
                        equipment={equipment}
                    />
                )}
                {!isTach && (!isAdapterChartType || (isAdapterChartType && FFT_TABS[currentTab])) && !isCustomChartTypesLoading && (
                    <>
                        <AlertModalHeader
                            chartTypes={chartTypes}
                            equipment={equipment}
                        />
                        <AlertTabs
                            tabConfig={tabsConfig}
                            value={currentTab}
                            onTabChange={handleTabChange}
                        />
                    </>
                )}
                <TabRenderer
                    equipment={equipment}
                    chartTypes={chartTypes}
                    axisLabels={axisLabels}
                />
            </div>
            <div className="col-7 chart-block">
                <ChartComponent />
            </div>
            {isCloseConfirm && (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={handleAlertConfirm}
                    onCancel={() => setIsCloseConfirm(false)}
                >
                    Are you sure you want to navigate away from this page? If you proceed, ALL of your changes will be lost. Click Yes to continue, or
                    click Cancel to stay on the current page
                </SweetAlert>
            )}
            {confirmRemove && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE ALERT"
                    delete={deleteId}
                    onConfirm={removeCurrent}
                    onCancel={() => setConfirmRemove(false)}
                    disabled={isAlertDeleteLoading}
                >
                    Are you sure you want to delete this alert?
                </SweetAlert>
            )}
        </Modal>
    );
};
export default withGlobalStore(withRouter(ManageAlertModal));
