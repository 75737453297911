// eslint-disabled
import React from "react";
import PropTypes from "prop-types";

const headerMap = {
    caution: "Caution",
    warning: "Warning",
};

const wrapperClassMap = {
    caution: "col col-12 col-xl-3 mb-3 mb-xl-0",
    warning: "col col-12 col-xl-auto flex-grow-1 mb-3 mb-xl-0",
};

const labelClassMap = {
    caution: "level-label caution-level",
    warning: "level-label warning-level",
};

/**
 *
 * @param {"warning" | "caution"} variant
 * @param {boolean} isNew
 * @return {JSX.Element}
 * @constructor
 */
export const AlertLabel = ({variant, isNew = false}) => {
    return (
        <div className={`${wrapperClassMap[variant]} mr-auto`}>
            <label className={labelClassMap[variant]}>
                {isNew ? "Add New " : ""}
                {headerMap[variant]}
            </label>
        </div>
    );
};

AlertLabel.propTypes = {
    variant: PropTypes.string,
    isNew: PropTypes.bool,
};
