import React from "react";
import PropTypes from "prop-types";
import {useAlertConditionStoreActions, useCurrentAlertGroup} from "../../../stores/zustand/AlertConditionStore";
import EquipmentSensorChecklists from "../../../pages/shared/equipment-sensor-checklists";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";

const AlertSensorMultipleSelect = () => {
    const {equipmentItem: equipment} = useEquipmentByParams();

    const currentGroup = useCurrentAlertGroup();
    const {setPointsList, setCurrent} = useAlertConditionStoreActions();

    const handleChange = (pointId, pointName) => {
        setPointsList(pointId, pointName);
        setCurrent({});
    };

    if (currentGroup.id) {
        return <></>;
    }

    const selectedInstallPoints = currentGroup.points.map((i) => i);

    return (
        <div className="form-group form-group-alerts mt-2 mb-2">
            <label className="d-block">Sensors:</label>
            <EquipmentSensorChecklists
                className="form-control"
                name="installationPointId"
                onChange={handleChange}
                groupId={currentGroup.id || false}
                value={selectedInstallPoints || []}
                filterOutTachometers={true}
                equipment={equipment}
            />
        </div>
    );
};

AlertSensorMultipleSelect.propTypes = {
    chartTypes: PropTypes.object,
    equipment: PropTypes.object,
};

export default AlertSensorMultipleSelect;
