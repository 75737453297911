import React from "react";
import Helper from "../../../helpers/helper";
import PropTypes from "prop-types";
import {useInstallationPoint} from "../hooks/useInstallationPoint";
import SensorSelect from "../../../pages/shared/sensor-select";
import {useHistory} from "react-router";
import {useCurrentIsChanged} from "../../../stores/zustand/AlertConditionStore";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import useCustomChartTypes from "../../../hooks/api/Chart/UseCustomChartTypes";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";

const RTD_VALUE_TYPE_ID = 4;

const AlertSensorSelect = ({chartType}) => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const installationPoint = useInstallationPoint(equipment, chartType);
    const installationPointId = installationPoint.id;
    const history = useHistory();
    const chartTypes = useChartTypesStore();
    const {customChartTypes} = useCustomChartTypes(equipment.id, chartTypes);
    const isAdapterChartType = customChartTypes?.[chartType];

    const currentIsChanged = useCurrentIsChanged();
    const setConfirm = (callback = () => {}) => {
        callback();
    };

    const onChangeInstallPoint = (ev) => {
        const installationPointId = Helper.getInputValue(ev.target);
        if (currentIsChanged) {
            setConfirm(() => history.push(Helper.setHashParams({installationPointId})));
        } else {
            history.push(Helper.setHashParams({installationPointId}));
        }
    };

    return (
        <div className="mb-4 w-100">
            <label>Sensors:</label>
            <SensorSelect
                name="installationPointId"
                onChange={onChangeInstallPoint}
                value={+installationPointId}
                chartType={chartType}
                filterOutTachometers={+chartType !== 14}
                disabled={+chartType === 14}
                equipment={equipment}
                withRtdSensors={equipment.installationPoints.some(
                    (installationPoint) => installationPoint?.universalAdapterParameter?.wuaSensor?.value_type_id === RTD_VALUE_TYPE_ID
                )}
                adapterChartType={isAdapterChartType ? +chartType : null}
            />
        </div>
    );
};

AlertSensorSelect.propTypes = {
    chartType: PropTypes.number,
};

export default AlertSensorSelect;
