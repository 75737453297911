import {useFFTChartSeriesBuilder} from "../../../../hooks/factory/useFFTChartSeriesBuilder";
import {useIsImpactVue} from "../../../../pages/chart/highcharts/hooks/useChartTypes";
import {useCurrentAlertCondition, useSelectAlertIsShowCurrentReading} from "../../../../stores/zustand/AlertConditionStore";

export const useCurrentEnvelopeReadings = ({options}) => {
    const alert = useCurrentAlertCondition();
    const isShowCurrentReading = useSelectAlertIsShowCurrentReading();

    const {series} = useFFTChartSeriesBuilder({
        axis: [alert?.axisId],
        chartType: alert?.chartType,
        installationPointId: alert?.installationPointId,
        isEnabled: !!Object.keys(alert).length,
        isImpactVue: useIsImpactVue(alert?.chartType),
    });

    if (!series?.[0]) {
        return {options, readingDate: ""};
    }

    if (!isShowCurrentReading) {
        return {options, readingDate: series[0].readingDate};
    }

    return {options: {...options, series: [...options.series, series[0]]}, readingDate: series[0].readingDate};
};
