import React, {useRef, useState} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {useSimpleChartOptions} from "../../hooks/charts/useSimpleChartOptions";
import {useTachometerReadingOptions} from "../../hooks/charts/useTachometerReadingOptions";
import ShowTachTriggers from "./components/ShowTachTriggers";
import {useAlertOptions} from "../../hooks/charts/useAlertOptions";
import Helper from "../../../../helpers/helper";
import {useSimpleReadingOptions} from "../../hooks/charts/useSimpleReadingOptions";

const TACHOMETER_CHART_TYPE = 14;

export const ChatTachometer = () => {
    const options = useSimpleChartOptions();
    const [showOverMax, setShowOverMax] = useState(false);
    const {type: chartType} = Helper.getHashParams();

    const chartRef = useRef(null);

    const isTachChart = +chartType === TACHOMETER_CHART_TYPE;

    const {options: optionsWithTachometer} = useTachometerReadingOptions({options, showOverMax, enabled: isTachChart});
    const {options: optionsWithSeries} = useSimpleReadingOptions({options, enabled: !isTachChart});

    const {options: optionsWithAlerts} = useAlertOptions({options: isTachChart ? optionsWithTachometer : optionsWithSeries, chartRef});

    return (
        <>
            {isTachChart && (
                <ShowTachTriggers
                    showTriggers={showOverMax}
                    toggleTachometerTriggers={() => setShowOverMax((prev) => !prev)}
                />
            )}
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"chart"}
                options={optionsWithAlerts}
                ref={chartRef}
                updateArgs={[true, true, false]}
            />
        </>
    );
};
