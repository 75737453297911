import {useQuery} from "@tanstack/react-query";
import ApiAlertCondition from "../../../api/alertCondition";
import {ALERT_CONDITIONS_API} from "./constants";
import {useMemo} from "react";
import {normalizeAlertCondition} from "../../../helpers/alerts/alertCondition";
import {get as _get, each as _each, groupBy as _groupBy} from "lodash";
import {useSelectedTab} from "../../../stores/zustand/AlertConditionStore";
import {ALERT_TABS_NAME} from "../../../modals/alert/constants/tabs";
import {AXIS_NAME} from "../../../constants/axis";

export const getAlertConditionListQueryKeys = (params) => [ALERT_CONDITIONS_API.LIST, params];

export const useAlertConditionListQuery = (chartType, installationPointIds, currentInstallPointId = null, equipment) => {
    const {data, isLoading, ...rest} = useQuery({
        queryFn: () =>
            ApiAlertCondition.getList({
                chartType,
                installationPointIds,
            }),
        queryKey: getAlertConditionListQueryKeys({chartType, installationPointIds}),
        cacheTime: 0,
    });

    const alertType = useSelectedTab();

    const list = useMemo(() => ((data?.list || {})[+chartType] || []).map((alert) => normalizeAlertCondition(chartType, alert)) || [], [data?.list]);

    const groups = useMemo(() => ((data?.groups || {})[+chartType] || []).map((alert) => normalizeAlertCondition(chartType, alert)) || [], [data?.groups]);
    const alertConditionsCount = useMemo(
        () =>
            !list
                ? 0
                : !installationPointIds || +chartType === 14 || !installationPointIds?.indexOf
                ? list.length
                : list.filter(
                      (alertCondition) =>
                          installationPointIds.indexOf(alertCondition.installation_point_id) !== -1 && +alertCondition.isTachometerRpm !== 1
                  ).length,
        [list, installationPointIds]
    );

    const filteredList = useMemo(() => {
        if (!currentInstallPointId) {
            return list;
        }
        let filteredByInstallPoint = list;
        if (alertType === ALERT_TABS_NAME.LINEAR || alertType === ALERT_TABS_NAME.TACHOMETER_RPM) {
            filteredByInstallPoint = list.filter((item) => +item.installationPointId === currentInstallPointId);
        }
        return filteredByInstallPoint.map((i) => normalizeAlertCondition(chartType, i));
    }, [list, currentInstallPointId]);

    const getAlertConditionsGroupsFiltered = useMemo(() => () => getAlertConditionsGroups(), [groups]);

    const getGroupsById = () => {
        const grouped = _groupBy(groups, (item) => item.group_id);

        const alertGroupEntries = Object.entries(grouped).map(([groupId, alertList]) => {
            const alerts = {};

            alertList.forEach((alert) => {
                const constructKey = () => `${alert.alertLevel.name}_${alert.value}_${alert.condition}`;

                if (!alerts[constructKey()]) {
                    alerts[constructKey()] = {...alert, groupId: +groupId, points: [alert.installationPoint.id]};
                }
                alerts[constructKey()].points.push(alert.installationPoint.id);
            });

            return [groupId, Object.values(alerts)];
        });

        return Object.fromEntries(alertGroupEntries);
    };

    const getAlertConditionsGroups = () => {
        let alertConditionsGroups = {};
        let inAllAxis = [];
        let temp = {};
        _each(groups, (alert) => {
            if (alert.group_id) {
                if (temp[alert.group_id]) {
                    if (+temp[alert.group_id] !== +alert.axis_id) {
                        inAllAxis.push(alert.group_id);
                    }
                } else {
                    temp[alert.group_id] = alert.axis_id;
                }
            }
        });
        _each(groups, (alert) => {
            if (alert.group_id) {
                const axisId = inAllAxis.indexOf(alert.group_id) !== -1 ? 0 : alert.axis_id;
                if (!alertConditionsGroups[axisId]) {
                    alertConditionsGroups[axisId] = {};
                }
                if (!alertConditionsGroups[axisId][alert.group_id]) {
                    alertConditionsGroups[axisId][alert.group_id] = {
                        id: alert.group_id,
                        points: [],
                        names: [],
                        alerts: {},
                    };
                }
                if (!alertConditionsGroups[axisId][alert.group_id]["alerts"][alert.alert_level_id]) {
                    alertConditionsGroups[axisId][alert.group_id]["alerts"][alert.alert_level_id] = normalizeAlertCondition(+chartType, alert);
                }
                if (alertConditionsGroups[axisId][alert.group_id]["points"].indexOf(alert.installation_point_id) === -1) {
                    alertConditionsGroups[axisId][alert.group_id]["points"].push(alert.installation_point_id);
                }

                const pointEquipmentId = _get(alert, "installationPoint.equipment.id");
                const pointEquipmentName = _get(alert, "installationPoint.equipment.name");
                const pointName = _get(alert, "installationPoint.name");
                const name = +pointEquipmentId === +(equipment || {}).id ? pointName : pointName.trim() + " (" + pointEquipmentName.trim() + ")";

                if (name && alertConditionsGroups[axisId][alert.group_id]["names"].indexOf(name) === -1) {
                    alertConditionsGroups[axisId][alert.group_id]["names"].push(name);
                }
            }
        });

        return alertConditionsGroups;
    };

    const constructKeyForAllAxis = ({condition, value, axisId}) => `${axisId}_${value}_${condition}`;

    // eslint-disable-next-line no-unused-vars
    const getAlertConditionAllAxisAlerts = () => {
        const groupedAlerts = {};
        const filtered = {};
        filteredList.forEach((alert) => {
            const key = constructKeyForAllAxis({condition: alert.condition, axisId: alert.axisId, value: alert.value});
            groupedAlerts[key] = alert;
        });

        filteredList.forEach((alert) => {
            const keyX = constructKeyForAllAxis({axisId: AXIS_NAME.X, condition: alert.condition, value: alert.value});
            const keyY = constructKeyForAllAxis({axisId: AXIS_NAME.Y, condition: alert.condition, value: alert.value});
            const keyZ = constructKeyForAllAxis({axisId: AXIS_NAME.Z, condition: alert.condition, value: alert.value});

            if (groupedAlerts[keyX] && groupedAlerts[keyY] && groupedAlerts[keyZ]) {
                const key = constructKeyForAllAxis({axisId: AXIS_NAME.ALL, value: alert.value, condition: alert.condition});
                filtered[key] = {
                    ...alert,
                    axisId: AXIS_NAME.ALL,
                    axis_id: AXIS_NAME.ALL,
                    axes_ids: [groupedAlerts[keyX].id, groupedAlerts[keyY].id, groupedAlerts[keyZ].id],
                };
            }
        });

        return Object.values(filtered);
    };

    const allAxisGroup = useMemo(getAlertConditionAllAxisAlerts, [isLoading, filteredList]);

    return {
        alertConditions: filteredList,
        alertConditionsGroup: groups,
        alertListByGroups: getGroupsById(),
        allAxisGroup,
        alertConditionsGroupNormalized: getAlertConditionsGroupsFiltered(),
        rawAlertConditions: data?.list,
        rawAlertConditionsGroup: data?.groups,
        alertConditionsCount,
        isLoading,
        ...rest,
    };
};
