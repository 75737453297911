export const CONDITIONS_MAP = {
    ">=": "Over",
    "<=": "Lower",
};

const overConditions = {
    ">=": "Over",
};

const lowerConditions = {
    "<=": "Lower",
};

export const getAlertConditions = (alert, index, alertList) => {
    if (alertList.length === 1 || alertList.length > 2) {
        return CONDITIONS_MAP;
    }

    const nextAlert = alertList[index + 1];
    const prevAlert = alertList[index - 1];

    if (CONDITIONS_MAP[alert?.condition] === "Over") {
        return overConditions;
    }

    if (CONDITIONS_MAP[alert?.condition] === "Lower") {
        return lowerConditions;
    }

    if (CONDITIONS_MAP[nextAlert?.condition] === "Over") {
        return lowerConditions;
    }

    if (CONDITIONS_MAP[prevAlert?.condition] === "Over") {
        return lowerConditions;
    }

    if (CONDITIONS_MAP[nextAlert?.condition] === "Lower") {
        return overConditions;
    }

    if (CONDITIONS_MAP[prevAlert?.condition] === "Lower") {
        return overConditions;
    }

    return CONDITIONS_MAP;
};
