import React, {Component} from "react";
import PropTypes from "prop-types";
import {DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown} from "reactstrap";
import {Link} from "react-router-dom";
import {
    get as _get,
    find as _find,
    filter as _filter,
    includes as _includes,
} from "lodash";
import EquipmentProfileApi from "../../../api/equipmentProfile";
import SweetAlert from "react-bootstrap-sweetalert";
import AlertLevelApi from "../../../api/alertLevel";
import Loader from "../../../shared/loader/loader";
import ApiReadingTypes from "../../../api/readingTypes";
import {GeneralStep, InstallationPointsStep, UpdateButton} from "../update-equip";
import {withGlobalStore} from "../../../stores/GlobalStore";
import Toast from "../../shared/toast";

class FullViewEquipment extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            deleteProfileId: null,
            deleteEquipmentProfileId: null,
            profileInfo: {},
            alertLevels: [],
            readingTypes: [],
            profileEquipments: [],
            loader: true,
            inProgress: false,
            profileEquipmentsList: ""
        };
    }
    componentDidMount() {
        Promise.all([
            this.fetchProfileInfo(),
            this.fetchAlertLevels(),
            this.fetchReadingTypes(),
            this.fetchEquipmentInfo(),
            this.fetchEquipmentList()
        ]).then(() => this.setState({loader: false}));
    }

    fetchProfileInfo() {
        const {id} = this.props;
        return EquipmentProfileApi
            .getInfo(id)
            .then((response) => {
                if (response) {
                    this.setState({
                        profileInfo: _get(response, "profile", {})
                    });
                }
            });
    }


    fetchAlertLevels() {
        return AlertLevelApi
            .getList()
            .then((response) => {
                if (response) {
                    const {list} = response;
                    this.setState({alertLevels: list || []});
                }
            });
    }
    fetchReadingTypes() {
        return ApiReadingTypes
            .forAlerts()
            .then((response) => {
                if (response) {
                    const {list} = response;
                    this.setState({readingTypes: _filter(list, (type) => !_includes(["dis", "cf"], _get(type, "alias")))});
                }
            });
    }

    fetchEquipmentInfo() {
        const {id} = this.props;
        return EquipmentProfileApi
            .getEquipmentsInfo(id)
            .then((response) => {
                if (response) {
                    this.setState({
                        profileEquipments: _get(response, "equipments", [])
                    });
                }
            });
    }

    fetchEquipmentList() {
        const {id} = this.props;
        return EquipmentProfileApi
            .view(id)
            .then((response) => {
                if (response) {
                    this.setState({
                        profileEquipmentsList: _get(response, "profile.isDraft", [])
                    });
                }
            });
    }

    beforeDelete(id) {
        this.setState({deleteProfileId: id});
    }

    onCancelDelete() {
        this.setState({deleteProfileId: null});
    }

    onDelete() {
        const {id, onClose} = this.props;
        const {fetchProfiles} = this.props;

        EquipmentProfileApi
            .delete(id)
            .then(() => {
                fetchProfiles().then(() => this.setState({
                    deleteProfileId: null,
                    showModal: false
                }))
                    .then (() => {
                        onClose();
                    });
            });
    }
    beforeDeleteEquipment(id) {
        this.setState({deleteEquipmentProfileId: id});
    }
    onDeleteEquipment() {
        const {id, onClose} = this.props;
        const {deleteEquipmentProfileId} = this.state;

        EquipmentProfileApi
            .dissociateEquipmentProfile(id,deleteEquipmentProfileId)
            .then(() => {
                this.fetchEquipmentInfo().then(() => this.setState({
                    deleteEquipmentProfileId: null
                }))
                    .then (() =>{
                        Toast.success("The equipment has been disassociated from the equipment profile.");
                        onClose();
                    });
            });
    }

    onCancelEquipmentDelete() {
        this.setState({deleteEquipmentProfileId: null});
    }

    getValue(alertConditions, axisId, alertLevelId, readingTypeId) {

        return (_find(alertConditions, (installationPoint) =>
            +installationPoint.axis_id === axisId &&
            +installationPoint.alert_level_id === alertLevelId &&
            +installationPoint.reading_type_id === readingTypeId
        ) || {}).value;
    }

    render() {
        const {loader, inProgress, profileInfo, alertLevels, readingTypes, profileEquipments, deleteEquipmentProfileId, deleteProfileId, profileEquipmentsList} = this.state;
        const {onClose,onCreateEquipment, auth, id, chartTypes} = this.props;
        // if (loader) return "";
        return (
            <React.Fragment>
                <Modal
                    {...this.props}
                    size="lg"
                    toggle={onClose}
                    isOpen={true}
                    centered={false}
                    className={"profile-alerts-modal full-view-modal"}
                >
                    <div>
                        <div className={"full-view-modal-header modal-header"}>
                            <div className={"modal-title"}>Equipment Profile</div>
                            <div className={"close"} onClick={onClose}></div>
                        </div>

                        <hr/>
                        {loader ?
                            <Loader/>
                            :
                            <div className={"profile-modal-body"}>
                                <div className={"profile-type profile-list-block col-md-4"} id={id}>
                                    <div className={"type"}>{_get(profileInfo, "name")}</div>
                                    <div className={"description"}>{_get(profileInfo, "description")}</div>
                                    <UncontrolledDropdown className="profile-actions d-inline-block">
                                        <DropdownToggle tag={"span"}>
                                            <span className={"setting-icon-wrapper"}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7.99998 10.2552C9.2457 10.2552 10.2556 9.24538 10.2556 7.99968C10.2556 6.75398 9.2457 5.74414 7.99998 5.74414C6.75426 5.74414 5.74438 6.75398 5.74438 7.99968C5.74438 9.24538 6.75426 10.2552 7.99998 10.2552Z"/>
                                                    <path d="M15 9.32221V6.67777H12.9C12.7833 6.24999 12.6278 5.86111 12.3945 5.47222L13.9111 3.95555L12.0445 2.08888L10.5278 3.60556C10.1389 3.37223 9.75001 3.21665 9.32223 3.09999V1H6.67777V3.09999C6.24999 3.21665 5.86111 3.37223 5.47222 3.60556L3.95555 2.08888L2.08891 3.95555L3.60554 5.47222C3.3722 5.86111 3.21668 6.24999 3.10001 6.67777H1V9.32221H3.10001C3.21668 9.74999 3.3722 10.1389 3.60554 10.5278L2.08891 12.0444L3.95555 13.9111L5.47222 12.3944C5.86111 12.6278 6.24999 12.7833 6.67777 12.9V15H9.32223V12.9C9.75001 12.7833 10.1389 12.6278 10.5278 12.3944L12.0445 13.9111L13.9111 12.0444L12.3945 10.5278C12.6278 10.1389 12.7833 9.74999 12.9 9.32221H15Z" />
                                                </svg>
                                            </span>
                                        </DropdownToggle>
                                        <DropdownMenu className={"p-3"} right={true}>
                                            <React.Fragment>
                                                <Link
                                                    className="link color-primary pt-2"
                                                    to={"/equipment-profile/update/" + id}
                                                >
                                                    <img src="/assets/img/profile/edit-icon.svg" alt=""/>
                                                    <span>Edit</span>
                                                </Link>
                                                {!!(auth.userCan("editEquipment") && profileEquipmentsList == 0) &&
                                                <Link
                                                    className="link color-success pt-2"
                                                    onClick={() => onCreateEquipment(id)}
                                                >
                                                    <img src="/assets/img/profile/plus-green.svg" alt=""/>
                                                    <span>Add Equipment</span>
                                                </Link>
                                                }
                                                {!!auth.userCan("editEquipment") &&
                                                <Link
                                                    className="link color-danger pt-2"
                                                    onClick={() => this.beforeDelete(id)}
                                                >
                                                    <img src="/assets/img/profile/delete-icon.svg" alt=""/>
                                                    <span>Delete</span>
                                                </Link>
                                                }
                                            </React.Fragment>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>

                                </div>
                                <div className={"wrapper-equipment-compare"}>
                                    <GeneralStep profile={profileInfo} chartTypes={chartTypes}/>

                                    <InstallationPointsStep
                                        profile={profileInfo}
                                        readingTypes={readingTypes}
                                        alertLevels={alertLevels}
                                    />
                                </div>
                                <div className="associated-equipment-block">
                                    <div className="subheader">
                                        <div className="subheader-title">Associated Equipment</div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        {profileEquipments.length === 0 && <div>No associated equipment</div>}
                                        {profileEquipments.map((equipment) =>
                                            <div className="d-flex align-items-center justify-content-between" key={equipment} id={_get(equipment, "id")}>
                                                <Link to={"/chart/" + _get(equipment, "id")} className="link color-primary pt-2">{_get(equipment, "name")}</Link>
                                                <div className="d-flex align-items-center">
                                                    <UpdateButton profileInfo={profileInfo} equipment={equipment} inProgress={inProgress} />
                                                    <div
                                                        className={"btn btn-sm btn-danger mb-1"}
                                                        onClick={() => this.beforeDeleteEquipment(_get(equipment, "id"))}
                                                    >
                                                        Dissociate
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </div>
                        }
                        {deleteProfileId &&
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="DELETE EQUIPMENT PROFILE"
                            openAnim={false}
                            closeAnim={false}
                            onConfirm={() => this.onDelete()}
                            onCancel={() => this.onCancelDelete()}
                        >
                            Are you sure you want to delete this equipment profile?
                        </SweetAlert>
                        }
                        {deleteEquipmentProfileId &&
                        <SweetAlert
                            warning
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnBsStyle="default"
                            btnSize="xs"
                            title="DISSOCIATE EQUIPMENT"
                            openAnim={false}
                            closeAnim={false}
                            onConfirm={() => this.onDeleteEquipment(id, deleteEquipmentProfileId)}
                            onCancel={() => this.onCancelEquipmentDelete()}
                        >
                            Are you sure you want to dissociate this equipment from the equipment profile?
                        </SweetAlert>
                        }
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

FullViewEquipment.propTypes = {
    onClose: PropTypes.func,
    profiles: PropTypes.array,
    fetchProfiles: PropTypes.func,
    onCreateEquipment: PropTypes.func,
    auth: PropTypes.object,
    id: PropTypes.number,
    profileId: PropTypes.object,
    alertLevels: PropTypes.array,
    readingTypes: PropTypes.array,
    pointIndex: PropTypes.number,
    profile: PropTypes.object,
    getDiff: PropTypes.func,
    chartTypes: PropTypes.object,
};

export default withGlobalStore(FullViewEquipment);