import React, {Component} from "react";
import PropTypes from "prop-types";
import ApiDefaultCondition from "../../../api/alertDefaultCondition";
import swal from "../../shared/swal";
import {Loader} from "../../../shared";
import SearchInput from "../../shared/search-input";
import {DefaultLevelsTable} from "./components/table";
import SetDefaultsModal from "../../../modals/AlertsDefaultLevels/set-defaults";
import {
    get as _get,
    each as _each,
    forEach as _forEach,
    findKey as _findKey
} from "lodash";
import ApiAxisLabel from "../../../api/axisLabel";
import {withLocationSelectStore} from "../../../stores/LocationSelectStore";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import {withGlobalStore} from "../../../stores/GlobalStore";
import { HeaderSimple } from "../../../shared/header";
import {ExportToCsv} from "export-to-csv";

class AlarmsDefault extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            breadcrumbs: [{name: "Default Alert Levels"}],
            loader: true,
            refresh: true,
            list: [],
            axisLabels: {},
            response: [],
            units: {},
            filter: {
                search: "",
                location: ""
            },
            modal: {
                setValues: {
                    show: false,
                    equipment: {},
                    installationPoint: {},
                    readingTypes: {}
                }
            },
            pagination: {
                page: 1,
                limit: 10,
                hasMore: true
            },
            globalSearchString: ""
        };

        this.handleFetch = this.handleFetch.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.handleSetValueModal = this.handleSetValueModal.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.showAllSearchResults = this.showAllSearchResults.bind(this);
        this.handleChangeGlobalSearch = this.handleChangeGlobalSearch.bind(this);
        this.onNextPage = this.onNextPage.bind(this);
    }

    componentDidMount() {
        this.handleFetch();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loader === true && prevState.loader === false) {
            if(this.state.refresh === true) {
                this.handleFetch();
            }
        }
    }

    handleChangeGlobalSearch = (globalSearchString) => {
        this.setState({globalSearchString: globalSearchString});
    };

    handleSetValueModal(data){
        this.setState({
            modal: {
                ...this.state.modal,
                setValues: {
                    ...this.state.modal.setValues,
                    ...data
                }
            }
        });
    }

    handleToggleModal(modalKey, isFetch){
        this.setState({
            modal: {
                ...this.state.modal,
                [modalKey]: {
                    ...this.state.modal[modalKey],
                    show: !this.state.modal[modalKey].show
                }
            },
            loader: isFetch || false,
            pagination: isFetch ? {
                ...this.state.pagination,
                page: 1
            } : this.state.pagination
        });
    }

    handleSearch = (value) => {
        const {filter} = {...this.state};

        this.setState({
            filter: {
                ...filter,
                search: value
            },
            loader: true,
            pagination: {
                page: 1,
                limit: 10,
                hasMore: false
            }
        });
    };

    showAllSearchResults(equipment) {
        const list = [...this.state.list];

        _forEach(list, item => {
            if (item.id === equipment.id) {
                equipment.showAll = true;
            }
            return item;
        });

        this.setState({
            list: list
        });
    }

    handleFetch(isLoadMore = false) {
        this.controller = new window.AbortController();
        this.signal = this.controller.signal;

        Promise.all([
            ApiDefaultCondition.getList({
                query: {
                    filter: this.state.filter,
                    pagination: this.state.pagination
                },
                signal: this.signal
            }),
            ApiAxisLabel.get()
        ]).then(([response, axisLabels]) => {
            if (response.status === "ok") {
                const {list, units} = response;
                this.setState({
                    loader: false,
                    response: list,
                    axisLabels: axisLabels.data || {},
                    list: isLoadMore ? [...this.state.list, ...list] : list,
                    units,
                    pagination: {
                        ...this.state.pagination,
                        hasMore: list.length === this.state.pagination.limit
                    }
                });
            } else {
                if (response.message) {
                    swal("", response.message, "error");
                }else{
                    swal("", "Server Error. Please contact to administrator.", "error");
                }
                this.setState({
                    loader: false
                });
            }
        });
    }

    onNextPage = () => {
        const {pagination} = {...this.state};
        this.setState({
            pagination: {
                ...pagination,
                page: pagination.page + 1
            }
        }, () => this.handleFetch(true));
    }

    handleFilterChange = (obj = {}) => {
        this.finished = false;
        this.setState({
            filter: {
                ...this.state.filter,
                ...obj,
            },
            loader: true,
            pagination: {
                page: 1,
                limit: 10,
                hasMore: false
            }
        });
    }

    filtersApplied = () => {
        return this.state.filter.location !== "";
    };

    reset = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                location: ""
            },
            loader: true
        });
    };

    removeExistingAlert = (readingType, level) => {
        let list = [...this.state.list];
        let modal = {...this.state.modal};
        let tempAlert = {};
        const alert = _get(modal, "setValues.installationPoint.existingAlerts." + readingType);

        if (alert) {
            _each(alert, (value, key) => {
                if (key.indexOf(level) === -1) {
                    tempAlert[key] = value;
                }
            });

            modal.setValues.installationPoint.existingAlerts[readingType] = tempAlert;
            const equipmentIndex = _findKey(list, item =>
                +item.id === +modal.setValues.equipment.id
            );
            const pointIndex = _findKey(list[equipmentIndex].installationPoints, item =>
                +item.id === +modal.setValues.installationPoint.id
            );
            list[equipmentIndex].installationPoints[pointIndex].existingAlerts[readingType] = tempAlert;

            this.setState({list, modal});
        }
    };

    handleExport = () => {
        this.setState({ loader: true, refresh: false });
        this.signal = this.controller.signal;

        ApiDefaultCondition.export({
            query: {
                filter: this.state.filter
            },
            signal: this.signal
        }).then((response) => {
            const csvExporter = new ExportToCsv({
                filename: "Default Levels",
                showLabels: true,
                headers: [
                    "Equipment Name",
                    "Point Name",
                    "Sensor",
                    "Temperature Caution",
                    "Temperature Warning",
                    "Acceleration Caution X-axes",
                    "Acceleration Warning X-axes",
                    "Acceleration Caution Y-axes",
                    "Acceleration Warning Y-axes",
                    "Acceleration Caution Z-axes",
                    "Acceleration Warning Z-axes",
                    "Velocity Caution X-axes",
                    "Velocity Warning X-axes",
                    "Velocity Caution Y-axes",
                    "Velocity Warning Y-axes",
                    "Velocity Caution Z-axes",
                    "Velocity Warning Z-axes",
                    "PK-PK Caution X-axes",
                    "PK-PK Warning X-axes",
                    "PK-PK Caution Y-axes",
                    "PK-PK Warning Y-axes",
                    "PK-PK Caution Z-axes",
                    "PK-PK Warning Z-axes",
                ],
            });
            let list = _get(response, "data", []);
            this.setState({ loader: false, refresh: true });
            csvExporter.generateCsv(list);
        } );
    }

    render() {
        const {
            loader,
            list,
            units,
            filter,
            pagination,
            breadcrumbs,
            modal,
            globalSearchString,
            axisLabels
        } = this.state;

        const {auth, getLocationById} = this.props;

        return (
            <div>
                <HeaderSimple
                    breadcrumbs={ breadcrumbs }
                    globalSearchString={ globalSearchString }
                    handleChangeGlobalSearch={ this.handleChangeGlobalSearch }
                />
                <div className="subheader">
                    <div className="subheader-title">Default Alert Levels</div>
                </div>
                <div className="block">
                    <div className="block-header block-header--set-defaults">
                        <div className="block-label">
                            <div className="form-list form-list--row">
                                <div className="form-group form-group--inline">
                                    <div className="form-label"><label>Asset Tree Branch:</label></div>
                                    <CollapseLocationSelect
                                        className={"form-control form-control-sm"}
                                        selectName={"location"}
                                        value={+_get(filter, ["location"], 0)}
                                        onChange={(e) => {
                                            this.handleFilterChange({
                                                location: _get(e, "target.value", "")
                                            });
                                        }}
                                        emptyOptionLabel={"All"}
                                        style={{maxWidth:"200px", width:150}}
                                        checkStoreValue={true}
                                    />
                                </div>
                                <div className="form-group form-group--inline">
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={this.handleExport}
                                    >
                                        Export
                                    </button>
                                </div>
                                {this.filtersApplied() &&
                                    <div className="form-group form-group--inline">
                                        <button
                                            className="btn btn-primary btn-sm"
                                            onClick={this.reset}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="block-toolbar">
                            <div className="block-toolbar-wrapper">
                                <SearchAlerts
                                    filter={filter}
                                    handleSearch={this.handleSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="block-body">
                        {loader && <div className="loader-fullscreen"><Loader/></div>}
                        <DefaultLevelsTable
                            list={list}
                            loader={loader}
                            location={getLocationById(filter.location)}
                            onNextPage={this.onNextPage}
                            search={filter.search}
                            hasMore={pagination.hasMore}
                            units={units}
                            handleSetValueModal={this.handleSetValueModal}
                            showAllSearchResults={this.showAllSearchResults}
                            axisLabels={axisLabels}
                            auth={auth}
                        />
                    </div>
                </div>
                {modal.setValues.show &&
                    <SetDefaultsModal
                        title="Set default alert levels"
                        showModal={modal.setValues.show}
                        onClose={(isFetch) => this.handleToggleModal("setValues", isFetch)}
                        readingTypes={modal.setValues.readingTypes}
                        installationPoint={modal.setValues.installationPoint}
                        equipment={modal.setValues.equipment}
                        location={getLocationById(filter.location)}
                        removeExistingAlert={this.removeExistingAlert}
                    />
                }
            </div>
        );
    }
}

class SearchAlerts extends Component {
    constructor(props){
        super(props);

        this.state = {
            value: _get(props.filter, ["search"], "")
        };

        this.timeout = null;

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value){
        if (this.timeout !== null) {
            clearTimeout(this.timeout);
        }

        this.setState({
            value: value
        }, () => {
            this.timeout = setTimeout(() => {
                this.props.handleSearch(this.state.value);
            }, 300);
        });
    }

    render(){
        const {value} = this.state;
        return (
            <div style={{minWidth: "200px"}}>
                <SearchInput
                    onChange={this.handleChange}
                    disabled={false}
                    query={value}
                    placeholder="Search Default Levels"
                />
            </div>
        );
    }
}

SearchAlerts.propTypes = {
    filter: PropTypes.object,
    handleSearch: PropTypes.func
};

AlarmsDefault.propTypes = {
    history: PropTypes.object,
    auth: PropTypes.object,

    // LocationSelectStore
    getLocationId: PropTypes.func,
    getLocationById: PropTypes.func
};

export default withLocationSelectStore(withGlobalStore(AlarmsDefault));