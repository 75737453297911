import moment from "moment";
import {FREQUENCY_TYPES, SERVER_DATETIME_FORMAT} from "../../../../constants/constants";
import {v4 as uuidV4} from "uuid";

/**
 *
 * @param {'band' | 'envelope' | 'simple' | 'tachometer'} variant
 * @param {object} level
 * @param {object} installationPoint
 * @param {object} currentGroup
 * @param {object} equipment
 * @param {number} axisId
 * @param {number} chartType
 * @param {number} readingType
 * @param id
 * @return {{emailUsers: [], requireReadings: number, f_max: *, axis_id: *, tachometerRpmTo?: string, groupId, notificationType: number, axisId: *, fftBandFrom?: string, reading_type_id: *, fftAlertType?: *, sms: string, fftBandTo?: string, value: string, email: string, smsUsers: [], lines_of_resolution: *, alertLevelId: *, alert_level_id: *, tachometerRpmFrom?: string, installationPoint: *&{equipment: {location_id: *}}, alertLevel: *, index?: number, condition: (string|string), rmsOd: string, isTachometerRpm?: number, messenger: string, phone: string, chartType: *, installationPointId: *, fftBandFrequencyType?: string, groupInterval: number}|{emailUsers: [], f_max: *, axis_id: *, groupId, avgDateTo: string, axisId: *, fftEnvelopeSpectralWindow: string, fftEnvelopeSpectralWindowWidth: string, fftEnvelopeMinimum: string, avgDateFrom: string, fftEnvelopeStd: string, reading_type_id: *, fftAlertType?: *, sms: string, avgLastDCount: string, existingEnvelopeData: *[], email: string, smsUsers: [], lines_of_resolution: *, alertLevelId: *, alert_level_id: *, installationPoint: *&{equipment: {location_id: *}}, alertLevel: *, followingType: number, fftEnvelopeType: string, fftEnvelopePercent: string, fftEnvelopeMinimumValue: string, rmsOd: string, messenger: string, phone: string, avgLastRCount: string, chartType: *, installationPointId: *, groupInterval: number, fftEnvelopeCalcType: string}}
 */
export const createAlert = ({
    variant,
    level,
    axisId,
    chartType,
    installationPoint,
    readingType,
    currentGroup = {},
    equipment = {},
    id = uuidV4().toString(),
}) => {
    const isChartFFT = variant === "band" || variant === "envelope";

    let alert = {
        isBlank: true,
        id,
        axis_id: axisId,
        groupId: currentGroup.id || false,
        alertLevel: level,
        alertLevelId: level.id,
        alert_level_id: level.id,
        sms: "0",
        email: "0",
        smsUsers: [],
        emailUsers: [],
        messenger: "0",
        phone: "0",
        chartType,
        installationPointId: installationPoint.id,
        installationPoint: {
            ...installationPoint,
            equipment: {
                ...equipment,
                location_id: equipment.location_id,
            },
        },
        reading_type_id: readingType,
        ...(isChartFFT ? {fftAlertType: Number(isChartFFT)} : {}),
        axisId,
        rmsOd: "0",
        f_max: undefined,
        lines_of_resolution: undefined,
        groupInterval: 1,
    };

    if (variant === "envelope") {
        alert = {
            ...alert,
            existingEnvelopeData: [],
            fftAlertType: "2",
            fftEnvelopeData: [],
            fftEnvelopeType: "1",
            fftEnvelopePercent: "5",
            fftEnvelopeCalcType: "1",
            fftEnvelopeStd: "1",
            fftEnvelopeSpectralWindow: "0",
            fftEnvelopeSpectralWindowWidth: "0",
            fftEnvelopeMinimum: "0",
            fftEnvelopeMinimumValue: "0",
            followingType: 1,
            avgLastRCount: "",
            avgLastDCount: "",
            avgDateFrom: moment().format(SERVER_DATETIME_FORMAT),
            avgDateTo: moment().format(SERVER_DATETIME_FORMAT),
        };
    } else {
        alert = {
            ...alert,
            ...(variant === "band"
                ? {
                      fftBandFrequencyType: FREQUENCY_TYPES.HZ,
                      fftBandFrom: "0",
                      fftBandTo: "1500",
                      index: 0,
                  }
                : {}),
            ...(variant === "tachometer"
                ? {
                      isTachometerRpm: "1",
                      is_tachometer_rpm: "1",
                      tachometerRpmFrom: "",
                      tachometerRpmTo: "",
                      index: 0,
                  }
                : {}),
            // condition: Object.keys(conditions || {})[0] || "",
            value: "",
            notificationType: 1,
            requireReadings: 1,
        };
    }

    return alert;
};
