import React from "react";
import {useChartStateStoreContext, useSelectZoomed} from "../../store/hooks/useStoreContext";
import {useZoomReset} from "../../hooks/useZoomReset";

const ZoomButton = () => {
    const zoomed = useSelectZoomed();

    const isLoading = useChartStateStoreContext((state) => state.isLoading);

    const resetZoom = useZoomReset();

    if (!zoomed) {
        return <></>;
    }
    return (
        <button
            className="highcharts-reset-zoom-custom"
            onClick={resetZoom}
            disabled={!!isLoading}
        >
            Reset Zoom
        </button>
    );
};

export default ZoomButton;
