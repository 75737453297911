/* eslint-disable no-unused-vars */
import React, {Fragment, useState} from "react";
import {Link} from "react-router-dom";
import Highlighter from "react-highlight-words";
import {get as _get} from "lodash";
import {Button} from "reactstrap";
import PropTypes from "prop-types";
import {mapDefaultAlert} from "../../../../../helpers/alerts/mapDefaultAlert";

const AlertLevelSettingBlock = ({data, units, label}) => {
    const caution = parseFloat(_get(data, "caution"));
    const cautionStr = isNaN(caution) ? "not set" : caution;
    const cautionColor = isNaN(caution) ? " badge-warning" : " badge-secondary";

    const warning = parseFloat(_get(data, "warning"));
    const warningStr = isNaN(warning) ? "not set" : warning;
    const warningColor = isNaN(warning) ? " badge-danger" : " badge-secondary";
    return (
        <div className="row">
            {label && <div className="col-auto pr-0 text-nowrap axis-label-text">{label}</div>}
            <div className={label ? "col-12 col-xxl-5 pr-0 text-nowrap" : "col-12 col-xxl-6 pr-0 text-nowrap col-temp"}>
                <span className={"badge round-badge mb-1 mr-1" + cautionColor}>Caution</span>
                {cautionStr !== "not set" ? (
                    <span className="font-weight-bold">
                        {cautionStr} <span className="units-sub">{units}</span>
                    </span>
                ) : (
                    <span className="font-weight-bold">not set</span>
                )}
            </div>
            <div className={label ? "col-12 col-xxl-5 pr-0 text-nowrap" : "col-12 col-xxl-6 pr-0 text-nowrap col-temp"}>
                <span className={"badge round-badge mb-1 mr-1" + warningColor}>Warning</span>
                {warningStr !== "not set" ? (
                    <span className="font-weight-bold">
                        {warningStr} <span className="units-sub">{units}</span>
                    </span>
                ) : (
                    <span className="font-weight-bold">not set</span>
                )}
            </div>
        </div>
    );
};

AlertLevelSettingBlock.propTypes = {
    data: PropTypes.object,
    units: PropTypes.string,
    label: PropTypes.string,
};

const labelMap = {
    caution: "Caution",
    warning: "Warning",
};
const colorMap = {
    caution: "badge-warning",
    warning: "badge-danger",
};

const AlertBox = ({variant, value, condition, units}) => {
    const label = "";

    const isShowValue = Number.isFinite(value);

    return (
        <div className={label ? "col-12 col-xxl-5 pr-0 text-nowrap" : "col-12 col-xxl-6 pr-0 text-nowrap col-temp"}>
            <span className={`badge round-badge mb-1 mr-1 ${isShowValue ? "badge-secondary" : colorMap[variant]}`}>{labelMap[variant]}</span>
            {isShowValue ? (
                <span className="font-weight-bold">
                    {condition} {value} <span className="units-sub">{units}</span>
                </span>
            ) : (
                <span className="font-weight-bold">not set</span>
            )}
        </div>
    );
};
AlertBox.propTypes = {
    variant: PropTypes.string,
    value: PropTypes.number,
    condition: PropTypes.string,
    units: PropTypes.string,
};

const axesMap = [
    {alias: "acc", readingList: ["3", "6", "22"], name: "Acceleration", chartType: 2},
    {alias: "vel", readingList: ["4", "7", "23"], name: "Velocity", chartType: 3},
    {alias: "pkpk", readingList: ["16", "18", "27"], name: "PK-PK", chartType: 5},
];

const axesNameMap = {
    3: "X - Axis",
    4: "X - Axis",
    16: "X - Axis",
    6: "Y - Axis",
    7: "Y - Axis",
    18: "Y - Axis",
    22: "Z - Axis",
    23: "Z - Axis",
    27: "Z - Axis",
};

export const TableRow = ({installationPoint, units, search, equipment, handleSetValueModal, auth}) => {
    const [showMobile, setShowMobile] = useState(false);

    const sortAlerts = (itemA, itemB) => itemA.variant < itemB.variant;

    const createBareAlert = ({variant}) => ({[variant]: null, [`${variant}_condition`]: null});

    const prepareAlerts = (alerts) => {
        if (!alerts.length) {
            alerts = [createBareAlert({variant: "warning"}), createBareAlert({variant: "caution"})];
        }

        if (alerts.length < 2) {
            const existingAlert = mapDefaultAlert(alerts[0]);
            const variantForCreation = existingAlert.variant === "caution" ? "warning" : "caution";

            alerts = [...alerts, createBareAlert({variant: variantForCreation})];
        }

        return alerts.map(mapDefaultAlert).sort(sortAlerts);
    };

    const toggleInstPoint = () => {
        setShowMobile(!showMobile);
    };

    return (
        <tr
            className={
                installationPoint.foundBySearch === false && equipment.showAll === false && search.trim().length > 1 ? "d-none" : "adaptive-row-inst-point"
            }
        >
            <td className="inst-point-title">
                <Link
                    to={`/chart/${equipment.id}#sensors=${installationPoint.id}`}
                    className="color-primary"
                >
                    <span className="font-weight-bold">
                        <Highlighter
                            searchWords={[search.trim()]}
                            autoEscape={true}
                            textToHighlight={installationPoint.name}
                        />
                    </span>
                </Link>
                <div>
                    Sensor: &nbsp;
                    <span className="font-weight-bold">
                        <Highlighter
                            searchWords={[search.trim()]}
                            autoEscape={true}
                            textToHighlight={_get(installationPoint, "sensor.hex", _get(installationPoint, "sensor.id", "not set")) || "not set"}
                        />
                    </span>
                </div>
                <Button
                    onClick={() => toggleInstPoint()}
                    className="btn-toogle-desktop"
                    style={{padding: "8px 8px", position: "absolute", top: "3px", right: "10px"}}
                    size="xs"
                >
                    <img
                        src="/assets/pic/icon-dropdown.svg"
                        alt=""
                    />
                </Button>
            </td>
            <td className={showMobile ? "pr-30" : "pr-30 hide-mobile"}>
                <div className="show-mobile">Temperature, {_get(units, "temp", "N/A")}</div>
                {prepareAlerts(_get(installationPoint, "existingAlerts.1", [])).map((alert) => (
                    <AlertBox
                        key={`${alert.condition}_${alert.variant}`}
                        condition={alert.condition}
                        variant={alert.variant}
                        value={alert.value}
                        units={_get(units, "temp", "N/A")}
                    />
                ))}
                <div className="action-icon-tbl">
                    {auth.userCan("editAlarms") && (
                        <button
                            className="btn btn-icon btn-sm btn-primary"
                            onClick={() =>
                                handleSetValueModal({
                                    show: true,
                                    equipment: {
                                        id: equipment.id,
                                        name: equipment.name,
                                        asset_code: equipment.asset_code,
                                    },
                                    installationPoint: installationPoint,
                                    readingTypes: {
                                        name: "Temperature",
                                        list: ["1"],
                                        alias: "temp",
                                        chartType: 1,
                                        units: _get(units, "temp", "N/A"),
                                    },
                                })
                            }
                        >
                            <i className="fa fa-pencil-alt" />
                        </button>
                    )}
                </div>
            </td>
            {axesMap.map((item) => (
                <td
                    className={showMobile ? "pr-30" : "pr-30 hide-mobile"}
                    key={item.alias}
                >
                    <div className="show-mobile">
                        {item.name}, {_get(units, item.alias, "N/A")}
                    </div>
                    {item.readingList.map((readingItem) => {
                        const alertList = _get(installationPoint, ["existingAlerts", readingItem], []);

                        return (
                            <Fragment key={readingItem}>
                                <div className="col-auto pr-0 text-nowrap axis-label-text">{axesNameMap[readingItem]}</div>
                                {prepareAlerts(alertList).map((alert) => (
                                    <AlertBox
                                        key={`${alert.condition}_${alert.variant}`}
                                        condition={alert.condition}
                                        variant={alert.variant}
                                        value={alert.value}
                                        units={_get(units, item.alias, "N/A")}
                                    />
                                ))}
                            </Fragment>
                        );
                    })}
                    <div className="action-icon-tbl">
                        {auth.userCan("editAlarms") && (
                            <button
                                className="btn btn-icon btn-sm btn-primary"
                                onClick={() =>
                                    handleSetValueModal({
                                        show: true,
                                        equipment: {
                                            id: equipment.id,
                                            name: equipment.name,
                                            asset_code: equipment.asset_code,
                                        },
                                        installationPoint: installationPoint,
                                        readingTypes: {
                                            name: item.name,
                                            list: item.readingList,
                                            alias: item.alias,
                                            chartType: item.chartType,
                                            units: _get(units, item.alias, "N/A"),
                                        },
                                    })
                                }
                            >
                                <i className="fa fa-pencil-alt" />
                            </button>
                        )}
                    </div>
                </td>
            ))}
        </tr>
    );
};

TableRow.propTypes = {
    installationPoint: PropTypes.object,
    equipment: PropTypes.object,
    search: PropTypes.string,
    units: PropTypes.object,
    handleSetValueModal: PropTypes.func,
    axisLabels: PropTypes.object,
    showMobile: PropTypes.bool,
    auth: PropTypes.object,
};
