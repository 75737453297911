import React, {useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {CHART_VIEW_TYPE} from "../../../../../../constants/constants";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useSpeedSettings} from "../../hooks/helpers/useSpeedSettings";
import {useIsCircular, useIsLinear} from "../../../hooks/useChartViewTypes";
import {useZoomReset} from "../../hooks/useZoomReset";

const ChartViewTypeFullscreenedDropdown = () => {
    const {currentSpeed} = useSpeedSettings();
    const chartViewType = useChartStateStoreContext((state) => state.settings.chartViewType);
    const {setChartViewType} = useChartStateStoreContext((state) => state.actions);
    const isCircular = useIsCircular(chartViewType);
    const isLinear = useIsLinear(chartViewType);
    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const resetZoom = useZoomReset();

    const [isOpen, setIsOpen] = useState(false);

    const handleViewTypeChange = (type) => {
        setChartViewType(type);
        resetZoom();
    };

    return (
        <Dropdown
            size="sm"
            disabled={!!isLoading}
            isOpen={isOpen}
            toggle={() => setIsOpen((prevState) => !prevState)}
        >
            <DropdownToggle
                tag="span"
                caret
                className="frequency-types-link"
            >
                {chartViewType === CHART_VIEW_TYPE.LINEAR ? "Linear" : "Circular"}
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem
                    onClick={() => handleViewTypeChange(CHART_VIEW_TYPE.LINEAR)}
                    disabled={isLinear}
                >
                    Linear
                </DropdownItem>
                <DropdownItem
                    onClick={() => handleViewTypeChange(CHART_VIEW_TYPE.CIRCULAR)}
                    disabled={isCircular || currentSpeed <= 0}
                >
                    Circular
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default ChartViewTypeFullscreenedDropdown;
