import React from "react";
import PropTypes from "prop-types";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {Loader} from "../shared";

import styles from "./simpleModal.module.scss";

const SimpleModal = (props) => {
    const {
        showModal,
        title,
        children,
        cancelTitle,
        submitTitle,
        header,
        size = "",
        onClose,
        onSubmit,
        disableSubmit = false,
        className,
        customBtns,
        centered = false,
        withoutFooter = false,
        withoutSubmit = false,
        withoutCancel = false,
        loader = false,
        inProgress = false,
        parseTitleAsHtml = false,
        bodyClass = "",
        withScrollLimit = false,
    } = props;

    return (
        <Modal
            isOpen={showModal}
            toggle={onClose}
            size={size}
            centered={centered}
            className={className}
            contentClassName={withScrollLimit && styles.modalContentLimit}
        >
            {title !== false && (
                <ModalHeader toggle={onClose}>
                    {parseTitleAsHtml ? <span dangerouslySetInnerHTML={{__html: title}} /> : title}
                    {header || ""}
                </ModalHeader>
            )}
            {loader ? (
                <ModalBody>
                    <div style={{height: "500px", position: "relative"}}>
                        <Loader />
                    </div>
                </ModalBody>
            ) : (
                <React.Fragment>
                    <div id="date-picker-portal-modal" />
                    {children && <ModalBody className={`custom-scroll ${bodyClass}`}>{children}</ModalBody>}
                    {!withoutFooter && (
                        <ModalFooter>
                            {!withoutCancel && (
                                <Button
                                    onClick={onClose}
                                    color={"secondary"}
                                    className={"btn-sm"}
                                >
                                    {cancelTitle ? cancelTitle : "Cancel"}
                                </Button>
                            )}
                            {!withoutSubmit && (
                                <Button
                                    onClick={onSubmit}
                                    color={"info"}
                                    disabled={inProgress || disableSubmit}
                                    className={"btn-sm"}
                                >
                                    {inProgress && !disableSubmit ? (
                                        <span>
                                            <i className="fa fa-spinner"></i> Processing
                                        </span>
                                    ) : submitTitle ? (
                                        submitTitle
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            )}
                        </ModalFooter>
                    )}
                    {customBtns && (
                        <ModalFooter>
                            {customBtns.map((btn, index) => (
                                <Button
                                    key={index}
                                    onClick={btn.callback}
                                    disabled={inProgress || btn.disabled}
                                    color={btn.color}
                                    className={"btn-sm"}
                                >
                                    {inProgress ? (
                                        <span>
                                            <i className="fa fa-spinner"></i> Processing
                                        </span>
                                    ) : btn.title ? (
                                        btn.title
                                    ) : (
                                        "Save"
                                    )}
                                </Button>
                            ))}
                        </ModalFooter>
                    )}
                </React.Fragment>
            )}
        </Modal>
    );
};

SimpleModal.propTypes = {
    loader: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showModal: PropTypes.bool,
    disableSubmit: PropTypes.bool,
    customStyles: PropTypes.object,
    customBtns: PropTypes.array,
    children: PropTypes.any,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    submitTitle: PropTypes.string,
    cancelTitle: PropTypes.string,
    progress: PropTypes.object,
    centered: PropTypes.bool,
    size: PropTypes.string,
    header: PropTypes.object,
    className: PropTypes.string,
    withoutFooter: PropTypes.bool,
    withoutSubmit: PropTypes.bool,
    withoutCancel: PropTypes.bool,
    inProgress: PropTypes.bool,
    bodyClass: PropTypes.string,
    parseTitleAsHtml: PropTypes.bool,
    withScrollLimit: PropTypes.bool,
};

SimpleModal.defaultProps = {
    progress: {active: false},
};

export default SimpleModal;
