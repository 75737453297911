import {useMutation} from "@tanstack/react-query";
import ApiDefaultCondition from "../../../api/alertDefaultCondition";

export const useUpdateDefaultAlertConditionMutation = ({onSuccess, onError} = {}) => {
    return useMutation({
        mutationFn: ApiDefaultCondition.update,
        onSuccess: () => {
            onSuccess?.();
        },
        onError: (res) => {
            onError?.(res);
        },
    });
};
