import React, {Component} from "react";
import PropTypes from "prop-types";
import {Modal} from "reactstrap";
import {get as _get, filter as _filter, includes as _includes, find as _find} from "lodash";
import EquipmentProfileApi from "../../../api/equipmentProfile";
import SweetAlert from "react-bootstrap-sweetalert";
import AlertLevelApi from "../../../api/alertLevel";
import ApiReadingTypes from "../../../api/readingTypes";
import "../../../assets/scss/components/equipment/equipment-profile.scss";
import Loader from "../../../shared/loader/loader";
import {GeneralStep, InstallationPointsStep, UpdateButton} from "../../../pages/equipment-profile/update-equip";
import {withGlobalStore} from "../../../stores/GlobalStore";
import Toast from "../../../pages/shared/toast";

class EquipmentProfileModalRemove extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: [],
            id: props.equipment.profile_id,
            getValue: "",
            deleteEquipmentProfileId: null,
            profileInfo: {},
            alertLevels: [],
            readingTypes: [],
            loader: true,
            equipment: {},
        };
        this.beforeDeleteEquipment = this.beforeDeleteEquipment.bind(this);
    }
    componentDidMount() {
        Promise.all([this.fetchProfileInfo(), this.fetchAlertLevels(), this.fetchReadingTypes(), this.fetchEquipmentInfo()]).then(() =>
            this.setState({loader: false})
        );
    }

    fetchEquipmentInfo() {
        return EquipmentProfileApi.getEquipmentInfo(this.props.equipment.id).then((response) => {
            if (response) {
                this.setState({
                    equipment: _get(response, "info", {}),
                });
            }
        });
    }

    fetchProfileInfo() {
        const {id} = this.state;
        return EquipmentProfileApi.getInfo(id).then((response) => {
            if (response) {
                this.setState({
                    profileInfo: _get(response, "profile", {}),
                });
            }
        });
    }

    fetchAlertLevels() {
        return AlertLevelApi.getList().then((response) => {
            if (response) {
                const {list} = response;
                this.setState({alertLevels: list || []});
            }
        });
    }
    fetchReadingTypes() {
        return ApiReadingTypes.forAlerts().then((response) => {
            if (response) {
                const {list} = response;
                this.setState({
                    readingTypes: _filter(list, (type) => !_includes(["dis", "cf"], _get(type, "alias"))),
                });
            }
        });
    }
    beforeDeleteEquipment(id) {
        this.setState({deleteEquipmentProfileId: id});
    }
    onCancelEquipmentDelete() {
        this.setState({deleteEquipmentProfileId: null});
    }
    onDeleteEquipment() {
        const {equipment, onClose, invalidateEquipment} = this.props;
        const {deleteEquipmentProfileId} = this.state;

        this.setState({loader: true}, () => {
            EquipmentProfileApi.dissociateEquipmentProfile(deleteEquipmentProfileId, equipment.id)
                .then(() =>
                    this.setState({
                        deleteEquipmentProfileId: null,
                    })
                )
                .then(() => {
                    invalidateEquipment();
                    Toast.success("The equipment has been disassociated from the equipment profile.");
                    onClose();
                })
                .catch(() => {
                    this.setState({loader: false});
                });
        });
    }

    getValue(alertConditions, axisId, alertLevelId, readingTypeId) {
        return (
            _find(
                alertConditions,
                (installationPoint) =>
                    +installationPoint.axis_id === axisId &&
                    +installationPoint.alert_level_id === alertLevelId &&
                    +installationPoint.reading_type_id === readingTypeId
            ) || {}
        ).value;
    }
    render() {
        const {id, onClose, chartTypes} = this.props;
        const {deleteEquipmentProfileId, profileInfo, alertLevels, readingTypes, loader, equipment} = this.state;
        return (
            <React.Fragment>
                <Modal
                    {...this.props}
                    size="lg"
                    toggle={onClose}
                    isOpen={true}
                    centered={false}
                    className={"profile-alerts-modal full-view-modal"}
                >
                    <div className={"full-view-modal-header modal-header"}>
                        <h5 className={"modal-title"}>Equipment Profile - {_get(profileInfo, "name")}</h5>
                        <div
                            className={"close"}
                            onClick={onClose}
                        ></div>
                    </div>
                    <hr />
                    {loader ? (
                        <Loader />
                    ) : (
                        <div className="profile-modal-body">
                            <div className={"wrapper-equipment-compare"}>
                                <GeneralStep
                                    profile={profileInfo}
                                    chartTypes={chartTypes}
                                />

                                <InstallationPointsStep
                                    profile={profileInfo}
                                    readingTypes={readingTypes}
                                    alertLevels={alertLevels}
                                />
                            </div>
                            <div>
                                <div className="subheader">
                                    <div className="subheader-title">Equipment</div>
                                    <div className="clear"></div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <span className="font-weight-bold">{_get(equipment, "name")}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <UpdateButton
                                            profileInfo={profileInfo}
                                            equipment={equipment}
                                        />
                                        <button
                                            className="btn btn-sm btn-danger pull-right btn-remove"
                                            onClick={() => this.beforeDeleteEquipment(_get(equipment, "profile_id"))}
                                        >
                                            Dissociate
                                        </button>
                                    </div>
                                </div>
                                {deleteEquipmentProfileId && (
                                    <SweetAlert
                                        warning
                                        showCancel
                                        confirmBtnText="Yes"
                                        cancelBtnBsStyle="default"
                                        btnSize="xs"
                                        title="DISSOCIATE EQUIPMENT"
                                        openAnim={false}
                                        closeAnim={false}
                                        onConfirm={() => this.onDeleteEquipment(deleteEquipmentProfileId, id)}
                                        onCancel={() => this.onCancelEquipmentDelete()}
                                    >
                                        Are you sure you want to dissociate this equipment from the equipment profile?
                                    </SweetAlert>
                                )}
                            </div>
                        </div>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

EquipmentProfileModalRemove.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func,
    profiles: PropTypes.array,
    equipment: PropTypes.object,
    id: PropTypes.number,
    invalidateEquipment: PropTypes.func,
    deleteEquipmentProfileId: PropTypes.number,
    chartTypes: PropTypes.array,
};

export default withGlobalStore(EquipmentProfileModalRemove);
