/* eslint-disable no-unused-vars */
import {useTabAlerts} from "../useTabAlerts";
import {useIsDarkTheme} from "../../../../hooks/helpers/theme";
import {find as _find, maxBy as _maxBy, minBy as _minBy, cloneDeep} from "lodash";
import {useAlertConditionStoreActions, useCurrentAlertCondition} from "../../../../stores/zustand/AlertConditionStore";
import $ from "jquery";
import Helper from "../../../../helpers/helper";

const darkThemeLines = {"#d9534f": "#AD3835", "#f0ad4e": "#D38F2E"};

export const useAlertOptions = ({options, chartRef, withAlertGroup = false}) => {
    const {alerts} = useTabAlerts({withAlertGroup});
    const currentAlert = useCurrentAlertCondition();
    const {setCurrent} = useAlertConditionStoreActions();

    const updateValue = (value, commit = false) => {
        const $inputValue = $(".current-alert-value");
        $inputValue && $inputValue.length && $inputValue.val(value);
    };

    const isDarkTheme = useIsDarkTheme();

    const draggablePlotLine = (ev) => {
        const axis = chartRef.current.chart.yAxis[0];

        const plotLine = _find(axis.plotLinesAndBands, {id: currentAlert?.id});

        let yOffset, clickY, value;

        const start = (ev) => {
            $(document).bind({
                "mousemove.line": step,
                "mouseup.line": stop,
                "click.line": stop,
            });
            clickY = ev.pageY - plotLine.svgElem.translateY;
        };

        const step = (ev) => {
            if (value === axis.max || value === axis.min) return stop();
            yOffset = ev.pageY - clickY;
            value = Math.max(axis.min, Math.min(axis.max, axis.toValue(yOffset) - axis.toValue(0) + plotLine.options.value));
            yOffset = axis.toPixels(value + axis.toValue(0) - plotLine.options.value);
            plotLine.svgElem.translate(0, yOffset);
            updateValue(Helper.numberFormat(value, 4));
        };

        const stop = () => {
            $(document).unbind(".line");
            const valueForUpdate = Helper.numberFormat(value, 4);

            setCurrent({...currentAlert, value: valueForUpdate});
        };

        if (typeof plotLine.svgElem.translateY === "undefined") {
            plotLine.svgElem.translate(0, 0);
        }

        start(ev);
    };

    const plotLines = alerts
        .filter((item) => (!!item.value || item.value === 0) && currentAlert?.id !== item.id)
        .concat(Object.keys(currentAlert).length ? [currentAlert] : [])
        .map((item) => ({
            value: parseFloat(currentAlert?.id === item.id ? currentAlert.value : item.value) || 0,
            color: isDarkTheme === "true" ? darkThemeLines[item.alertLevel.color] || item.alertLevel.color : item.alertLevel.color,
            dashStyle: currentAlert?.id === item.id ? "Line" : "ShortDot",
            className: currentAlert?.id === item.id ? "cursor-move" : "",
            width: 2,
            id: item.id,
            zIndex: 9,
            events: {
                mousedown: currentAlert?.id === item.id ? draggablePlotLine : () => {},
            },
        }));

    const maxAlert = _maxBy(alerts, (i) => i.value);
    const minAlert = _minBy(alerts, (i) => i.value);

    return {options: {...options, yAxis: [{...options.yAxis[0], plotLines, softMin: minAlert?.value, softMax: maxAlert?.value}]}};
};
