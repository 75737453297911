import React from "react";
import {Modal} from "../../../../shared";
import {AXIS_NAME} from "../../../../constants/axis";
import PropTypes from "prop-types";

const ConfirmAlertTemplateBlock = ({title, from, to}) => (
    <div className={"row col-12 mb-3"}>
        <label className="col-12">{title}</label>
        <div className="col-6">
            <label className="mr-1 mb-0 ml-3">From:</label>
            <span className="text-bold">{isNaN(parseFloat(from)) ? from || "not set" : from}</span>
        </div>
        <div className="col-6">
            <label className="mr-1 mb-0 ml-3">To:</label>
            <span className="text-bold">{isNaN(parseFloat(to)) ? to || "not set" : to}</span>
        </div>
    </div>
);
ConfirmAlertTemplateBlock.propTypes = {
    title: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
};

const alertTriggerTypeLevels = {
    1: "Alert only when reading exceeds the last active alert",
    2: "Alert for every reading",
};

const axesMap = {
    [AXIS_NAME.X]: "X - Axis",
    [AXIS_NAME.Y]: "Y- Axis",
    [AXIS_NAME.Z]: "Z - Axis",
};

export const OverwriteConfirmModal = ({axisId, alerts, setIsShowConfirmModal, isShowConfirmModal, inProgress, units, onSubmit, existingAlerts}) => {
    const getKeyForAlerts = (alert) => `${alert.condition}_${alert.alertLevel.name}`;

    const constructAlertsForCompare = () => {
        const compare = {};

        existingAlerts.forEach((alert) => (compare[getKeyForAlerts(alert)] = alert));

        return compare;
    };

    const alertsForCompare = constructAlertsForCompare();

    return (
        <Modal
            title="Save and Overwrite Existing Alerts"
            showModal={isShowConfirmModal}
            size={"lg"}
            onClose={() => setIsShowConfirmModal(false)}
            onCancel={() => setIsShowConfirmModal(false)}
            inProgress={inProgress}
            className={"custom-modal"}
            onSubmit={onSubmit}
            submitTitle={"Yes"}
        >
            <p>Are you sure you want to save and overwrite existing alerts?</p>
            <div className="subheader mb-0">
                <div className="subheader-title subheader-title--axis">{axesMap[axisId]}</div>
                <div className="clear" />
            </div>
            {alerts?.map((item) => {
                const current = item.alertLevel.name.toLowerCase();
                const alertToCompare = alertsForCompare[getKeyForAlerts(item)];

                return (
                    <div
                        className="form-group row col-md-12 mb-0"
                        key={item.id}
                    >
                        <div className="col-12 mb-1">
                            <span className={`badge badge-${current === "caution" ? "warning" : "danger"}`}>{item.alertLevel.name}</span>
                        </div>
                        <ConfirmAlertTemplateBlock
                            title="Condition:"
                            to={item.condition}
                            from={alertToCompare?.condition}
                        />
                        <ConfirmAlertTemplateBlock
                            title={`Value (${units}):`}
                            to={item.value}
                            from={alertToCompare?.value}
                        />
                        <ConfirmAlertTemplateBlock
                            title="Trigger Type:"
                            to={alertTriggerTypeLevels[item.notificationType]}
                            from={alertTriggerTypeLevels[alertToCompare?.notificationType]}
                        />
                        <ConfirmAlertTemplateBlock
                            title="Consecutive Readings Required Before Trigger:"
                            to={item.requireReadings}
                            from={alertToCompare?.requireReadings}
                        />
                        {(+alertToCompare?.rmsOd === 1 || +item.rmsOd === 1) && (
                            <ConfirmAlertTemplateBlock
                                title="Request FFT:"
                                from={+alertToCompare?.rmsOd ? "Yes" : "No"}
                                to={+item.rmsOd ? "Yes" : "No"}
                            />
                        )}
                    </div>
                );
            })}
        </Modal>
    );
};

OverwriteConfirmModal.propTypes = {
    axisId: PropTypes.number,
    alerts: PropTypes.array,
    existingAlerts: PropTypes.array,
    setIsShowConfirmModal: PropTypes.func,
    isShowConfirmModal: PropTypes.bool,
    inProgress: PropTypes.bool,
    units: PropTypes.string,
    onSubmit: PropTypes.func,
};
