/* eslint-disable no-unused-vars */
import React, {useEffect} from "react";
import {useFftChartOptions} from "../../hooks/charts/useFftChartOptions";
import {useFftReadingOptions} from "../../hooks/charts/useFftReadingOptions";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {useEnvelopAlert} from "../../hooks/charts/useEnvelopAlert";
import {Loader} from "../../../../shared";
import {
    useAlertConditionStoreActions,
    useCurrentAlertCondition,
    useSelectAlertIsShowAverageReading,
    useSelectAlertIsShowCurrentReading,
} from "../../../../stores/zustand/AlertConditionStore";
import {useEnvelopeAverageSeries} from "../../hooks/charts/useEnvelopeAverageSeries";
import {useCurrentEnvelopeReadings} from "../../hooks/charts/useCurrentEnvelopeReadings";

export const ChartEnvelop = () => {
    const currentAlert = useCurrentAlertCondition();
    const isShowCurrentReading = useSelectAlertIsShowCurrentReading();
    const isShowAverageReading = useSelectAlertIsShowAverageReading();

    const {setIsShowAverageReading, setIsShowCurrentReading, setCurrent} = useAlertConditionStoreActions();

    const options = useFftChartOptions();
    const {options: optionsWithReadings, isLoading} = useFftReadingOptions({options});

    const {
        options: optionsWithEnvelopSeries,
        isLoading: isEnvelopeLoading,
        envelopReadings,
    } = useEnvelopAlert({options: options, baseSeriesOption: optionsWithReadings});
    const {options: optionsWithAvgSeries, isLoading: isAvgLoading} = useEnvelopeAverageSeries({options: optionsWithEnvelopSeries});
    const {options: optionsWithCurrentSeries, readingDate} = useCurrentEnvelopeReadings({options: optionsWithAvgSeries});

    useEffect(() => {
        if (currentAlert?.fftEnvelopeData || !currentAlert?.id) {
            return;
        }

        setCurrent({...currentAlert, fftEnvelopeData: envelopReadings});
    }, [currentAlert.id]);

    useEffect(() => {
        if (!currentAlert.id) {
            return;
        }

        setCurrent({...currentAlert, fftEnvelopeData: envelopReadings});
    }, [
        currentAlert.followingType,
        currentAlert.fftEnvelopeSpectralWindow,
        currentAlert.fftEnvelopeCalcType,
        currentAlert.fftEnvelopeMinimum,
        currentAlert.fftEnvelopeStd,
        currentAlert.fftEnvelopeSpectralWindowWidth,
        currentAlert.fftEnvelopePercent,
        currentAlert.fftEnvelopeType,
        isEnvelopeLoading,
    ]);

    if (isLoading || isAvgLoading || isEnvelopeLoading) {
        return <Loader />;
    }

    return (
        <>
            {currentAlert?.id && (
                <div className={"pl-4 mb-4"}>
                    <span
                        className={"alert-link mr-2"}
                        onClick={() => setIsShowCurrentReading(!isShowCurrentReading)}
                    >
                        <label className="switch">
                            <input
                                readOnly={true}
                                type="checkbox"
                                checked={isShowCurrentReading ? "checked" : false}
                            />
                            <span className="slider round" />
                        </label>
                        Show Current Reading
                    </span>
                    {readingDate && <span className={"mr-4"}>[{readingDate}]</span>}
                    {+currentAlert.followingType !== 1 && (
                        <span
                            className={"alert-link "}
                            onClick={() => setIsShowAverageReading(!isShowAverageReading)}
                        >
                            <label className="switch">
                                <input
                                    readOnly={true}
                                    type="checkbox"
                                    checked={isShowAverageReading ? "checked" : false}
                                />
                                <span className="slider round" />
                            </label>
                            Show {+currentAlert.fftEnvelopeCalcType === 2 ? "Peaks" : "Average"} Reading
                        </span>
                    )}
                </div>
            )}
            <HighchartsReact
                // ref={this.chartRef}
                highcharts={Highcharts}
                constructorType={"chart"}
                options={optionsWithCurrentSeries}
            />
        </>
    );
};
