/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from "react";
import BandAlertTemplate from "../../../../components/alertModal/BandAlertTemplate/BandAlertTemplate";
import SimpleAlertTemplate from "../../../../components/alertModal/SimpleAlertTemplate/SimpleAlertTemplate";
import {EnvelopeAlertTemplate} from "../../../../components/alertModal/EnvelopeAlertTemplate/EnvelopeAlertTemplate";
import {useTabAlerts} from "../../hooks/useTabAlerts";
import {
    useAlertConditionStoreActions,
    useCurrentAlertCondition,
    useSelectAlertChangeToAdditional,
    useSelectAlertShowAdditionalAlert,
    useSelectedTab,
} from "../../../../stores/zustand/AlertConditionStore";
import SweetAlert from "react-bootstrap-sweetalert";
import {Loader} from "../../../../shared";
import AxisTypeControl from "../../../../components/alertModal/AxisTypeControl/AxisTypeControl";
import Helper from "../../../../helpers/helper";
import {useHistory} from "react-router";
import useCustomChartTypes from "../../../../hooks/api/Chart/UseCustomChartTypes";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import {useChartTypesStore} from "../../../../stores/zustand/ChartSelectedChartTypeStore";
import {useInstallationPoint} from "../../hooks/useInstallationPoint";
import styles from "./alertList.module.scss";
import {AXIS_NAME} from "../../../../constants/axis";
import {AddNewAlertButton} from "../../../../components/alertModal/AddNewAlertButton/AddNewAlertButton";
import {CONDITIONS_MAP} from "../../../../helpers/alerts/getAlertConditions";
import {getMappedPercentageValues} from "../../../../helpers/alerts/getMappedPercentageValues";
import {useIsImpactVue} from "../../../../pages/chart/highcharts/hooks/useChartTypes";
import {ALERT_TABS_NAME} from "../../constants/tabs";
import {useChartUnits} from "../../../../components/alertModal/hooks/useChartUnits";
import {isEqual as _isEqual, omit as _omit} from "lodash";
import TachometerAlertTemplate from "../../../../components/alertModal/TachometerAlertTemplate/TachometerAlertTemplate";

const noAxisChartTypes = {
    1: true,
    13: true,
    14: true,
    45: true,
};

const alertListMap = {
    simple: SimpleAlertTemplate,
    delta: SimpleAlertTemplate,
    band: BandAlertTemplate,
    envelop: EnvelopeAlertTemplate,
    tachometer: TachometerAlertTemplate,
};

/**
 *
 * @param {"simple" | "band" | "envelop" | "tachometer" | "delta"} variant
 * @return {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line no-unused-vars,react/prop-types
export const AlertList = ({variant}) => {
    const AlertRenderer = alertListMap[variant];

    const [alertToChange, setAlertToChange] = useState({isShowModal: false, axisToChange: null, alertToChange: null});
    const history = useHistory();
    const units = useChartUnits();

    const {axisId, type: chartType} = Helper.getHashParams();

    const showChangeModal = ({axisToChange = null, alertToChange = null}) => setAlertToChange({isShowModal: true, axisToChange, alertToChange});

    const hideChangeModal = () => setAlertToChange({isShowModal: false, axisToChange: null, alertToChange: null});

    const handleModalChange = () => {
        if (alertToChange.alertToChange) {
            setCurrent(alertToChange.alertToChange);
        }

        if (alertToChange.axisToChange !== null) {
            history.push(Helper.setHashParams({axisId: alertToChange.axisToChange + ""}));
        }

        hideChangeModal();
    };

    const {alerts, isLoading: isAlertLoading} = useTabAlerts({withAlertGroup: variant === "delta"});

    const {equipmentItem} = useEquipmentByParams();

    const isTach = +chartType === 14;
    const chartTypes = useChartTypesStore();
    const currentTab = useSelectedTab();
    const {isLoading: isCustomChartTypesLoading} = useCustomChartTypes(equipmentItem.id, chartTypes);
    const isImpactVue = useIsImpactVue(chartType);
    const installationPoint = useInstallationPoint(equipmentItem, chartType);

    const currentAlert = useCurrentAlertCondition();
    const {setCurrent, setShowAdditionalAlert, setChangeToAdditional, setIsShowAverageReading, setIsShowCurrentReading} = useAlertConditionStoreActions();
    const additionalAlerts = useSelectAlertShowAdditionalAlert();
    const changeToAdditional = useSelectAlertChangeToAdditional();

    const hasAxis = !noAxisChartTypes[+chartType];

    const showAxisTypeControl = hasAxis && !isImpactVue;

    useEffect(() => {
        if (isAlertLoading) {
            return;
        }
        if (currentAlert?.id) {
            return;
        }
        setIsShowAverageReading(false);
        setIsShowCurrentReading(false);
        setCurrent(alerts[0] || {});
    }, [axisId, installationPoint.id, isAlertLoading, currentTab]);

    useEffect(() => {
        if (!changeToAdditional) {
            return;
        }
        const alertToChange = alerts.find((item) => item.isBlank && item.alertLevel.name === changeToAdditional);

        handleSelectAlert(alertToChange);
        setChangeToAdditional(null);
    }, [alerts]);

    const handleAddAdditionalAlert = (levelName) => {
        setShowAdditionalAlert({levelName, value: true});
    };

    const handleSelectAlert = (selectedAlert) => {
        if (!currentAlert.id) {
            return setCurrent(selectedAlert);
        }

        const alertFromList = alerts.find((item) => item.id === currentAlert.id);

        const isChanged = !_isEqual(
            _omit(alertFromList, ["availableConditions", "fftEnvelopeData"]),
            _omit(currentAlert, ["availableConditions", "fftEnvelopeData"])
        );

        if (isChanged) {
            return showChangeModal({alertToChange: selectedAlert});
        }
        setIsShowAverageReading(false);
        setIsShowCurrentReading(false);
        setCurrent(selectedAlert);
    };

    const handleAxisChange = (axis) => {
        if (!currentAlert.id) {
            return history.push(Helper.setHashParams({axisId: axis}));
        }
        const alertFromList = alerts.find((item) => item.id === currentAlert.id);
        const isChanged = !_isEqual(
            _omit(alertFromList, ["availableConditions", "fftEnvelopeData"]),
            _omit(currentAlert, ["availableConditions", "fftEnvelopeData"])
        );

        if (isChanged) {
            return showChangeModal({axisToChange: axis, alertToChange: {}});
        }

        setCurrent({});
        setIsShowAverageReading(false);
        setIsShowCurrentReading(false);
        history.push(Helper.setHashParams({axisId: axis}));
    };

    const mappedPercentageValue = getMappedPercentageValues(alerts);

    if (isAlertLoading) {
        return <Loader />;
    }

    return (
        <div>
            {!isTach && !isCustomChartTypesLoading && showAxisTypeControl && (
                <AxisTypeControl
                    withAllAxis={currentTab !== ALERT_TABS_NAME.ENVELOPE}
                    onAxisChange={handleAxisChange}
                    axisId={axisId}
                    chartType={chartType}
                    equipmentId={equipmentItem.id}
                />
            )}
            {+axisId === AXIS_NAME.ALL && currentTab !== ALERT_TABS_NAME.BAND && (
                <span className={styles.textDanger}>If you save alerts for all axes, existing alerts will be overwritten.</span>
            )}
            {alerts.map((item, index) => (
                <>
                    <AlertRenderer
                        key={`${item.id}_${item.alertLevel.id}`}
                        alert={item.id === currentAlert?.id ? currentAlert : item}
                        originalAlert={item}
                        onChange={setCurrent}
                        isCurrent={item.id === currentAlert?.id}
                        onSelect={() => (item.id !== currentAlert.id ? handleSelectAlert(item) : null)}
                        variant={item.alertLevel.name === "Caution" ? "caution" : "warning"}
                        percentageValue={mappedPercentageValue[CONDITIONS_MAP[currentAlert.condition]]}
                        units={units}
                        alertType={currentTab === ALERT_TABS_NAME.DELTA_T ? "delta-t" : "linear"}
                        index={index}
                    />
                    <AddNewAlertButton
                        index={index}
                        onClick={() => handleAddAdditionalAlert(item.alertLevel.name)}
                        additionalAlerts={additionalAlerts}
                        alerts={alerts}
                        isWithoutLimit={currentTab === ALERT_TABS_NAME.BAND || (currentTab === ALERT_TABS_NAME.TACHOMETER_RPM && +chartType !== 14)}
                    />
                </>
            ))}
            {alertToChange.isShowModal && (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={handleModalChange}
                    onCancel={hideChangeModal}
                >
                    Are you sure you want to navigate away from this page? If you proceed, ALL of your changes will be lost. Click Yes to continue, or
                    click Cancel to stay on the current page
                </SweetAlert>
            )}
        </div>
    );
};
