import React from "react";
import PropTypes from "prop-types";
import {SENSOR_TYPES} from "../../constants/constants";
import {withGlobalStore} from "../../stores/GlobalStore";
import {get as _get, includes as _includes} from "lodash";
import SelectWrapper from "../../helpers/select-wrapper";

const FMaxSelect = ({value, name, onChange, versionType, specs, isHFDvue = false, disabled = false, errorMsg, user, needUpdateOnNotValidValue}) => {
    // const inputRef = useRef(null);

    const getDefaultValues = () => {
        let values;
        if (isHFDvue) {
            values = [5000, 2000, 1000, 500];
        } else if (versionType === SENSOR_TYPES.V1) {
            values = [2500];
        } else if (versionType === SENSOR_TYPES.V2) {
            values = [3200, 1600, 800, 400, 200];
        } else if (
            versionType === SENSOR_TYPES.M001 &&
            +(specs ? (specs || {}).axis_id : -1) === 1 &&
            [303, 257, 313, 312, 247, 307, 302, 242, 281, 311, 266, 340, 333, 325, 283, 299, 313].indexOf(user.customer_id) !== -1
        ) {
            values = [11200, 5600, 2800, 1400, 700];
        } else if (versionType === SENSOR_TYPES.WIFI) {
            values = [12800, 6400, 3200, 1600, 800, 400, 200];
        } else {
            values = specs ? [(specs || {}).fmax] : [];
        }

        return values;
    };

    const fMaxValues = getDefaultValues();

    let defaultValue = value;
    if (!defaultValue || !_includes(fMaxValues, +defaultValue)) {
        defaultValue = _get(fMaxValues, 0);
    }

    if (needUpdateOnNotValidValue && defaultValue !== value) {
        onChange({
            target: {
                name: name || "f_max",
                value: defaultValue,
            },
        });
    }

    return (
        <SelectWrapper
            // ref={inputRef}
            className={errorMsg ? " is-invalid" : ""}
            name={name || "f_max"}
            value={defaultValue}
            onChange={onChange}
            disabled={disabled}
        >
            <option
                value=""
                disabled
            >
                FMax
            </option>
            {fMaxValues.map((value) => (
                <option
                    key={`fmax-value-${value}`}
                    value={value}
                >
                    {value}
                </option>
            ))}
        </SelectWrapper>
    );
};

FMaxSelect.propTypes = {
    disabled: PropTypes.bool,
    isHFDvue: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    maxValue: PropTypes.number,
    versionType: PropTypes.number,
    specs: PropTypes.object,
    errorMsg: PropTypes.string,
    user: PropTypes.object,
    needUpdateOnNotValidValue: PropTypes.bool,
};

export default withGlobalStore(FMaxSelect);
