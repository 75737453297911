import React, {useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {get as _get} from "lodash";
import PropTypes from "prop-types";
import {useDeleteDefaultAlertMutation} from "../../../hooks/api/alertCondition/useDeleteDefaultAlertMutation";

const axisNameMap = {
    1: "X - Axis",
    2: "Y - Axis",
    3: "Z - Axis",
};

const levelMap = {
    Caution: "caution",
    Warning: "warning",
};

export const DefaultAlertActions = ({alert, onDelete, onDeleteBlankImage, isDeleteDisabled}) => {
    const [isShowDelete, setIsShowDelete] = useState(false);

    const {mutate, isLoading} = useDeleteDefaultAlertMutation({onSuccess: onDelete});

    const onConfirmDeleteAlerts = () => {
        const request = {
            installationPointId: alert.installationPointId || false,
            equipmentId: alert?.installationPoint?.equipment?.id || false,
            locationId: alert?.installationPoint?.equipment?.location_id || false,
            data: {
                [alert.readingId]: [{level: levelMap[alert.alertLevel.name], condition: alert.condition}],
            },
        };

        if (!alert.isBlank) {
            mutate(request);
        } else {
            onDeleteBlankImage(alert);
        }
        setIsShowDelete(false);
    };

    const getDeleteConfirmationText = () => {
        const getEquipmentName = () => {
            let res = "";
            res += _get(alert, "installationPoint.name", "ALL INSTALLATION POINTS");
            res += " on ";
            res += _get(alert, "installationPoint.equipment.name", "ALL EQUIPMENT");
            return res;
        };

        return (
            <React.Fragment>
                <p>
                    Are you sure you want to delete {alert.alertLevel.name} {_get(alert, "readingTypeName", "")} alerts
                    {alert.isHasAxis ? ` for ${axisNameMap[alert.axisId]}` : ""} on {getEquipmentName()}?
                </p>
                <p>This alert will be deleted immediately. You can&apos;t undo this action.</p>
            </React.Fragment>
        );
    };

    return (
        <>
            <div className="row justify-content-end mt-4 mx-0">
                <button
                    className="btn btn-sm btn-danger btn-remove"
                    onClick={() => setIsShowDelete(true)}
                    disabled={isLoading || isDeleteDisabled}
                >
                    Delete
                </button>
            </div>
            {isShowDelete && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=" "
                    className="wide"
                    onConfirm={onConfirmDeleteAlerts}
                    onCancel={() => setIsShowDelete(false)}
                    style={{width: "40em"}}
                >
                    {getDeleteConfirmationText()}
                </SweetAlert>
            )}
        </>
    );
};

DefaultAlertActions.propTypes = {
    alert: PropTypes.object,
    onDelete: PropTypes.func,
    onDeleteBlankImage: PropTypes.func,
    isDeleteDisabled: PropTypes.bool,
};
