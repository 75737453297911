import React, {Fragment, useRef} from "react";
import {UncontrolledTooltip} from "reactstrap";
import Helper from "../../../../helpers/helper";
import PropTypes from "prop-types";

const VARIANTS = {
    MOTE: "mote",
    NODE: "node",
};

export const SignalTooltip = ({node, variant}) => {
    const tooltipRef = useRef(null);

    if (!node.packetTimestamps.length) {
        return null;
    }

    return (
        <>
            <span
                className="btn btn-elevate btn-circle btn-icon"
                style={{color: "#777", marginLeft: "10px"}}
                ref={tooltipRef}
            >
                <i className="fa fa-info-circle" />
            </span>
            <UncontrolledTooltip
                style={{maxWidth: 300, textAlign: "left"}}
                target={tooltipRef}
            >
                Signal Strength: {Helper.getSignalLabel(node.signal_strength)}
                <br />
                Packet Loss {node.packet_loss_percent || "N/A"}%
                <br />
                Signal: This value represents the average packet loss over the last {variant === VARIANTS.NODE ? "five" : ""} FFT/TWF{" "}
                {variant === VARIANTS.NODE ? "readings" : "reading"}. A lower percentage indicates a more stable connection, while higher values may
                suggest potential communication issues. Consistently high packet loss can impact data transmission.
                <br />
                <br />
                {node.last_fft_timestamps?.map((item, index) => (
                    <Fragment key={index}>
                        <span>{item}</span>
                        <br />
                    </Fragment>
                ))}
            </UncontrolledTooltip>
        </>
    );
};

SignalTooltip.propTypes = {
    node: PropTypes.object,
    variant: PropTypes.string,
};
