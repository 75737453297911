import validator from "validator";

class FormValidator {
    constructor(validations) {
        this.validations = validations;
    }

    validate(st, fieldName, auxiliaryComponent = {}) {
        let validation = this.valid();
        let state = Object.assign({}, st);

        this.validations.forEach(rule => {
            if (Object.keys(state).includes(rule.field)) {
                const field_value = state[rule.field] ? state[rule.field].toString() : "";
                if (
                    !validation[rule.field].isInvalid &&
                    (fieldName === undefined || (fieldName === rule.field || field_value.length))
                ) {
                    const options = rule.options || {};
                    const validation_method = typeof rule.method === "string" ?
                        validator[rule.method] :
                        rule.method;

                    let skipOnEmpty = rule.skipOnEmpty;
                    if (typeof skipOnEmpty === "function") {
                        skipOnEmpty = skipOnEmpty();
                    }

                    if (
                        (skipOnEmpty !== true || field_value)  &&
                        validation_method(field_value, options, state, auxiliaryComponent) != rule.validWhen
                    ) {
                        validation[rule.field] = {
                            isInvalid: true,
                            message: rule.message
                        };
                        validation.isValid = false;
                    }
                }
            }
        });
        return validation;
    }

    valid() {
        const validation = {};

        this.validations.map(rule => (
            validation[rule.field] = {isInvalid: false, message: ""}
        ));
        return {isValid: true, ...validation};
    }
}

export default FormValidator;