import React from "react";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {useChartRef} from "../../hooks/useChartRef";

function BulkReadingsButton() {
    const {toggleBulkReadingsMode} = useChartStateStoreContext((state) => state.actions);
    const {bulkDeleteReadingsMode} = useChartStateStoreContext((state) => state.settings);
    const chartRef = useChartRef();

    if (bulkDeleteReadingsMode === false) {
        return <></>;
    }

    const handleToggleBulkDelete = () => {
        chartRef.current.chart.isBulkDelete = !bulkDeleteReadingsMode;
        toggleBulkReadingsMode();
    };

    return (
        <button
            className="highcharts-reset-zoom-custom"
            onClick={handleToggleBulkDelete}
        >
            Cancel Bulk Delete Readings
        </button>
    );
}

export default BulkReadingsButton;
