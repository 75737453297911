/* eslint-disable no-unused-vars */
import {orderBy as _orderBy} from "lodash";
import {useCurrentAlertCondition} from "../../../../stores/zustand/AlertConditionStore";
import Highcharts from "highcharts";
import {useEnvelopeAverage} from "../useEnvelopeAverage";

const colorMap = {
    Caution: new Highcharts.Color("#f0ad4e").setOpacity(1).get(),
    Warning: new Highcharts.Color("#d9534f").setOpacity(1).get(),
};

const buildEnvelopSeries = ({readings, alertLevelName}) => ({
    id: "alertSeries",
    name: "",
    color: colorMap[alertLevelName],
    data: readings,
    dataGrouping: {
        approximation: "high",
        forced: true,
    },
    zIndex: 3,
});

export const useEnvelopAlert = ({options, baseSeriesOption}) => {
    const currentAlert = useCurrentAlertCondition();

    const optionsForQuery = {
        axisId: currentAlert.axisId,
        chartType: currentAlert.chartType,
        fftEnvelopeCalcType: currentAlert.fftEnvelopeCalcType,
        followingType: currentAlert.followingType,
        ...(+currentAlert.followingType === 2 ? {avgLastRCount: currentAlert.avgLastRCount} : {}),
        ...(+currentAlert.followingType === 3 ? {avgLastDCount: currentAlert.avgLastDCount} : {}),
        ...(+currentAlert.followingType === 4 ? {avgDateFrom: currentAlert.avgDateFrom, avgDateTo: currentAlert.avgDateTo} : {}),
    };

    // TODO: remove delay via setTimeout
    const {data: averageData, isLoading, fetchStatus} = useEnvelopeAverage();

    const averageFft = {...averageData, ...optionsForQuery};

    const getReadingsForEnvelop = () => {
        if (!currentAlert.followingType) {
            return [];
        }

        if (currentAlert.followingType === 1) {
            return baseSeriesOption.series?.[0]?.data;
        }

        if (+currentAlert.fftEnvelopeCalcType === 1) {
            return averageData?.average || [];
        }

        if (+currentAlert.fftEnvelopeCalcType === 2) {
            return averageData?.max || [];
        }

        return averageData?.avarage || [];
    };

    const envelopReadings = calculateEnvelope({alert: currentAlert, averageFft, readings: getReadingsForEnvelop()});

    return {
        options: {
            ...options,
            series: [...options.series, buildEnvelopSeries({readings: envelopReadings, alertLevelName: currentAlert?.alertLevel?.name})],
        },
        envelopReadings,
        isLoading: isLoading && fetchStatus !== "idle",
    };
};

const calculateEnvelope = ({readings = [], alert, averageFft}) => {
    let result = [];
    let maxHz = 0;
    readings.forEach((val, key) => {
        if (val) {
            if (+alert.fftEnvelopeType === 1) result.push([val[0], val[1] * (1 + alert.fftEnvelopePercent / 100)]);
            else if (+alert.fftEnvelopeType === 2) result.push([val[0], val[1] + alert.fftEnvelopeStd * averageFft.std[key][1]]);
            else result.push([val[0], val[1]]);

            if (maxHz < val[0]) maxHz = val[0];
        }
    });

    if (+alert.fftEnvelopeSpectralWindow === 1 && +alert.fftEnvelopeSpectralWindowWidth > 0) {
        result = filterEnvelopePeaks(findAllPeaks(result), alert.fftEnvelopeSpectralWindowWidth, maxHz);
    }

    if (+alert.fftEnvelopeMinimum === 1) {
        result = result.map((val) => [val[0], Math.max(val[1], alert.fftEnvelopeMinimumValue)]);
    }

    return _orderBy(result, ["0"], ["asc"]);
};

const filterEnvelopePeaks = (peaks, hz, maxHz) => {
    hz = +hz;
    const sortedArr = peaks.sort((a, b) => b[1] - a[1]); // sort descending by value
    const xx = sortedArr.map((val) => val[0]);

    xx.forEach((x) => {
        if (!x) return true;
        const max_range = x + hz / 2;
        const min_range = x - hz / 2;
        sortedArr.forEach((vv, kk) => {
            if (vv) {
                var cur_x_val = vv[0];
                // if value is between current range (x_val - hz to x_val + hz) - remove it.
                // also removing the value from xx array to skip it when it comes to iteration
                if (cur_x_val !== x && cur_x_val >= min_range && cur_x_val <= max_range) {
                    xx[kk] = undefined;
                    sortedArr[kk] = undefined;
                }
            }
        });
    });

    const coords = sortedArr.filter((val) => !!val).sort((a, b) => a[0] - b[0]);
    const res = [];

    coords.forEach((vv) => {
        if (vv) {
            let x1 = vv[0] - hz / 2;
            let x2 = vv[0] + hz / 2;
            let y = vv[1];
            if (x1 < 0) {
                x1 = 0;
            }
            if (x2 > maxHz) {
                x2 = maxHz;
            }
            res.push([x1, y]);
            res.push([x2, y]);
        }
    });

    if (res.length && res[0][0] > 0) res.unshift([0, res[0][1]]);

    //Make trapezoid shape for envelope series to avoid x cord duplication
    res.forEach((val, key) => {
        if (res[key + 1]) {
            if (res[key + 1][0] <= res[key][0]) {
                if (res[key + 1][1] > res[key][1]) {
                    res[key][0] = res[key + 1][0] - 0.000001;
                } else if (res[key + 1][1] < res[key][1]) {
                    res[key + 1][0] = res[key][0] + 0.000001;
                }
            }
        }
    });

    return res;
};

const findAllPeaks = (data) => {
    const peaks = [];

    data.forEach((val, key) => {
        if (key === 0 || key === data.length - 1) return true;
        const cur_y_val = val[1];
        const prev_y_val = data[key - 1][1];
        const next_y_val = data[key + 1][1];
        if (cur_y_val >= prev_y_val && cur_y_val >= next_y_val) peaks.push(val);
    });

    return peaks;
};

const avgReadingsShouldUpdate = ({alert, averageFft}) => {
    return (
        +averageFft.axisId !== +alert.axisId ||
        +averageFft.followingType !== +alert.followingType ||
        (+alert.followingType === 2 && !!alert.avgLastRCount && +averageFft.avgLastRCount !== +alert.avgLastRCount) ||
        (+alert.followingType === 3 && !!alert.avgLastDCount && +averageFft.avgLastDCount !== +alert.avgLastDCount) ||
        (+alert.followingType === 4 &&
            ((!!alert.avgDateFrom && averageFft.avgDateFrom !== alert.avgDateFrom) || (!!alert.avgDateTo && averageFft.avgDateTo !== alert.avgDateTo)))
    );
};

// get currentReadings() {
//     const {alert, isEnvelope, updateStdDeviations, update} = this.props;
//     const {averageFft} = this.state;
//
//     if (isEnvelope && alert.followingType) {
//         if (+alert.followingType === 1) {
//             return Promise.resolve(this.props.chartSeries[0]?.data);
//         }
//         if (this.avgReadingsShouldUpdate()) {
//             update({fftEnvelopeData: []}, true);
//             return this.loadAverageReadings();
//         } else {
//             updateStdDeviations(!!averageFft.std.length);
//             if (+alert.fftEnvelopeCalcType === 2) {
//                 return Promise.resolve(averageFft["max"]);
//             }
//             return Promise.resolve(averageFft["data"]);
//         }
//     } else {
//         updateStdDeviations(false);
//         return Promise.resolve([]);
//     }
// }
