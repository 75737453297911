import PeaksModal from "../components/peaks";
import Helper from "../../../helpers/helper";
import React, {useState} from "react";
import {useModal} from "react-modal-hook";
import {useChartContext, useSearchParams} from "../../../pages/chart/highcharts/twf/hooks/chartHooks";
import {useHistory} from "react-router";
import ChartHelper from "../../../helpers/chart";
import {get as _get, cloneDeep as _cloneDeep} from "lodash";
import {useChartStateStoreContext, useSelectChartPointsForDetection} from "../../../pages/chart/highcharts/twf/store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useLockPoints} from "../../../pages/chart/highcharts/twf/hooks/useLockPoints";
import {useChartRef} from "../../../pages/chart/highcharts/twf/hooks/useChartRef";
import {useModalHistory} from "../../../hooks/helpers/useModalHistory";
import {MODALS} from "../../../constants/modals";
import {useHashParams} from "../../../hooks/helpers/useHashParams";
import {useSpeedSettings} from "../../../pages/chart/highcharts/twf/hooks/helpers/useSpeedSettings";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useInstallPointFaultFrequency} from "../../../hooks/api/faultFrequency/useFaultFrequencyQuery";
import {useChartReadings} from "../../../pages/chart/highcharts/twf/hooks/helpers/useChartReadings";

export const usePeaksModal = () => {
    const {setChartCursor} = useChartStateStoreContext((state) => state.actions, shallow);
    const pointsForDetection = useSelectChartPointsForDetection();
    const chartRef = useChartRef();
    const {chartProps} = useChartContext();
    const lockPoints = useLockPoints();
    const {seriesList, selectedPoint, currentSpeed, chartType} = useChartStateStoreContext((state) => state.settings, shallow);
    const {currentSpeed: currentSpeedForCalculating} = useSpeedSettings();
    const {data: readings} = useChartReadings();
    const [options, setOptions] = useState({});
    const {axisLabels} = chartProps;
    const {chartTypeName} = useChartStateStoreContext((state) => state.factory, shallow);
    const {equipmentItem} = useEquipmentByParams();
    const {filteredFaultFrequencies} = useInstallPointFaultFrequency(equipmentItem.id, selectedPoint);
    const history = useHistory();

    const countHarmonicPoints = useChartStateStoreContext((state) => state.settings.countHarmonicPoints);
    const hashSelectedPoint = useHashParams().selectedPoint;

    const [showModal, hideModal] = useModal(() => {
        const searchParams = useSearchParams();
        if (+searchParams.selectedPoint !== +selectedPoint && +searchParams.chartType !== +chartType) {
            return <></>;
        }
        const changeCursorHandler = (fftCursor) => {
            ChartHelper.clearAdditionalPoints(chartRef.current.chart);
            chartRef.current.chart.isPointBPaused = false;
            lockPoints.current = [];
            setChartCursor(fftCursor);
        };

        const showHarmonic = (selectedSeries, point) => {
            history.push(Helper.deleteHashParams(["modal", "chartType", "selectedPoint"]));
            hideModal();
            changeCursorHandler("harmonic");
            const lockPointsLocal = [...lockPoints.current];
            const chart = chartRef.current.chart;

            if (_get(chart, "series." + selectedSeries + ".points", []).length !== _get(chart, "series." + selectedSeries + ".xData", []).length) {
                chartRef.current.chart.zoomOut();
            }

            const points = _get(chart, "series." + selectedSeries + ".points", []);

            points.forEach((p) => {
                if (p.x >= point[0] && p.x <= point[0]) {
                    lockPointsLocal.push(p);
                }
            });

            ChartHelper.harmonicCursor(lockPointsLocal, chartTypeName, countHarmonicPoints);
            lockPoints.current = _cloneDeep(lockPointsLocal);
        };

        return (
            <PeaksModal
                showModal={true}
                showHarmonic={showHarmonic}
                selectedPoint={selectedPoint || hashSelectedPoint}
                seriesList={seriesList}
                axisLabels={axisLabels}
                currentSpeed={currentSpeed}
                onClose={onHide}
                options={chartRef.current?.chart?.userOptions}
                pointsForDetection={pointsForDetection}
                currentSpeedForCalculating={currentSpeedForCalculating}
                filteredFaultFrequencies={filteredFaultFrequencies}
                equipmentItem={equipmentItem}
                lor={readings[0]?.lor}
                fmax={readings[0]?.fmax}
            />
        );
    }, [options, filteredFaultFrequencies, readings[0]?.lor, readings[0]?.fmax, currentSpeedForCalculating]);

    const {pushHistory, deleteHistory} = useModalHistory(MODALS.PEAKS, showModal, {
        selectedPoint: selectedPoint,
        chartType: chartType,
    });

    const onShow = () => {
        setOptions(chartRef.current?.chart?.options);
        pushHistory();
        showModal();
    };

    const onHide = () => {
        deleteHistory();
        hideModal();
    };

    return [onShow, onHide];
};
