import {useChartActions, useChartStateStoreContext} from "../store/hooks/useStoreContext";
import {useEffect} from "react";
import Highcharts from "highcharts";
import {useDeleteReadingsSimple} from "../../../../../modals/deleteReadingsSimple/hooks/useDeleteReadingsSimple";
import {cloneDeep as _cloneDeep, get as _get} from "lodash";

export const useChartEventEffect = (chartRef, selectedInstPoints, series) => {
    const bulkDeleteReadingsMode = useChartStateStoreContext((state) => state.settings.bulkDeleteReadingsMode);
    const {setZoomed, setBulkDeleteReadingPoints, setCurrentChart, setZoomRange} = useChartActions();

    const [showDeleteReadingModalSimple] = useDeleteReadingsSimple();

    const onChartSelectionEvent = (e) => {
        if (bulkDeleteReadingsMode) {
            const currentChart = _cloneDeep(chartRef);
            const {zoomStart, zoomEnd} = chartRef.current.chart.zoomedPoints;
            let selectedPoints = [];
            const minTimestamp = _get(e, "target.xAxis.0.min");
            const maxTimestamp = _get(e, "target.xAxis.0.max");
            const series = _get(currentChart, "current.chart.series", []);

            if (minTimestamp && maxTimestamp) {
                series.forEach((serial) => {
                    const points = _get(serial, "points", []).slice(zoomStart, zoomEnd);

                    points.forEach((point) => {
                        if (point.category >= minTimestamp && point.category <= maxTimestamp) {
                            selectedPoints.push(point);
                        }
                    });
                });
            }
            setBulkDeleteReadingPoints(selectedPoints);
            showDeleteReadingModalSimple();
            return false;
        } else {
            setZoomed(!e.resetSelection);
        }

        setZoomRange({min: Math.floor(e.target.xAxis[0].min / 1000), max: Math.floor(e.target.xAxis[0].max / 1000)});
    };

    const onSetExtremes = (e) => {
        if (e.trigger === "resetZoom") {
            setZoomRange({max: null, min: null});
            return setZoomed(false);
        }

        if (e.trigger === "zoom" && chartRef.current.chart.userOptions.chart.syncZoomGroup) {
            return setZoomed(true);
        }
    };

    useEffect(() => {
        if (!chartRef.current || !chartRef.current?.chart) {
            return;
        }

        setCurrentChart(_cloneDeep(chartRef));
        Highcharts.addEvent(chartRef.current.chart, "selection", onChartSelectionEvent);
        Highcharts.addEvent(chartRef.current.chart.xAxis[0], "setExtremes", onSetExtremes);

        return () => {
            if (!chartRef.current || !chartRef.current?.chart) {
                return;
            }

            Highcharts.removeEvent(chartRef.current.chart, "selection", onChartSelectionEvent);
            Highcharts.removeEvent(chartRef.current.chart.xAxis[0], "setExtremes", onSetExtremes);
        };
    }, [chartRef.current, bulkDeleteReadingsMode, selectedInstPoints, series]);
};
