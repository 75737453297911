/* eslint-disable no-unused-vars */
import React, {Fragment, useEffect, useState} from "react";
import {Modal} from "../../shared";
import PropTypes from "prop-types";
import {get as _get, find as _find, includes as _includes} from "lodash";
import {withGlobalStore} from "../../stores/GlobalStore";
import AxisTypeControl from "../../components/alertModal/AxisTypeControl/AxisTypeControl";
import SimpleAlertTemplate from "../../components/alertModal/SimpleAlertTemplate/SimpleAlertTemplate";
import useAlertLevelsList from "../../hooks/api/Alert/useAlertLevelList";
import {useUpdateDefaultAlertConditionMutation} from "../../hooks/api/Alert/useUpdateDefaultAlertConditionMutation";
import {createAlert} from "../alert/hooks/helpers/createAlert";
import {CONDITIONS_MAP, getAlertConditions} from "../../helpers/alerts/getAlertConditions";
import {AddNewAlertButton} from "../../components/alertModal/AddNewAlertButton/AddNewAlertButton";
import {AXIS_NAME} from "../../constants/axis";
import {transformAlertForRequest, transformToRequest} from "./helpers/transformAlertForRequest";
import {getMappedPercentageValues} from "../../helpers/alerts/getMappedPercentageValues";
import {OverwriteConfirmModal} from "./components/OverwriteConfirmModal/OverwriteConfirmModal";
import {mapDefaultAlert} from "../../helpers/alerts/mapDefaultAlert";
import {useDefaultFftSettings} from "../../hooks/api/fftReadings/useDefaultFftSettings";
import Toast from "../../pages/shared/toast";
import {useDeleteDefaultAlertMutation} from "../../hooks/api/alertCondition/useDeleteDefaultAlertMutation";

const levelMap = {
    Caution: "caution",
    Warning: "warning",
};

const aboveCondition = {
    ">=": true,
    ">": true,
};

const belowCondition = {
    "<=": true,
    "<": true,
};

const axesByAlias = {
    temp: {
        [AXIS_NAME.X]: "1",
    },
    acc: {
        [AXIS_NAME.X]: "3",
        [AXIS_NAME.Y]: "6",
        [AXIS_NAME.Z]: "22",
    },
    vel: {
        [AXIS_NAME.X]: "4",
        [AXIS_NAME.Y]: "7",
        [AXIS_NAME.Z]: "23",
    },
    pkpk: {
        [AXIS_NAME.X]: "16",
        [AXIS_NAME.Y]: "18",
        [AXIS_NAME.Z]: "27",
    },
};

// eslint-disable-next-line react/prop-types
const SetDefaultsModal = ({equipment, readingTypes, installationPoint, showModal, location, onClose, title}) => {
    const [axisId, setAxisId] = useState(1);
    const [additionalAlerts, setAdditionalAlerts] = useState({Caution: false, Warning: false});
    const [alertIndex, setAlertIndex] = useState(null);
    const [alerts, setAlerts] = useState([]);
    const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
    const [errors, setErrors] = useState({});

    const {data: defaultFftSettings} = useDefaultFftSettings({sensorId: installationPoint?.sensor?.id});
    const {mutate, isLoading} = useDeleteDefaultAlertMutation({onSuccess: () => onClose(true)});

    const alertsWithValue = alerts.filter((item) => Number.isInteger(parseInt(item.value)));

    const readingId = axesByAlias[readingTypes?.alias]?.[axisId];
    const existingAlerts = installationPoint?.existingAlerts?.[readingId] || [];
    const normalizedExistedAlerts = existingAlerts.map(mapDefaultAlert);

    let recordsDeleted = false;

    const onUpdateSuccess = () => {
        Toast.success("The alert conditions have been updated.");
        onClose(true);
    };

    const onUpdateError = (res) => {
        if (res.errors) {
            setErrors(Object.values(res.errors)[0]);
            setIsShowConfirmModal(false);
            return;
        }
        Toast.error(res?.messages?.join(";") || "Server Error. Please contact to administrator.");
    };

    const {alertLevelsList: levels, isLoading: isLevelLoading, isSuccess: isAlertLevelsLoaded} = useAlertLevelsList();
    const {mutate: updateAlertConditionMutate, isLoading: isUpdateLoading} = useUpdateDefaultAlertConditionMutation({
        onSuccess: onUpdateSuccess,
        onError: onUpdateError,
    });

    const mappedPercentage = getMappedPercentageValues(alerts);

    const versionType = +((installationPoint.sensor || {}).version_type || 0);
    const specs = _find(defaultFftSettings?.specs, (spec) => +spec.axis_id === +axisId) || _get(defaultFftSettings, 0) || {};

    const createAlertDefaultAlert = (params) => {
        const alert = createAlert(params);

        return {
            ...alert,
            readingTypeName: readingTypes.name,
            isHasAxis: readingTypes.list.length > 1,
            value: params.value ?? "",
            readingId,
            condition: params.condition,
            fMax: params.fMax,
            linesOfResolution: params.linesOfResolution,
            rmsOd: params.rmsOd?.toString?.() || "0",
            isBlank: params.isBlank,
            installationPoint: {...alert.installationPoint, equipment: {...alert.installationPoint.equipment, location_id: location?.id}},
        };
    };

    useEffect(() => {
        if (!isAlertLevelsLoaded) {
            return;
        }
        const alerts = levels?.flatMap((level) => {
            let alertsForDisplay = [];
            const alertsByLevel = existingAlerts?.map(mapDefaultAlert).filter((item) => item.variant === level.name.toLowerCase());

            if (alertsByLevel.length) {
                alertsForDisplay = alertsByLevel.map((item) =>
                    createAlertDefaultAlert({
                        level,
                        variant: "simple",
                        chartType: readingTypes.chartType,
                        axisId,
                        installationPoint: installationPoint,
                        equipment: equipment,
                        value: item.value,
                        condition: item.condition,
                        rmsOd: item.rmsOd,
                        fMax: item.fMax,
                        linesOfResolution: item.linesOfResolution,
                        isBlank: false,
                    })
                );
            } else {
                const alert = createAlertDefaultAlert({
                    level,
                    variant: "simple",
                    chartType: readingTypes.chartType,
                    axisId,
                    installationPoint: installationPoint,
                    equipment: equipment,
                    isBlank: true,
                });

                alertsForDisplay = [alert];
            }

            return alertsForDisplay.map((item, index) => {
                const availableConditions = getAlertConditions(item, index, alertsForDisplay);
                const condition = item.condition || Object.keys(availableConditions)[0];

                return {...item, availableConditions, condition};
            });
        });

        const warningCount = alerts.filter((item) => item.alertLevel.name === "Warning").length;
        const cautionCount = alerts.filter((item) => item.alertLevel.name === "Caution").length;
        setAdditionalAlerts({Caution: cautionCount > 1, Warning: warningCount > 1});

        setAlerts(alerts);
    }, [isAlertLevelsLoaded, axisId]);

    useEffect(() => {
        if (!additionalAlerts.Caution && !additionalAlerts.Warning) {
            return;
        }

        const alertGroupsByLevelNameEntries = Object.entries(additionalAlerts).flatMap(([key, isAddNewAlert]) => {
            const valuesPerLevel = [key, alerts.filter((item) => item.alertLevel.name === key)];

            if (isAddNewAlert && valuesPerLevel[1].length < 2) {
                valuesPerLevel[1].push(
                    createAlertDefaultAlert({
                        level: levels.find((item) => item.name === key),
                        variant: "simple",
                        chartType: readingTypes.chartType,
                        axisId,
                        installationPoint: installationPoint,
                        equipment: equipment,
                        isBlank: true,
                    })
                );
            }

            return valuesPerLevel[1].map((item, index, list) => {
                const availableConditions = getAlertConditions(item, index, list);
                const condition = item.condition || Object.keys(availableConditions)[0];

                return {...item, availableConditions, condition};
            });
        });
        setAlerts(alertGroupsByLevelNameEntries);
    }, [additionalAlerts]);

    const handleAlertChange = (alert) => {
        const updatedAlerts = alerts.map((item) => (item.id === alert.id ? alert : item));
        setAlerts(updatedAlerts);
    };

    const isValidField = (alertsWithValue) => {
        let isError = false;
        ["Caution", "Warning"].forEach((item) => {
            const alertByLevelName = alertsWithValue.filter((alert) => alert.alertLevel.name === item);
            if (alertByLevelName?.length !== 2) {
                return;
            }
            const aboveAlert = alertByLevelName.find((item) => aboveCondition[item.condition]);
            const belowAlert = alertByLevelName.find((item) => belowCondition[item.condition]);

            if (+aboveAlert.value <= +belowAlert.value) {
                isError = true;
                setErrors((prev) => ({...prev, [aboveAlert.id]: `"The value (${aboveAlert.value}) must be greater than ${belowAlert.value}"`}));
            }
        });

        return !isError;
    };

    const handleSave = (isOverwrite) => {
        const transformedAlerts = alertsWithValue.map((item) => transformAlertForRequest(item, versionType));

        const body = transformToRequest({
            alerts: transformedAlerts,
            axisId: readingId || 1,
            overwrite: isOverwrite,
            equipmentId: equipment.id,
            installationPointId: installationPoint?.id,
            locationId: location?.id,
        });

        updateAlertConditionMutate(body);
    };

    const onDeleteBlankImage = (alert) => {
        const alertsByLevel = alerts.filter((item) => item.alertLevel.name === alert.alertLevel.name);

        if (alertsByLevel?.length === 2) {
            const updatedAlerts = alerts.filter((item) => item.id !== alert.id);
            setAlerts(updatedAlerts);
            setAdditionalAlerts((prev) => ({...prev, [alert.alertLevel.name]: false}));
        }
        if (alertsByLevel?.length === 1 && alert?.installationPoint?.equipment?.id && alert.installationPointId) {
            const updatedAlerts = alerts.map((item) => {
                if (item.id !== alert.id) {
                    return item;
                }

                const createdAlert = createAlertDefaultAlert({
                    level: item.alertLevel,
                    variant: "simple",
                    chartType: readingTypes.chartType,
                    axisId,
                    installationPoint: installationPoint,
                    equipment: equipment,
                    isBlank: true,
                });

                return {...createdAlert, condition: item.condition, availableConditions: item.availableConditions};
            });
            setAlerts(updatedAlerts);
            return;
        }

        if (alertsByLevel?.length === 1) {
            const request = {
                installationPointId: alert.installationPointId || false,
                equipmentId: alert?.installationPoint?.equipment?.id || false,
                locationId: location?.id || false,
                data: {
                    [alert.readingId]: [
                        {level: levelMap[alert.alertLevel.name], condition: ">="},
                        {level: levelMap[alert.alertLevel.name], condition: "<="},
                    ],
                },
            };

            mutate(request);
        }
    };

    const handleInputChange = (alert) => {
        alerts.forEach((item) => {
            if (item.alertLevel.name === alert.alertLevel.name) {
                setErrors((prev) => ({...prev, [item.id]: null}));
            }
        });
    };

    return (
        <>
            <Modal
                title={title}
                showModal={showModal}
                size="lg"
                onClose={() => onClose(recordsDeleted)}
                loader={isLevelLoading}
                inProgress={isUpdateLoading}
                className="custom-modal"
                bodyClass="mh-250"
                withoutFooter={true}
                submitTitle="Create Conditions"
                withScrollLimit
                customBtns={[
                    {
                        title: "Cancel",
                        color: "secondary",
                        callback: () => onClose(recordsDeleted),
                    },
                    {
                        title: "Save and Overwrite Existing Alerts",
                        color: "info",
                        disabled: !alertsWithValue.length,
                        callback: () => {
                            if (!isValidField(alertsWithValue)) {
                                return;
                            }
                            setIsShowConfirmModal(true);
                        },
                    },
                    {
                        title: "Save Non-Existing Alerts Only",
                        color: "success",
                        disabled: !alertsWithValue.length,
                        callback: () => {
                            if (!isValidField(alertsWithValue)) {
                                return;
                            }
                            handleSave(false);
                        },
                    },
                ]}
            >
                <div className="row mb-2">
                    <div className="col-12">
                        <span className="secondary-text">
                            <i className="fa fa-info-circle mr-1" />
                            Check the following details before creating alert levels:
                        </span>
                    </div>
                </div>
                <div className="row">
                    {_get(readingTypes, "name", false) && (
                        <div className="form-group col-12 mb-2">
                            <label>Reading Type: </label>
                            <span className="font-weight-bold ml-1">
                                {_get(readingTypes, "name", "Not set")}, {_get(readingTypes, "units", "N/A")}
                            </span>
                        </div>
                    )}
                    {_get(equipment, "name", false) ? (
                        <div className="form-group col-6 mb-1">
                            <label>Equipment:</label>
                            <div>{_get(equipment, "name", "Not set")}</div>
                            <div>{_get(equipment, "asset_code", "Not set")}</div>
                        </div>
                    ) : (
                        <div className="form-group col-6 mb-1">
                            <label>{_get(location, "name", false) === false ? "Equipment: " : "Asset Tree Branch: "}</label>
                            <div className="font-weight-bold text-danger">{_get(location, "name", "ALL EQUIPMENT")}</div>
                        </div>
                    )}
                    {_get(installationPoint, "name", false) ? (
                        <div className="form-group col-6 mb-1">
                            <label>Installation Point: </label>
                            <div>{_get(installationPoint, "name", "Not set")}</div>
                        </div>
                    ) : (
                        <div className="form-group col-6 mb-1">
                            <label>Installation Point:</label>
                            <div className="font-weight-bold text-danger">ALL INSTALLATION POINTS</div>
                        </div>
                    )}
                </div>
                <hr style={{margin: "20px 0"}} />
                {readingTypes.list.length > 1 && (
                    <AxisTypeControl
                        axisId={axisId}
                        onAxisChange={setAxisId}
                        chartType={readingTypes.chartType}
                        equipmentId={equipment.id}
                    />
                )}
                {alerts.map((item, index) => (
                    <>
                        <SimpleAlertTemplate
                            key={index}
                            index={index}
                            alert={item}
                            variant={levelMap[item.alertLevel.name]}
                            onChange={handleAlertChange}
                            isCurrent={alertIndex === index}
                            originalAlert={item}
                            onSelect={() => setAlertIndex(index)}
                            withoutEmailTemplate={true}
                            actionsType="alertDefaults"
                            percentageValue={mappedPercentage[CONDITIONS_MAP[alerts[alertIndex]?.condition]]}
                            units={readingTypes?.units}
                            onDelete={() => onClose(true)}
                            withoutOnDemand={!versionType || !specs?.axis_id}
                            onInputChange={() => handleInputChange(item)}
                            error={errors[item.id]}
                            onDeleteBlankImage={onDeleteBlankImage}
                            isDeleteDisabled={isLoading}
                        />
                        <AddNewAlertButton
                            index={index}
                            alerts={alerts}
                            isAllowEmptyAlerts
                            additionalAlerts={additionalAlerts}
                            onClick={() => setAdditionalAlerts((prev) => ({...prev, [item.alertLevel.name]: true}))}
                        />
                    </>
                ))}
            </Modal>
            {isShowConfirmModal && (
                <OverwriteConfirmModal
                    alerts={alertsWithValue}
                    existingAlerts={normalizedExistedAlerts}
                    axisId={axisId}
                    isShowConfirmModal={isShowConfirmModal}
                    setIsShowConfirmModal={setIsShowConfirmModal}
                    units={readingTypes?.units}
                    inProgress={isUpdateLoading}
                    onSubmit={() => handleSave(true)}
                />
            )}
        </>
    );
};

SetDefaultsModal.propTypes = {
    installationPoint: PropTypes.object,
    readingTypes: PropTypes.object,
    equipment: PropTypes.object,
    chartTypes: PropTypes.array,
    location: PropTypes.object,
    onClose: PropTypes.func,
    removeExistingAlert: PropTypes.func,
};

export default withGlobalStore(SetDefaultsModal);
