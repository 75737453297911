import $ from "jquery";
import {useAlertConditionStoreActions, useCurrentAlertCondition} from "../../../../stores/zustand/AlertConditionStore";
import FrequencyConverter from "../../../../helpers/frequency-converter";
import {find as _find} from "lodash";

const LINE_FROM_ID = "lineFrom";
const LINE_TO_ID = "lineTo";

const bandValueMap = {
    [LINE_FROM_ID]: "fftBandFrom",
    [LINE_TO_ID]: "fftBandTo",
};
const scaleMapFn = {
    [LINE_FROM_ID]: (pixelWidth, xOffset) => (pixelWidth - xOffset) / pixelWidth,
    [LINE_TO_ID]: (pixelWidth, xOffset) => (pixelWidth + xOffset) / pixelWidth,
};

const createPlotLine = ({value, id, mousedown}) => ({
    id,
    color: "black",
    width: 3,
    value,
    zIndex: 10,
    events: {
        mousedown: (e) => mousedown(e, id),
    },
    className: "cursor-ew-resize",
});

const createPlotBand = ({valueFrom, valueTo, mousedown}) => ({
    id: "band",
    color: "rgba(196, 196, 196, 0.34)",
    from: valueFrom,
    to: valueTo,
    events: {
        mousedown,
    },
    className: "cursor-move",

});

export const useBandNavigation = ({options, chartRef}) => {
    const currentAlert = useCurrentAlertCondition();
    const {setCurrent} = useAlertConditionStoreActions();

    const convertedBandFrom =
        FrequencyConverter.fromType(currentAlert.fftBandFrequencyType, +currentAlert.fftBandFrom, options.series?.[0]?.speed || 0).toHz().value || 0;

    const convertedBandTo =
        FrequencyConverter.fromType(currentAlert.fftBandFrequencyType, +currentAlert.fftBandTo, options.series?.[0]?.speed || 0).toHz().value || 1500;

    const dragBandEvent = (e) => {
        let xOffset, clickX, from, to;

        const xAxis = chartRef.current.chart.xAxis[0];
        const band = _find(chartRef.current.chart.xAxis[0].plotLinesAndBands, {id: "band"});
        const lineFrom = _find(chartRef.current.chart.xAxis[0].plotLinesAndBands, {id: "lineFrom"});
        const lineTo = _find(chartRef.current.chart.xAxis[0].plotLinesAndBands, {id: "lineTo"});

        const start = (e) => {
            $(document).bind({
                "mousemove.line": step,
                "mouseup.line": stop,
                "click.line": stop,
            });
            clickX = e.pageX - band.svgElem.translateX;
        };

        const step = (e) => {
            xOffset = e.pageX - clickX;

            const transformedBandFrom = FrequencyConverter.fromType(
                currentAlert.fftBandFrequencyType,
                +currentAlert.fftBandFrom,
                options.series?.[0]?.speed || 0
            ).toHz().value;

            const transformedBandTo = FrequencyConverter.fromType(
                currentAlert.fftBandFrequencyType,
                +currentAlert.fftBandTo,
                options.series?.[0]?.speed || 0
            ).toHz().value;

            const pixelFrom = xAxis.toPixels(transformedBandFrom);
            const pixelTo = xAxis.toPixels(transformedBandTo);

            if (pixelFrom + xOffset < xAxis.toPixels(0)) {
                xOffset = xAxis.toPixels(0) - pixelFrom;
            }
            if (pixelTo + xOffset > xAxis.toPixels(xAxis.max)) {
                xOffset = xAxis.toPixels(xAxis.max) - pixelTo;
            }

            from = xAxis.toValue(xOffset + pixelFrom);
            from = FrequencyConverter.fromHz(from, options.series?.[0]?.speed || 0).toType(currentAlert.fftBandFrequencyType).value;

            to = xAxis.toValue(xOffset + pixelTo);
            to = FrequencyConverter.fromHz(to, options.series?.[0]?.speed || 0).toType(currentAlert.fftBandFrequencyType).value;

            band.svgElem.translate(xOffset, 0);
            lineFrom.svgElem.translate(xOffset, 0);
            lineTo.svgElem.translate(xOffset, 0);
        };

        const stop = () => {
            setCurrent({...currentAlert, fftBandFrom: from, fftBandTo: to});
            $(document).unbind(".line");
        };

        if (typeof band?.svgElem?.translateX === "undefined") {
            band.svgElem.translate(0, 0);
        }
        start(e);
    };

    const dragPlotLine = (e, id) => {
        let xOffset, clickX, translateValue;
        const xAxis = chartRef.current.chart.xAxis[0];
        const band = _find(chartRef.current.chart.xAxis[0].plotLinesAndBands, {id: "band"});
        const line = _find(chartRef.current.chart.xAxis[0].plotLinesAndBands, {id: id});

        const start = (e) => {
            $(document).bind({
                "mousemove.line": step,
                "mouseup.line": stop,
                "click.line": stop,
            });
            clickX = e.pageX - line.svgElem.translateX;
        };

        const step = (e) => {
            xOffset = e.pageX - clickX;

            const transformedBandFrom = FrequencyConverter.fromType(
                currentAlert.fftBandFrequencyType,
                +currentAlert.fftBandFrom,
                options.series?.[0]?.speed || 0
            ).toHz().value;

            const transformedBandTo = FrequencyConverter.fromType(
                currentAlert.fftBandFrequencyType,
                +currentAlert.fftBandTo,
                options.series?.[0]?.speed || 0
            ).toHz().value;

            const pixelFrom = xAxis.toPixels(transformedBandFrom);
            const pixelTo = xAxis.toPixels(transformedBandTo);

            const lineValue = id === LINE_FROM_ID ? pixelFrom : pixelTo;
            const oppositeLineValue = id === LINE_FROM_ID ? pixelTo : pixelFrom;

            if (lineValue + xOffset < xAxis.toPixels(0)) {
                xOffset = xAxis.toPixels(0) - lineValue;
            }
            if (lineValue + xOffset > xAxis.toPixels(xAxis.max)) {
                xOffset = xAxis.toPixels(xAxis.max) - lineValue;
            }

            let pixelWidth = pixelTo - pixelFrom;
            const scaleFn = scaleMapFn[id];
            const scale = scaleFn(pixelWidth, xOffset);

            translateValue = xAxis.toValue(xOffset + lineValue);
            translateValue = FrequencyConverter.fromHz(translateValue, options.series?.[0]?.speed || 0).toType(currentAlert.fftBandFrequencyType).value;

            line.svgElem.translate(xOffset, 0);
            band.svgElem.translate(-(scale * oppositeLineValue - oppositeLineValue), 0);
            band.svgElem.attr({scaleX: scale});
        };

        const stop = () => {
            const keyForUpdate = bandValueMap[id];
            setCurrent({...currentAlert, [keyForUpdate]: translateValue});

            $(document).unbind(".line");
        };

        if (typeof line.svgElem.translateX === "undefined") {
            line.svgElem.translate(0, 0);
        }
        start(e);
    };

    const plotLines = currentAlert.id
        ? [
              createPlotLine({value: convertedBandTo, id: LINE_TO_ID, mousedown: dragPlotLine}),
              createPlotLine({value: convertedBandFrom, id: LINE_FROM_ID, mousedown: dragPlotLine}),
          ]
        : [];
    const plotBands = currentAlert.id ? [createPlotBand({valueFrom: convertedBandFrom, valueTo: convertedBandTo, mousedown: dragBandEvent})] : [];

    return {
        options: {
            ...options,
            chart: {...options.chart, height: 100},
            xAxis: {...options.xAxis, plotLines, plotBands},
        },
    };
};
