/* eslint-disable no-unused-vars */
import {useCurrentAlertCondition, useCurrentAlertGroup, useSelectedTab} from "../../../../stores/zustand/AlertConditionStore";
import {isEmpty as _isEmpty} from "lodash";
import {ALERT_TABS_NAME} from "../../constants/tabs";
import {useSimpleChartSeriesBuilder} from "../../../../hooks/factory/useSimpleChartSeriesBuilder";
import Helper from "../../../../helpers/helper";

export const useSimpleReadingOptions = ({options, withAlertGroup = false, enabled}) => {
    const alertGroup = useCurrentAlertGroup();

    const currentAlertCondition = useCurrentAlertCondition();
    const currentAlertGroup = useCurrentAlertGroup();

    const selectedTab = ALERT_TABS_NAME.LINEAR;

    let installPoints = [];
    if (!_isEmpty(currentAlertCondition)) {
        installPoints = selectedTab === withAlertGroup ? currentAlertGroup.points.map((i) => i) : [currentAlertCondition?.installationPoint];
    }

    const {installationPointId, axisId, type} = Helper.getHashParams();

    const {series, isLoading, isSuccess} = useSimpleChartSeriesBuilder({
        chartType: type,
        axisIds: [+axisId],
        pointsData: withAlertGroup ? alertGroup.points : [+installationPointId],
        deltaFlag: withAlertGroup,
        axisIdsComputed: [+axisId],
        manageAlertModal: true,
        axisCurrent: +axisId,
        enabled,
    });

    return {options: {...options, series: [...options.series, ...series]}, isLoading, isSuccess};
};
