import TabLinear from "../components/TabLinear/TabLinear";
import TabDelta from "../components/TabDelta/TabDelta";
import {TabBand} from "../components/TabBand/TabBand";
import {ChartBand} from "../components/ChartBand/ChartBand";
import {TabEnvelop} from "../components/TabEnvelop/TabEnvelop";
import ChartLinear from "../components/ChartLinear/ChartLinear";
import {ChartEnvelop} from "../components/ChartEnvelop/ChartEnvelop";
import {ChatTachometer} from "../components/ChartTachometer/ChatTachometer";
import ChartDelta from "../components/ChartDelta/ChartDelta";
import TabTachometer from "../components/TabTachometer/TabTachometer";

export const ALERT_TABS_NAME = {
    LINEAR: "linear",
    DELTA_T: "delta-t",
    TACHOMETER_RPM: "tachometer-rpm",
    ENVELOPE: "envelope",
    BAND: "band",
};

export const ALERT_TABS_FOR_ADAPTER = {
    [ALERT_TABS_NAME.LINEAR]: {
        title: "Linear",
        key: ALERT_TABS_NAME.LINEAR,
        AlertComponent: TabLinear,
        ChartComponent: ChartLinear,
    },
};

export const ALERT_TABS = {
    [ALERT_TABS_NAME.LINEAR]: {
        title: "Linear",
        key: ALERT_TABS_NAME.LINEAR,
        AlertComponent: TabLinear,
        ChartComponent: ChartLinear,
    },
    [ALERT_TABS_NAME.DELTA_T]: {
        title: "Delta T",
        key: ALERT_TABS_NAME.DELTA_T,
        AlertComponent: TabDelta,
        ChartComponent: ChartDelta,
    },
    [ALERT_TABS_NAME.TACHOMETER_RPM]: {
        title: "Tachometer-rpm",
        key: ALERT_TABS_NAME.TACHOMETER_RPM,
        AlertComponent: TabTachometer,
        ChartComponent: ChatTachometer,
    },
};

export const FFT_ALERT_TABS = {
    [ALERT_TABS_NAME.BAND]: {
        title: "Band",
        key: ALERT_TABS_NAME.BAND,
        AlertComponent: TabBand,
        ChartComponent: ChartBand,
    },
    [ALERT_TABS_NAME.ENVELOPE]: {
        title: "Envelope",
        key: ALERT_TABS_NAME.ENVELOPE,
        AlertComponent: TabEnvelop,
        ChartComponent: ChartEnvelop,
    },
};
