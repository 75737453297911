import ChartHelper from "../../../../../helpers/chart";
import {zoomPausedChart} from "../../../../../helpers/holdMode";
import {useChartRef} from "./useChartRef";
import {useChartActions, useSelectValueInitMax, useSelectValueInitMin} from "../store/hooks/useStoreContext";
import {useLockPoints} from "./useLockPoints";

export const useZoomReset = () => {
    const chartRef = useChartRef();
    const {setZoomed, setMinMax} = useChartActions();
    const valueInitMin = useSelectValueInitMin();
    const valueInitMax = useSelectValueInitMax();

    const lockPoints = useLockPoints();

    return () => {
        setZoomed(false);
        chartRef.current.chart.xAxis[0].zoom();
        chartRef.current.chart.yAxis[0].zoom();
        chartRef.current.chart.redraw();

        setMinMax({
            valueMax: +valueInitMax,
            valueMin: +valueInitMin,
        });

        chartRef.current.chart.isPointBPaused = false;
        ChartHelper.clearAdditionalPoints(chartRef.current.chart);
        lockPoints.current = [];
        if (chartRef.current.chart.holdPoints) {
            setTimeout(() => zoomPausedChart(chartRef.current), 0);
        }
    };
};
