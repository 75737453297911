import React, {useRef} from "react";
import {useSimpleChartOptions} from "../../hooks/charts/useSimpleChartOptions";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {useSimpleReadingOptions} from "../../hooks/charts/useSimpleReadingOptions";
import {useAlertOptions} from "../../hooks/charts/useAlertOptions";
import {Loader} from "../../../../shared";

const ChartDelta = () => {
    const chartRef = useRef(null);

    const options = useSimpleChartOptions();
    const {options: optionsWithSeries, isSuccess} = useSimpleReadingOptions({options, withAlertGroup: true});
    const {options: optionsWithAlerts} = useAlertOptions({options: optionsWithSeries, chartRef, withAlertGroup: true});

    if (!isSuccess) {
        return <Loader />;
    }

    return (
        <div>
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                constructorType={"chart"}
                options={optionsWithAlerts}
                updateArgs={[true, true, false]}
            />
        </div>
    );
};

export default ChartDelta;
