import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";
import {useMemo} from "react";
import Cacher from "../../../../../../helpers/cacher";
import {getBearingLines} from "../../helpers/plotLines";
import {useSpeedSettings} from "../helpers/useSpeedSettings";
import {useIsCircular} from "../../../hooks/useChartViewTypes";

export const useBearingBuilder = (equipment, series) => {
    const activeBearings = useChartStateStoreContext((state) => state.settings.activeBearings, shallow);
    const settings = useChartStateStoreContext((state) => state.settings, shallow);
    const factory = useChartStateStoreContext((state) => state.factory, shallow);
    const isCircular = useIsCircular(settings.chartViewType);
    const speedSettings = useSpeedSettings();
    return useMemo(() => {
        const xMaxCacher = new Cacher(() =>
            series.reduce((result, {data}) => {
                if (data && data.length) {
                    const lastX = data[data.length - 1][0];
                    return Math.max(lastX, result);
                }
                return result;
            }, 0)
        );
        return getBearingLines({
            xMaxCacher,
            settings,
            equipment,
            getBearingFn: factory.getBearingFn,
            speedSettings,
            series,
        });
    }, [activeBearings, isCircular, settings, equipment, factory.getBearingFn, speedSettings]);
};
