const alertTypeMap = {
    Caution: "caution",
    Warning: "warning",
};

export const transformAlertForRequest = (alert, versionType) => {
    const alertKey = alertTypeMap[alert.alertLevel.name];

    return {
        [alertKey]: +alert.value,
        [`${alertKey}_condition`]: alert.condition,
        [`${alertKey}_notification_type`]: alert.notificationType,
        [`${alertKey}_require_readings`]: alert.requireReadings,
        [`${alertKey}_rms_od`]: parseInt(alert.rmsOd),
        [`${alertKey}_f_max`]:  versionType === 1 ? 12800 : 2500,
        [`${alertKey}_lines_of_resolutions`]: 1024,
    };
};

export const transformToRequest = ({axisId, alerts, equipmentId, locationId, installationPointId, overwrite}) => {
    return {
        data: {
            [axisId]: alerts,
        },
        equipmentId,
        locationId: locationId ?? false,
        installationPointId,
        overwrite,
    };
};
