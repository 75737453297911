import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiAlertCondition from "../../../api/alertCondition";
import {getAlertConditionListQueryKeys} from "./useAlertConditionListQuery";

export const useAlertConditionCreateMutation = ({chartType, onSuccess}) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ApiAlertCondition.create,
        onSuccess: async (data, variables) => {
            const queryKey = getAlertConditionListQueryKeys({chartType});

            onSuccess?.(data, variables);
            return queryClient.refetchQueries({queryKey});
        },
    });
};
