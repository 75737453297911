import ChartHelper from "../../../helpers/chart";

const CHART_ZOOM_COLOR = "rgba(227, 187, 49, 0.22)";

const getValuesForZoom = (axis, start, end) => {
    let zoomStart = axis[0].toValue(start);
    let zoomEnd = axis[0].toValue(end);

    if (zoomEnd > axis[0].dataMax && zoomStart < axis[0].dataMax) {
        zoomEnd = axis[0].dataMax;
    }

    if (zoomStart < axis[0].dataMin && zoomEnd > axis[0].dataMin) {
        zoomStart = axis[0].dataMin;
    }

    if (zoomEnd < 0 && zoomStart < 0) {
        return {zoomStart: 0, zoomEnd: 0};
    }

    return {zoomStart, zoomEnd};
};

const applyZoomForAxis = (axis, zoomStart, zoomEnd) => {
    if (zoomStart === zoomEnd) {
        return;
    }

    if (!Array.isArray(axis)) {
        axis = [axis];
    }

    axis.forEach((axis) => {
        axis.setExtremes(zoomStart, zoomEnd, undefined, false, {trigger: "zoom"});
    });
};

const fireEventsForChart = (Highcharts, chart) => {
    Highcharts.fireEvent(chart, "selection");
};

const getSvgDrawAttr = (zoomOptions, e) => {
    const {
        zoomType,
        triggerEvent: {chartX, chartY},
        chart,
    } = zoomOptions;

    let x = 0,
        y = 0,
        width = chart.chartWidth,
        height = chart.chartHeight;

    const moveX = e.clientX - zoomOptions.triggerEvent.clientX;
    const moveY = e.clientY - zoomOptions.triggerEvent.clientY;

    if (zoomType === "y" || zoomType === "xy") {
        y = moveY < 0 ? chartY + moveY : chartY;
        height = moveY < 0 ? -moveY : moveY;
    }

    if (zoomType === "x" || zoomType === "xy") {
        x = moveX < 0 ? chartX + moveX : chartX;
        width = moveX < 0 ? -moveX : moveX;
    }

    return {width, height, x, y};
};

export const wrapChartZoomMovement = (Highcharts) => {
    if (!Highcharts.syncZoomOptions) {
        Highcharts.syncZoomOptions = {};
    }

    Highcharts.wrap(Highcharts.Pointer.prototype, "onContainerMouseDown", function (proceed, e) {
        if (e.target.hcEvents?.mousedown?.length > 0) {
            return;
        }

        const zoomType = this.chart.userOptions.chart.wrapZoomType;

        if (!zoomType) {
            return proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        }

        Highcharts.zoomOptions = {
            triggerEvent: e,
            chart: this.chart,
            zoomRect: this.chart.renderer
                .rect(0, 0, 0, 0)
                .attr({
                    fill: CHART_ZOOM_COLOR,
                    "stroke-width": 2,
                })
                .add(),
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            zoomType,
        };

        proceed.apply(this, Array.prototype.slice.call(arguments, 1));
    });

    function mousemove(e) {
        const {zoomOptions} = Highcharts;

        if (!zoomOptions) {
            return;
        }

        const attr = getSvgDrawAttr(zoomOptions, e);

        zoomOptions.zoomRect.attr(attr);

        Highcharts.zoomOptions = {...zoomOptions, ...attr};
    }

    function mouseup() {
        const {zoomOptions} = Highcharts;

        if (!zoomOptions) {
            return;
        }

        const {chart, x, y, width, height, zoomType} = zoomOptions;
        const zoomGroup = chart.userOptions?.chart?.syncZoomGroup;

        Highcharts.zoomOptions.zoomRect.destroy();
        Highcharts.zoomOptions = null;

        const isChartZoomMustSync = !!chart.userOptions.chart.syncZoomGroup;

        const chartsToFire = isChartZoomMustSync
            ? Highcharts.charts.filter((item) => item?.userOptions?.chart?.syncZoomGroup === chart.userOptions?.chart?.syncZoomGroup)
            : [chart];

        Highcharts.syncZoomOptions[zoomGroup] = {};
        if (chart?.isBulkDelete) {
            const {zoomStart, zoomEnd} = getValuesForZoom(chart.xAxis, x, x + width);
            const pointZoomStart = ChartHelper.getNearestPointIndex(chart.series[0], zoomStart);
            const pointZoomEnd = ChartHelper.getNearestPointIndex(chart.series[0], zoomEnd);
            chart.zoomedPoints = {zoomStart: pointZoomStart, zoomEnd: pointZoomEnd};
            return fireEventsForChart(Highcharts, chart);
        }

        if (zoomType === "xy" || zoomType === "y") {
            const {zoomStart, zoomEnd} = getValuesForZoom(chart.yAxis, y + height, y);
            if (zoomEnd === zoomStart) {
                return;
            }

            Highcharts.syncZoomOptions[zoomGroup].yAxisStart = zoomStart;
            Highcharts.syncZoomOptions[zoomGroup].yAxisEnd = zoomEnd;

            chartsToFire.reduce((arr, item) => [...arr, ...item.yAxis], []).forEach((item) => applyZoomForAxis(item, zoomStart, zoomEnd));
        }

        if (zoomType === "xy" || zoomType === "x") {
            const {zoomStart, zoomEnd} = getValuesForZoom(chart.xAxis, x, x + width);
            if (zoomEnd === zoomStart) {
                return;
            }

            Highcharts.syncZoomOptions[zoomGroup].xAxisStart = zoomStart;
            Highcharts.syncZoomOptions[zoomGroup].xAxisEnd = zoomEnd;

            chartsToFire.reduce((arr, item) => [...arr, ...item.xAxis], []).forEach((item) => applyZoomForAxis(item, zoomStart, zoomEnd));
        }

        if (y + height === y && x === x + width) {
            return;
        }

        if (!isChartZoomMustSync) {
            fireEventsForChart(Highcharts, chart);
        }

        chartsToFire.forEach((item) => fireEventsForChart(Highcharts, item));
    }

    Highcharts.addEvent(document, "mousemove", mousemove);

    Highcharts.addEvent(document, "mouseup", mouseup);

    return () => {
        Highcharts.removeEvent(document, "mousemove", mousemove);
        Highcharts.removeEvent(document, "mouseup", mouseup);
    };
};
