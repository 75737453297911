import React, {useState} from "react";
import PropTypes from "prop-types";
import FrequencyConverter from "../../../helpers/frequency-converter";
import AlertActions from "../../../modals/alert/components/AlertActions/AlertActions";
import SelectWrapper from "../../../helpers/select-wrapper";
import {FREQUENCY_TYPES} from "../../../constants/constants";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {useCurrentSpeed} from "../../../modals/alert/hooks/helpers/useCurrentSpeed";
import {useAlertConditionStoreActions, useCurrentAlertCondition, useCurrentIsChanged} from "../../../stores/zustand/AlertConditionStore";
import {AlertLabel} from "../AlertLabel/AlertLabel";
import EmailTemplate from "../../../modals/alert/parts/email-template";
import {get as _get} from "lodash";
import RmsOdTemplate from "../../../modals/alert/parts/rms-od-template";
import Helper from "../../../helpers/helper";
import {useChartUnits} from "../hooks/useChartUnits";

const BandAlertTemplate = ({alert, variant, onChange, isCurrent, originalAlert, onSelect, index}) => {
    const [errorMessage, setErrorMessage] = useState(null);

    const chartTypes = useChartTypesStore();
    const units = useChartUnits();
    const speedConfig = useCurrentSpeed();
    const currentSpeed = speedConfig.value || 0;
    const currentIsChanged = useCurrentIsChanged();
    const currentAlertCondition = useCurrentAlertCondition();
    const {type: chartType = "1"} = Helper.getHashParams();
    const alias = _get(chartTypes, [chartType, "alias"]) || "";

    const {setConfirmRemove} = useAlertConditionStoreActions();

    const currentFrequency =
        currentSpeed === 0 && currentAlertCondition?.fftBandFrequencyType === FREQUENCY_TYPES.ORDERS
            ? FREQUENCY_TYPES.HZ
            : currentAlertCondition?.fftBandFrequencyType;

    const reset = () => {
        onChange(alert);
    };

    const update = (data) => {
        if (data.email === "1") {
            return onChange({...alert, ...data, email: data.email + ""});
        }
        onChange({...alert, ...data});
    };

    if (alert.fftAlertType !== 1) return "";
    return (
        <div
            className={`form-group mb-3 alert-level-block-big alert-level-block${isCurrent ? " current" : ""}`}
            onClick={onSelect}
        >
            <div className="row flex-xl-nowrap justify-content-end">
                <div className="mr-auto">
                    <AlertLabel variant={variant} />
                </div>
                <div className="col-12 col-md-6 col-xl-3 mb-3 mb-md-0">
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.condition}
                        onChange={(ev) => update({condition: ev.target.value})}
                        disabled={!isCurrent}
                        name={"level-rand"}
                    >
                        {Object.keys(alert.availableConditions || {}).map((key) => (
                            <option
                                key={key}
                                value={key}
                            >
                                {key}
                            </option>
                        ))}
                    </SelectWrapper>
                </div>
                <div className="col-12 col-md-4 col-xl-6 mb-3 mb-md-0">
                    <div className="input-group input-group-sm">
                        <input
                            id={`${variant}-${index}-input`}
                            className={`form-control request-form-control${isCurrent ? " current-alert-value" : ""}`}
                            type="number"
                            value={alert.value.toString() || ""}
                            step={0.1}
                            min={0}
                            onChange={(ev) => update({value: ev.target.value})}
                            disabled={!isCurrent}
                        />
                        {!!units && (
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">{units}</span>
                            </div>
                        )}
                    </div>
                    {errorMessage && isCurrent && <span className="error-msg d-block color-danger mt-1 text-right">{errorMessage}</span>}
                </div>
            </div>
            <div className="row justify-content-end mt-2">
                <div className="col-12 col-md-3 mb-1 mb-md-0">
                    <span className="trigger-type label-horizontal-input d-block mt-2">Trigger Type</span>
                </div>
                <div className="col-md-9">
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.notificationType || ""}
                        onChange={(ev) => update({notificationType: +ev.target.value})}
                        disabled={!isCurrent}
                        name={"trigger-type"}
                    >
                        <option value={1}>Alert only when reading exceeds the last active alert</option>
                        <option value={2}>Alert for every reading</option>
                    </SelectWrapper>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 col-md-3 mb-1 mb-md-0">
                    <span>From / To</span>
                </div>
                <div className="col-md-3">
                    <input
                        className={`form-control request-form-control${isCurrent ? " current-alert-fftBandFrom" : ""}`}
                        type="number"
                        id={`${variant}-${index}-from-input`}
                        value={alert.fftBandFrom || ""}
                        step={1}
                        min={0}
                        onChange={(ev) => update({fftBandFrom: ev.target.value, fft_band_from: ev.target.value})}
                        disabled={!isCurrent}
                    />
                </div>
                <div className="col-md-3">
                    <input
                        className={`form-control request-form-control${isCurrent ? " current-alert-fftBandTo" : ""}`}
                        type="number"
                        value={alert.fftBandTo || ""}
                        id={`${variant}-${index}-to-input`}
                        step={1}
                        min={1}
                        onChange={(ev) => update({fftBandTo: ev.target.value, fft_band_to: ev.target.value})}
                        disabled={!isCurrent}
                    />
                </div>
                <div className="col-md-3">
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.fftBandFrequencyType || FREQUENCY_TYPES.HZ}
                        onChange={(ev) => {
                            let fftBandFrom = +FrequencyConverter.fromType(currentFrequency, alert.fftBandFrom, currentSpeed)
                                .toType(ev.target.value)
                                .numberFormat();

                            let fftBandTo = +FrequencyConverter.fromType(currentFrequency, alert.fftBandTo, currentSpeed)
                                .toType(ev.target.value)
                                .numberFormat();

                            update({
                                fftBandFrequencyType: ev.target.value,
                                fftBandFrom: fftBandFrom.toString(),
                                fftBandTo: fftBandTo.toString(),
                            });
                        }}
                        disabled={!isCurrent}
                        name={"band-type"}
                    >
                        <option value={FREQUENCY_TYPES.HZ}>{FREQUENCY_TYPES.HZ}</option>
                        <option value={FREQUENCY_TYPES.CPM}>{FREQUENCY_TYPES.CPM}</option>
                        <option
                            value={FREQUENCY_TYPES.ORDERS}
                            disabled={currentSpeed <= 0}
                        >
                            {FREQUENCY_TYPES.ORDERS}
                        </option>
                    </SelectWrapper>
                </div>
            </div>
            <hr />
            <RmsOdTemplate
                alert={alert}
                alias={alias}
                isCurrent={isCurrent}
                update={update}
            />
            <EmailTemplate
                alert={alert}
                update={update}
            />
            <div>
                <AlertActions
                    isCurrent={isCurrent}
                    level={variant}
                    isChanged={currentIsChanged || true}
                    reset={reset}
                    remove={() => setConfirmRemove((prev) => !prev)}
                    alert={alert}
                    originalAlert={originalAlert}
                    setErrorMessage={setErrorMessage}
                />
            </div>
        </div>
    );
};

BandAlertTemplate.propTypes = {
    alert: PropTypes.object,
    originalAlert: PropTypes.object,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    isCurrent: PropTypes.bool,
    index: PropTypes.number,
};

export default BandAlertTemplate;
