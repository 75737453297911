import React from "react";
import {AlertList} from "../AlertList/AlertList";
import PropTypes from "prop-types";

export const TabEnvelop = () => {
    return <AlertList variant="envelop" />;
};

TabEnvelop.propTypes = {
    chartTypes: PropTypes.array,
};
