import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import useGroupEmailUsersQuery from "../../../../hooks/api/Alert/useGroupEmailUsersQuery";
import {get as _get} from "lodash";
import {useAlertConditionCreateMutation} from "../../../../hooks/api/alertCondition/useAlertConditionCreateMutation";
import Helper from "../../../../helpers/helper";
import {useAlertConditionDeleteMutation} from "../../../../hooks/api/alertCondition/useAlertConditionDeleteMutation";
import {useAlertConditionStoreActions, useCurrentAlertGroup, useSelectedTab} from "../../../../stores/zustand/AlertConditionStore";
import {useAlertConditionUpdateMutation} from "../../../../hooks/api/alertCondition/useAlertConditionUpdateMutation";
import Toast from "../../../../pages/shared/toast";
import SweetAlert from "react-bootstrap-sweetalert";
import {ALERT_TABS_NAME} from "../../constants/tabs";
import {AXIS_NAME} from "../../../../constants/axis";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import swal from "../../../../pages/shared/swal";
import {useEnvelopeAverage} from "../../hooks/useEnvelopeAverage";
import {useInstallationPoint} from "../../hooks/useInstallationPoint";
import {normalizeAlertCondition} from "../../../../helpers/alerts/alertCondition";

const AlertActions = ({isCurrent, alert, originalAlert, setErrorMessage}) => {
    const locationId = _get(alert, "installationPoint.equipment.location_id");
    const {type: chartType} = Helper.getHashParams();
    const {setShowAdditionalAlert, setCurrent, setChangeGroupId} = useAlertConditionStoreActions();
    const currentGroup = useCurrentAlertGroup();
    const currentTab = useSelectedTab();
    const [isDeleteAlert, setIsDeleteAlert] = useState(false);
    const [isSaveAlertForAllAxis, setIsSaveAlertForAllAxis] = useState(false);
    const {equipmentItem} = useEquipmentByParams();
    const installationPoint = useInstallationPoint(equipmentItem, chartType);
    const versionType = +((installationPoint.sensor || {}).version_type || 0);

    const isChanged = originalAlert !== alert;
    const {isLoading: isEnvelopeAverageLoading, fetchStatus} = useEnvelopeAverage();

    useEffect(() => {
        setErrorMessage?.(null);
    }, [currentTab]);

    const {isSuccess} = useGroupEmailUsersQuery({
        alertLevelId: +alert.alertLevel?.id,
        equipmentId: _get(alert, ["installationPoint", "equipment_id"]),
        instPointId: _get(alert, ["installationPointId"]),
        locationId: locationId,
        chartType: _get(alert, "chartType"),
    });

    const onSuccess = (data, variables) => {
        if (variables.alertType === ALERT_TABS_NAME.DELTA_T) {
            setChangeGroupId(data.result[0].group_id);
        }
        setShowAdditionalAlert({levelName: alert.alertLevel.name, value: false});
        Toast.success("The alert has been added.");
        if (currentTab === ALERT_TABS_NAME.ENVELOPE) {
            setCurrent(normalizeAlertCondition(chartType, data.result[0]));
            return;
        }
        setCurrent({});
    };

    const onDeleteSuccess = () => {
        setCurrent({});
    };

    const onUpdateSuccess = () => {
        Toast.success("The alert has been updated.");
        if (currentTab === ALERT_TABS_NAME.ENVELOPE) {
            return;
        }
        setCurrent({});
    };

    const {mutateAsync: createAlertCondition, isLoading: isAlertCreateLoading} = useAlertConditionCreateMutation({chartType, onSuccess});
    const {mutateAsync: deleteAlertCondition, isLoading: isAlertDeleteLoading} = useAlertConditionDeleteMutation({chartType, onSuccess: onDeleteSuccess});
    const {mutateAsync: updateAlertCondition, isLoading: isAlertUpdateLoading} = useAlertConditionUpdateMutation({chartType, onSuccess: onUpdateSuccess});

    const handleSaveAllAlerts = () => {
        let localAlert = {...alert, alertType: currentTab};
        const handleFn = localAlert.isBlank ? createAlertCondition : updateAlertCondition;

        if (localAlert.rmsOd === "1") {
            if (!localAlert.f_max) {
                localAlert.f_max = versionType === 1 ? 12800 : 2500;
            }
            if (!localAlert.lines_of_resolution) {
                localAlert.lines_of_resolution = 1024;
            }
        }

        if (currentTab === ALERT_TABS_NAME.DELTA_T) {
            handleFn({
                ...localAlert,
                groupPoints: currentGroup.points,
                alertType: ALERT_TABS_NAME.DELTA_T,
                groupId: currentGroup.id,
                axes: [AXIS_NAME.X, AXIS_NAME.Y, AXIS_NAME.Z],
                isOverride: 1,
                axisId: 1,
                axis_id: 1,
            });
            setIsSaveAlertForAllAxis(false);
            return;
        }

        handleFn({
            ...localAlert,
            axes: [AXIS_NAME.X, AXIS_NAME.Y, AXIS_NAME.Z],
            isOverride: currentTab === ALERT_TABS_NAME.BAND || (currentTab === ALERT_TABS_NAME.TACHOMETER_RPM && +chartType !== 14) ? 0 : 1,
            axisId: 1,
            axis_id: 1,
        });
        setIsSaveAlertForAllAxis(false);
    };

    const handleSave = async () => {
        setErrorMessage?.(null);
        let localAlert = {...alert};
        const handleFn = localAlert.isBlank ? createAlertCondition : updateAlertCondition;

        if (currentTab === ALERT_TABS_NAME.TACHOMETER_RPM) {
            let isErr = false;
            if (!alert.tachometerRpmFrom) {
                setErrorMessage((prev) => ({...prev, tachometerRpmFrom: true}));
                isErr = true;
            }
            if (!alert.tachometerRpmTo) {
                setErrorMessage((prev) => ({...prev, tachometerRpmTo: true}));
                isErr = true;
            }

            if (isErr) {
                return;
            }
        }

        if (localAlert.rmsOd === "1") {
            if (!localAlert.f_max) {
                localAlert.f_max = versionType === 1 ? 12800 : 2500;
            }
            if (!localAlert.lines_of_resolution) {
                localAlert.lines_of_resolution = 1024;
            }
        }

        if (currentTab === ALERT_TABS_NAME.DELTA_T) {
            const currentInstallationPoints = {};

            equipmentItem.installationPoints.forEach((instPoint) => (currentInstallationPoints[instPoint.id] = true));

            const isHasEquipmentInstallPoint = currentGroup.points.some((item) => currentInstallationPoints[item]);

            if (currentGroup.points.length < 2) {
                Toast.warning("You must select at least two installation points.");
                return;
            }
            if (!isHasEquipmentInstallPoint) {
                Toast.warning("You must select at least one installation point from current equipment.");
                return;
            }
        }

        if (localAlert.axisId === 0 && currentTab !== ALERT_TABS_NAME.BAND) {
            setIsSaveAlertForAllAxis(true);
            return;
        }

        if (localAlert.axisId === 0 && (currentTab === ALERT_TABS_NAME.BAND || (currentTab === ALERT_TABS_NAME.TACHOMETER_RPM && +chartType !== 14))) {
            handleSaveAllAlerts();
            return;
        }

        try {
            if (currentTab === ALERT_TABS_NAME.DELTA_T) {
                await handleFn({
                    ...localAlert,
                    groupPoints: currentGroup.points,
                    alertType: ALERT_TABS_NAME.DELTA_T,
                    groupId: currentGroup.id || null,
                    alertGroupId: currentGroup.id || null,
                });
                return;
            }

            await handleFn(localAlert);
        } catch (e) {
            if (!setErrorMessage) {
                swal("", e.message, "error", 15000);
                return;
            }
            if (currentTab === ALERT_TABS_NAME.TACHOMETER_RPM) {
                setErrorMessage?.((prev) => ({...prev, input: e.message}));
                return;
            }
            setErrorMessage?.(e.message);
        }
    };

    const handleDelete = async () => {
        setIsDeleteAlert(false);
        if (alert.isBlank) {
            setShowAdditionalAlert({levelName: alert.alertLevel.name, value: false});
            return;
        }

        if (alert?.axes_ids?.length) {
            const promisify = alert.axes_ids.map((id) => deleteAlertCondition({alert_condition_id: id, isGroupAlert: !!alert.groupId}));
            await Promise.all(promisify);
            Toast.success("The alert has been deleted.");

            return;
        }

        await deleteAlertCondition({alert_condition_id: alert.id, isGroupAlert: !!alert.groupId});
        Toast.success("The alert has been deleted.");
    };

    const handleReset = () => {
        setCurrent(originalAlert);
    };

    const handleDeletePress = (e) => {
        e.stopPropagation();
        setIsDeleteAlert(true);
    };

    if (!isSuccess) {
        return null;
    }

    return (
        <>
            {(alert.id || (isCurrent && isChanged)) && <hr className="my-2" />}

            <div className="row controls-button-alerts-level">
                <div className="col-md-12">
                    {isCurrent && isChanged && (
                        <React.Fragment>
                            <button
                                className="btn btn-sm btn-primary pull-right"
                                style={{padding: "4px 8px"}}
                                disabled={
                                    isAlertCreateLoading ||
                                    isAlertUpdateLoading ||
                                    (isEnvelopeAverageLoading && fetchStatus !== "idle") ||
                                    (currentTab === ALERT_TABS_NAME.ENVELOPE && !alert.fftEnvelopeData?.length)
                                }
                                onClick={handleSave}
                            >
                                {isEnvelopeAverageLoading && fetchStatus !== "idle" ? "Calculating" : "Save"}
                            </button>
                            <button
                                className="btn btn-sm btn-secondary pull-right"
                                style={{marginRight: 10, padding: "4px 8px"}}
                                onClick={handleReset}
                            >
                                Cancel
                            </button>
                        </React.Fragment>
                    )}
                    {!alert.isBlank && (
                        <button
                            className="btn btn-sm btn-danger pull-right btn-remove"
                            style={{padding: "4px 8px"}}
                            onClick={handleDeletePress}
                            disabled={isAlertDeleteLoading}
                        >
                            Delete
                        </button>
                    )}
                </div>
            </div>
            {isDeleteAlert && (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title="DELETE ALERT"
                    onConfirm={handleDelete}
                    onCancel={() => setIsDeleteAlert(false)}
                >
                    Are you sure you want to delete this alert?
                </SweetAlert>
            )}
            {isSaveAlertForAllAxis && (
                <SweetAlert
                    warning
                    showCancel
                    cancelBtnText="Cancel"
                    confirmBtnText="Yes"
                    cancelBtnBsStyle="default"
                    btnSize="xs"
                    title=""
                    onConfirm={handleSaveAllAlerts}
                    onCancel={() => setIsSaveAlertForAllAxis(false)}
                >
                    Are you sure you want to save the alert condition for all axes? If you proceed, any existing alert conditions set for individual axes
                    will be updated and replaced by the new alert condition for all axes.
                </SweetAlert>
            )}
        </>
    );
};

AlertActions.propTypes = {
    reset: PropTypes.func,
    save: PropTypes.func,
    remove: PropTypes.func,
    setErrorMessage: PropTypes.func,
    isEnvelope: PropTypes.bool,
    isCurrent: PropTypes.bool,
    inProgress: PropTypes.bool,
    isProfile: PropTypes.bool,
    alert: PropTypes.object,
    originalAlert: PropTypes.object,
    level: PropTypes.object,
};

export default AlertActions;
