import {useContext} from "react";
import {ChartStateStore} from "../../context/context";
import {useStore} from "zustand";

export const useChartStateStoreContext = (selector, equalityFn) => {
    const store = useContext(ChartStateStore);
    if (!store) {
        throw new Error("Missing chart state context");
    }
    return useStore(store, selector, equalityFn);
};

export const useChartActions = () => useChartStateStoreContext((state) => state.actions);
export const useSelectChartWaterfallSort = () => useChartStateStoreContext((state) => state.settings.waterfallSort);
export const useSelectChartIsFaultFrequencyDetectionMode = () => useChartStateStoreContext((state) => state.settings.isFaultFrequencyDetectionMode);
export const useSelectChartPointsForDetection = () => useChartStateStoreContext((state) => state.settings.pointsForDetection);
export const useSelectChartType = () => useChartStateStoreContext((state) => state.settings.chartType);
export const useSelectChartSelectedPoint = () => useChartStateStoreContext((state) => state.settings.selectedPoint);

export const useSelectUnixFrom = () => useChartStateStoreContext((state) => state.settings.unixFrom);
export const useSelectUnixTo = () => useChartStateStoreContext((state) => state.settings.unixTo);

export const useSelectValueInitMin = () => useChartStateStoreContext((state) => state.settings.valueInitMin);
export const useSelectValueInitMax = () => useChartStateStoreContext((state) => state.settings.valueInitMax);
export const useSelectZoomed = () => useChartStateStoreContext((state) => state.settings.zoomed);
