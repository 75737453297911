import React from "react";
import PropTypes from "prop-types";
import {get as _get} from "lodash";
import Helper from "../../../helpers/helper";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import useAxisLabelQuery from "../../../hooks/api/axisLabel/useAxisLabelQuery";

const chartTypesWithoutAxis = {
    1: true,
    13: true,
    14: true,
    45: true,
};

const AxisTypeControl = ({onAxisChange, axisId, chartType, withAllAxis, equipmentId}) => {
    const {installationPointId} = Helper.getHashParams();

    const chartTypes = useChartTypesStore();
    const {axisLabelsChart: axisLabels} = useAxisLabelQuery(equipmentId);

    if (chartTypesWithoutAxis[+chartType]) {
        return null;
    }

    return (
        <div className="form-group row align-items-center mb-3">
            <label className="col-4 mb-0">Axis Type:</label>
            <div className="col-8">
                <div className="axis-block-container">
                    {withAllAxis && (
                        <div
                            className={`axis-block ${+axisId === 0 ? "active" : ""}`}
                            onClick={() => onAxisChange("0")}
                        >
                            <span>All</span>
                        </div>
                    )}
                    {Object.keys((chartTypes[chartType] || {}).series || {}).map((key) => {
                        const axisLabel = _get(axisLabels, [key, installationPointId]);
                        return (
                            <div
                                key={key}
                                className={`axis-block ${+axisId === +key ? "active" : ""}`}
                                onClick={() => onAxisChange(key)}
                            >
                                <span>{axisLabel || chartTypes[chartType].series[key].axisName}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

AxisTypeControl.propTypes = {
    withAllAxis: PropTypes.bool,
    axisId: PropTypes.number,
    chartType: PropTypes.number,
    equipmentId: PropTypes.number,
    onAxisChange: PropTypes.func,
};

export default AxisTypeControl;
