import {useSimpleChartReadings} from "../api/simpleChartReadings/useSimpleChartReadings";
import {useInstallationPointColors, useIsDarkTheme} from "../helpers/theme";
import {find as _find, get as _get} from "lodash";
import {DATEFORMAT, IDLE_THRESHOLD_TYPES, AXES_COLORS, AXES_COLORS_DARK} from "../../constants/constants";
import {useMemo} from "react";
import {useChartSelectedRangeStore} from "../../stores/zustand/ChartSelectedRangeStore";
import useAxisLabelQuery from "../api/axisLabel/useAxisLabelQuery";
import {useEquipmentByParams} from "../api/equipment/useEquipmentQuery";
import useUserProfile from "../api/Global/useUserProfile";
import {useSeriesList} from "../helpers/useSeriesList";
import {useCurrentAlertGroup} from "../../stores/zustand/AlertConditionStore";
import {useChartTypesStore} from "../../stores/zustand/ChartSelectedChartTypeStore";

export const useSimpleChartSeriesBuilder = ({
    chartType,
    axisIds,
    pointsData,
    deltaFlag = false,
    axisIdsComputed,
    manageAlertModal = false,
    axisCurrent,
    enabled = true,
}) => {
    const seriesList = useSeriesList(chartType);
    const {equipmentItem} = useEquipmentByParams();
    const {data: user} = useUserProfile();
    const {axisLabelsChart: axisLabels, isSuccess: isAxisLabelsSuccess} = useAxisLabelQuery(equipmentItem.id);
    const pointsIDs = pointsData.map((point) => point?.id || point);
    const axisIdsModal = manageAlertModal ? axisIdsComputed : axisIds;
    const {
        data: readingsData,
        isSuccess,
        isLoading,
        isRefetching,
    } = useSimpleChartReadings({
        installPointsIds: pointsIDs,
        axisIds: axisIdsModal,
        chartType,
        enabled,
    });
    const currentAlertGroup = useCurrentAlertGroup();
    const chartTypes = useChartTypesStore();
    const rangeSelector = useChartSelectedRangeStore();

    const isDarkTheme = useIsDarkTheme();
    const installPointColors = useInstallationPointColors();

    let colorIndex = 0;
    const pickedColor = [];

    const getColor = (pointData, isIgnorePointColor = false) => {
        let color = pointData.color && !isIgnorePointColor ? pointData.color : installPointColors[colorIndex + pointsData.length];

        if (pickedColor.length === installPointColors.length) {
            return color;
        }
        if (!pickedColor.includes(color)) {
            ++colorIndex;
            pickedColor.push(color);
            return color;
        }
        ++colorIndex;
        return getColor(pointData, true);
    };

    const generateSeries = () => {
        if (!isAxisLabelsSuccess || !isSuccess) {
            return [];
        }

        let axisIndex = 0;
        let pointIndex = 0;

        return readingsData
            .filter((item) => !!item.readings.length)
            .map((readingsData) => {
                const {readings, installPointId, axisId} = readingsData;

                let pointData = [];
                let pointName;
                let pointColor;

                if (manageAlertModal) {
                    if (deltaFlag) {
                        if (axisCurrent === 0) {
                            if (axisIndex > 2) {
                                pointIndex++;
                                axisIndex = 1;
                            } else {
                                axisIndex++;
                            }

                            colorIndex = axisIndex;
                            pointName = _get(currentAlertGroup, ["names", pointIndex]);
                            pointName += " - " + _get(chartTypes[chartType], ["series", axisId, "axisName"]);
                            pointColor = isDarkTheme === "true" ? AXES_COLORS_DARK[colorIndex] : AXES_COLORS[colorIndex];
                        } else {
                            pointName = _get(currentAlertGroup, ["names", pointIndex]);
                            pointColor = installPointColors[pointIndex];
                            pointIndex++;
                        }
                    } else {
                        if (axisCurrent === 0) {
                            if (axisIndex > 2) {
                                pointIndex++;
                                axisIndex = 1;
                            } else {
                                axisIndex++;
                            }
                            colorIndex = axisIndex;
                            pointName = _get(chartTypes[chartType], ["series", axisId, "axisName"]);
                        } else {
                            colorIndex = axisId;
                            pointIndex++;
                        }
                        pointColor = isDarkTheme === "true" ? AXES_COLORS_DARK[colorIndex] : AXES_COLORS[colorIndex];
                    }

                    return {
                        name: pointName || "",
                        color: pointColor,
                        data: readings || [],
                        dataGrouping: {
                            approximation: "high",
                            forced: true,
                        },
                    };
                } else {
                    pointData = Array.isArray(pointsData) ? _find(pointsData, {id: installPointId}) : pointsData;
                }

                if (!pointData) {
                    return [];
                }

                let color = getColor(pointData);

                const isMultipleAxisSelected = pointsData.length === 1;
                if (isMultipleAxisSelected && Object.keys(seriesList.list).length > 1) {
                    color = isDarkTheme === "true" ? AXES_COLORS_DARK[axisId] : AXES_COLORS[axisId];
                }
                const axisLabel = _get(axisLabels, [axisId, pointData.id]) || seriesList.list[axisId]?.axisName + " - Axis";

                let seriesName = (pointData.name || "---") + ` - ${axisLabel}`;

                seriesName = pointsData.length && pointData.equipment_name ? pointData.equipment_name + ": " + seriesName : seriesName;

                return {
                    id: `${pointData.id}_${axisId}`,
                    name: seriesName,
                    data: readings,
                    index: 0,
                    installationPointId: pointData.id,
                    datetimeFormat: _get(user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0]),
                    units: seriesList.units,
                    color: color,
                    precision: seriesList.precision,
                    idle_threshold:
                        (pointData.idle_threshold_type === IDLE_THRESHOLD_TYPES.ACC && +chartType === 2) ||
                        (pointData.idle_threshold_type === IDLE_THRESHOLD_TYPES.VEL && +chartType === 3)
                            ? +pointData.idle_threshold
                            : null,
                    dataGrouping: {
                        approximation: "high",
                    },
                };
            });
    };

    const series = useMemo(() => {
        return generateSeries();
    }, [
        isLoading,
        isSuccess,
        isRefetching,
        pointsData,
        rangeSelector,
        seriesList,
        axisLabels,
        axisIds,
        currentAlertGroup,
        rangeSelector.from,
        rangeSelector.to,
        rangeSelector.range,
    ]);

    return {series, isLoading, isSuccess, isRefetching};
};
