import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiAlertCondition from "../../../api/alertCondition";
import {getAlertConditionListQueryKeys} from "./useAlertConditionListQuery";

export const useAlertConditionUpdateMutation = ({chartType, onSuccess}) => {
    const queryClient = useQueryClient();

    return useMutation(
        (data) => {
            return ApiAlertCondition.update(data);
        },
        {
            onSuccess: async () => {
                onSuccess?.();
                const queryKey = getAlertConditionListQueryKeys({chartType});

                return queryClient.refetchQueries({queryKey});
            },
        }
    );
};
