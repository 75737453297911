import {useMutation, useQueryClient} from "@tanstack/react-query";
import ApiAlertCondition from "../../../api/alertCondition";
import {getAlertConditionListQueryKeys} from "./useAlertConditionListQuery";

export const useAlertConditionDeleteMutation = ({chartType, onSuccess}) => {
    const queryClient = useQueryClient();

    return useMutation((data) => ApiAlertCondition.delete(data), {
        onSuccess: async (_, variables) => {
            onSuccess?.();
            const queryKey = getAlertConditionListQueryKeys({chartType});

            if (variables.isGroupAlert) {
                return queryClient.refetchQueries({queryKey});
            }

            queryClient.setQueriesData(queryKey, (old) => {
                const listByChartType = old.list[+chartType];
                const updatedList = listByChartType.filter((item) => item.id !== variables.alert_condition_id);

                return {...old, list: {...old.list, [+chartType]: updatedList}};
            });

        },
    });
};
