import {useCurrentAlertCondition, useSelectAlertIsShowAverageReading} from "../../../../stores/zustand/AlertConditionStore";

import {cloneDeep as _cloneDeep} from "lodash";
import {useEnvelopeAverage} from "../useEnvelopeAverage";

export const useEnvelopeAverageSeries = ({options}) => {
    const isShowAverageReading = useSelectAlertIsShowAverageReading();

    const alert = useCurrentAlertCondition();

    const {data: averageData, isLoading} = useEnvelopeAverage();

    if (!isShowAverageReading || !averageData) {
        return {options};
    }

    const avgSeries = {
        id: "aveReading",
        name: "aveReading",
        color: "#0000FF",
        data: +alert.fftEnvelopeCalcType === 2 ? _cloneDeep(averageData["max"]) : _cloneDeep(averageData["average"]),
        dataGrouping: {
            approximation: "high",
            forced: true,
        },
        zIndex: 0,
    };

    return {
        options: {...options, series: [...options.series, avgSeries]},
        isLoading,
    };
};
