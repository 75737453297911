import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import Helper from "../../../helpers/helper";
import {Link} from "react-router-dom";
import {get as _get} from "lodash";
import {withGlobalStore} from "../../../stores/GlobalStore";
import {EQUIPMENT_TYPE_NODE, SENSOR_TYPES} from "../../../constants/constants";
import CollapseLocationSelect from "../../../shared/collapseLocationSelect/collapseLocationSelect";
import SelectWrapper from "../../../helpers/select-wrapper";
import moment from "moment/moment";
import {usePacketLossChartModal} from "../hooks/usePacketLossChartModal";
import SimpleModal from "../../../modals/simple-modal";
import NodeBatteryVoltageChart from "../../../modals/nodeChart/batteryVoltageChart";
import SignalWarningTooltip from "../../../components/tooltips/SignalWarningTooltip";
import {FirmwareVersion} from "../../../shared/FirmwareVersion/FirmwareVersion";
import {SignalTooltip} from "../components/SignalTooltip/SignalTooltip";

const Mote = (props) => {
    const {mote, gateways, user, auth, onEdit = false, wifiGroups} = props;

    const [modalBatteryVoltage, setModalBatteryVoltage] = useState(false);

    const version = _get(mote, "sensors.0.version") || _get(mote, "code_version");

    const moteSensor = useMemo(() => {
        let sensor = _get(props.mote, "sensors.0", {}) || {};
        if (!Object.keys(sensor).length) {
            sensor = _get(props.mote, "ports.0.sensor", {}) || {};
        }
        return sensor;
    });

    const moteInstallationPoint = useMemo(() => {
        let installationPoint = _get(props.mote, "ports.0.installationPoint", {}) || {};

        if (!Object.keys(installationPoint).length && moteSensor) {
            installationPoint = _get(moteSensor, "installationPoint", {}) || {};
        }
        return installationPoint;
    });

    const [handlePacketLoss] = usePacketLossChartModal(mote.serial, EQUIPMENT_TYPE_NODE);

    let batteryColor = Helper.getNodeBatteryColor(mote.battery, "mote");
    let signalColor = mote.signal_strength;

    if (+moment().diff(moment(_get(mote, "last_seen")), "hours") > 24) {
        batteryColor = "secondary";
        signalColor = mote.signal_strength + "-gray";
    }

    let title = onEdit
        ? "Edit Sensor Mote"
        : +_get(mote, "ports.0.version_type", "") === SENSOR_TYPES.WIFI && auth.userCan("manageWiFiGroup")
        ? "WiFi Sensor Mote"
        : "Sensor Mote";
    let showWifiMoteBlock = auth.userCan("manageWiFiGroup") && +_get(mote, "sensors.0.version_type") === SENSOR_TYPES.WIFI;

    let timestampTitle = "";
    for (let i in mote.packetTimestamps) {
        if (timestampTitle !== "") {
            timestampTitle += "\n";
        }
        timestampTitle += `Date: ${Helper.dateToUserFormat(mote.packetTimestamps[i], user)}`;
    }

    return (
        <div>
            <div className="subheader">
                <div className="subheader-title">
                    <span>
                        {title} {parseInt(mote.serial).toString(16)}
                    </span>
                </div>
                <div className="subheader-controls"></div>
            </div>
            <div className="block">
                <div className="block-body">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="k-iconbox">
                                <div className="k-iconbox__icon">
                                    <img
                                        width="auto"
                                        height={127}
                                        src={_get(mote, ["photos", 0, "url"])}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <table className={"table border-0"}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong>Serial Number: </strong>
                                        </td>
                                        <td>{parseInt(mote.serial).toString(16).toUpperCase()}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Version: </strong>
                                        </td>
                                        <td>
                                            <FirmwareVersion
                                                version={version}
                                                isFirmwareExists={!!mote?.sensor?.firmwareExist}
                                                description={mote?.sensor?.firmwareDescription}
                                                shortDescription={mote?.sensor?.firmwareShortDescription}
                                                name={mote.name}
                                                type="Mote"
                                            />
                                        </td>
                                    </tr>
                                    {typeof props.onChange === "function" && +_get(mote, "sensors.0.version_type") !== SENSOR_TYPES.WIFI && (
                                        <tr>
                                            <td>
                                                <strong>Gateway: </strong>
                                            </td>
                                            <td>
                                                {auth.userCan("editNodes") ? (
                                                    <SelectWrapper
                                                        style={{
                                                            width: "100%",
                                                            display: "block",
                                                        }}
                                                        name="gateway_id"
                                                        value={+mote.gateway_id}
                                                        onChange={props.onChange}
                                                        placeholder={"Select Gateway"}
                                                    >
                                                        {gateways.map((gateway) => (
                                                            <option
                                                                key={gateway.gateway_id}
                                                                value={gateway.gateway_id}
                                                            >
                                                                {gateway.name ? gateway.name + " (" + gateway.serial + ")" : gateway.serial}
                                                            </option>
                                                        ))}
                                                    </SelectWrapper>
                                                ) : (
                                                    <React.Fragment>
                                                        {mote.gateway
                                                            ? mote.gateway.name
                                                                ? mote.gateway.name + " (" + mote.gateway.serial + ")"
                                                                : mote.gateway.serial
                                                            : "---"}
                                                    </React.Fragment>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                    {showWifiMoteBlock && (
                                        <tr>
                                            <td>
                                                <strong>Wifi Mote Group: </strong>
                                            </td>
                                            <td>
                                                {typeof props.onChange === "function" ? (
                                                    <SelectWrapper
                                                        style={{
                                                            width: "100%",
                                                            display: "block",
                                                        }}
                                                        name="wifi_config_group_id"
                                                        value={+_get(mote, "wifi_config_group_id")}
                                                        onChange={props.onChange}
                                                        placeholder={"Select Wifi Mote Group"}
                                                    >
                                                        {wifiGroups.map((group) => (
                                                            <option
                                                                key={group.id}
                                                                value={group.id}
                                                            >
                                                                {_get(group, "name")}
                                                            </option>
                                                        ))}
                                                    </SelectWrapper>
                                                ) : (
                                                    <React.Fragment>{_get(mote, "wifi_config_group_name", "---")}</React.Fragment>
                                                )}
                                            </td>
                                        </tr>
                                    )}

                                    <tr>
                                        <td>
                                            <strong>Location: </strong>
                                        </td>
                                        <td>
                                            {typeof props.onChange === "function" ? (
                                                <React.Fragment>
                                                    <input
                                                        type="text"
                                                        value={mote.location || ""}
                                                        name="location"
                                                        onChange={props.onChange}
                                                        className="form-control"
                                                        readOnly={!auth.userCan("editNodes")}
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                mote.location || "---"
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Asset Tree Branch: </strong>
                                        </td>
                                        <td>
                                            {typeof props.onChange === "function" ? (
                                                <React.Fragment>
                                                    <CollapseLocationSelect
                                                        className={"form-control"}
                                                        selectName={"location_id"}
                                                        value={+_get(mote, "location_id", null)}
                                                        onChange={props.onChange}
                                                        emptyOptionLabel={"Select Location"}
                                                        allowedLocationIds={_get(user, "allowedLocationIds", [])}
                                                        disabled={!auth.userCan("editNodes")}
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                _get(mote, "location_name") || "---"
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Last Seen: </strong>
                                        </td>
                                        <td>{mote.last_seen}</td>
                                    </tr>
                                    {!!mote.signal_strength && (
                                        <tr>
                                            <td>
                                                <strong>Signal: </strong>
                                            </td>
                                            <td>
                                                <span
                                                    title={
                                                        Helper.getSignalLabel(mote.signal_strength) +
                                                        (mote.lastSeen ? `\nFFT: ${Helper.dateToUserFormat(mote.lastSeen, user)}` : "")
                                                    }
                                                    className={`signal-lvl position-relative color-${
                                                        mote.signal_strength === "default" ? "" : signalColor
                                                    }`}
                                                    style={{marginLeft: 0}}
                                                >
                                                    {!!(signalColor === mote.signal_strength + "-gray" && mote.signal_strength !== "default") && (
                                                        <img
                                                            title={"Sensor has not been seen in over 24 hours"}
                                                            className={"yellow-time-icon"}
                                                            src={"/assets/pic/icon-time.svg"}
                                                        />
                                                    )}
                                                    {mote.signal_strength === "default" ? (
                                                        "N/A"
                                                    ) : (
                                                        <img src={`/assets/pic/icon-signal-level-${signalColor}.svg`} />
                                                    )}
                                                </span>
                                                <SignalTooltip
                                                    node={mote}
                                                    variant="mote"
                                                />
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td>
                                            <strong>Battery: </strong>
                                        </td>
                                        <td>
                                            <span
                                                className={`position-relative color-${batteryColor}`}
                                                style={{marginLeft: 0}}
                                            >
                                                {batteryColor === "secondary" && (
                                                    <img
                                                        title={"Sensor has not been seen in over 24 hours"}
                                                        className={"yellow-time-icon"}
                                                        src={"/assets/pic/icon-time.svg"}
                                                    />
                                                )}
                                                {batteryColor === "" ? (
                                                    "N/A"
                                                ) : batteryColor === "warning" ? (
                                                    <i className="fa fa-battery-half" />
                                                ) : (
                                                    <i className="fa fa-battery-full" />
                                                )}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Installed On:</strong>
                                        </td>
                                        <td>
                                            {moteInstallationPoint ? (
                                                <React.Fragment>
                                                    {moteInstallationPoint.name}
                                                    {!!moteInstallationPoint.id && (
                                                        <Link
                                                            to={`/chart/${moteInstallationPoint.equipment_id}#sensors=${moteInstallationPoint.id}`}
                                                            className="btn btn-success btn-elevate btn-circle btn-icon pull-right"
                                                            style={{marginRight: 10}}
                                                            title={"View Sensor"}
                                                        >
                                                            <i className="flaticon2-chart" />
                                                        </Link>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <i>Not set</i>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Equipment:</strong>
                                        </td>
                                        <td>
                                            {moteInstallationPoint?.id ? (
                                                <React.Fragment>
                                                    {moteInstallationPoint.equipmentName}
                                                    {!!moteInstallationPoint.equipment_id && (
                                                        <Link
                                                            to={`/chart/${moteInstallationPoint.equipment_id}#sensors=${moteInstallationPoint.id}`}
                                                            className="btn btn-success btn-elevate btn-circle btn-icon pull-right"
                                                            style={{marginRight: 10}}
                                                            title={"View Sensor"}
                                                        >
                                                            <i className="flaticon2-chart" />
                                                        </Link>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <i>Not set</i>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Packet Loss Chart:</strong>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-success btn-elevate btn-circle btn-icon"
                                                title={"Show Packet Loss Chart"}
                                                onClick={handlePacketLoss}
                                            >
                                                <i className="flaticon2-chart" />
                                            </button>
                                            <SignalWarningTooltip
                                                packetLossPercent={_get(mote, "packetLossPercent")}
                                                tooltipText={"FFT transmission may be impacted by poor connectivity to the device."}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Battery Voltage Chart:</strong>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-success btn-elevate btn-circle btn-icon"
                                                title={"Show Battery Voltage Chart"}
                                                onClick={() => {
                                                    setModalBatteryVoltage(true);
                                                }}
                                            >
                                                <i className="flaticon2-chart" />
                                            </button>
                                            <SimpleModal
                                                showModal={modalBatteryVoltage}
                                                title={"Battery Voltage Chart"}
                                                withoutSubmit={true}
                                                onClose={() => {
                                                    setModalBatteryVoltage(false);
                                                }}
                                                size={"lg"}
                                            >
                                                <NodeBatteryVoltageChart node={mote} />
                                            </SimpleModal>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Mote.propTypes = {
    user: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    mote: PropTypes.object,
    gateways: PropTypes.array,
    onChange: PropTypes.func,
    onShowBatteryVoltageModal: PropTypes.func,
    auth: PropTypes.object,
    onEdit: PropTypes.bool,
    wifiGroups: PropTypes.array,
};

export default withGlobalStore(Mote);
