import {useTachometerQuery} from "../../../../hooks/api/tachometer/useTachometerQuery";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import {forEach as _forEach, get as _get} from "lodash";
import {DATEFORMAT} from "../../../../constants/constants";
import useUserProfile from "../../../../hooks/api/Global/useUserProfile";
import {usePointsData} from "../../../../pages/chart/highcharts/hooks/usePointsData";
import {useMemo} from "react";

const TACH_SERIES_COLOR = "#999999";

export const useTachometerReadingOptions = ({options, showOverMax = false, enabled}) => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {data: user} = useUserProfile();

    const {tachReadings} = useTachometerQuery(equipment, enabled);

    const pointsData = usePointsData();
    const maxRpm = useMemo(() => _get(equipment, ["tachometer", "nodeTriggerSetting", "max_rpm"], null), [equipment]);
    const readingOverMax = (reading) => {
        return maxRpm !== null && reading[1] !== null && reading[1] > maxRpm;
    };

    const series = useMemo(() => {
        if (!tachReadings.length) {
            return [];
        }

        let readings = tachReadings;
        let zones = [];

        if (!showOverMax) {
            readings = tachReadings.filter((reading) => !readingOverMax(reading));
        } else if (maxRpm !== null) {
            zones = [
                {
                    value: maxRpm,
                    color: TACH_SERIES_COLOR,
                },
                {
                    color: "#ff0000",
                },
            ];
            // TODO: Test it after refactoring
            // after toggling full-screen, readings is rewritten inside ChartsStore by highchart series sync
            // https://waites.atlassian.net/browse/SSW-195
            readings = [...tachReadings];
        }

        let seriesData = [
            {
                name: "Tachometer RPM",
                data: readings,
                id: "tachSeria",
                datetimeFormat: _get(user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0]),
                units: "RPM",
                color: TACH_SERIES_COLOR,
                precision: 2,
                yAxis: 0,
                dataGrouping: {
                    approximation: "high",
                    forced: true,
                },
                zones,
            },
        ];

        pointsData.map((pointData) => {
            let pointRpm = [];
            let speedRatio = _get(pointData, "speed_ratio", 1);

            if (speedRatio === null || speedRatio === 0) {
                speedRatio = 1;
            }

            _forEach(readings, (reading) => {
                if (readingOverMax(reading)) {
                    return;
                }
                pointRpm.push([reading[0], reading[1] === null ? null : reading[1] * speedRatio]);
            });

            seriesData.push({
                name: pointData.name || "",
                data: pointRpm,
                installationPointId: pointData.id,
                datetimeFormat: _get(user, "personal_settings.datetime_format", Object.keys(DATEFORMAT)[0]),
                units: "RPM",
                ratioValue: speedRatio,
                color: pointData.color || "#000000",
                precision: 2,
                dataGrouping: {
                    approximation: "high",
                    forced: true,
                },
            });
        });

        return seriesData;
    }, [tachReadings, pointsData, showOverMax, maxRpm]);

    return {options: {...options, series: [...options.series, ...series]}};
};
