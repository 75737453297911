import {ALERT_LEVEL_NAMES} from "../../modals/alert/constants/alertLevelNames";
import {CONDITIONS_MAP} from "./getAlertConditions";

export const getMappedPercentageValues = (alerts) =>
    alerts.reduce(
        (value, item) => {
            if (item.alertLevel.name !== ALERT_LEVEL_NAMES.CAUTION) {
                return value;
            }
            const key = CONDITIONS_MAP[item.condition];

            value[key] = item.value;

            return value;
        },
        {Over: null, Lower: null}
    );
