import {useState} from "react";
import {cloneDeep as _cloneDeep} from "lodash";
// eslint-disable-next-line no-unused-vars
import ChartHelper from "../../../../helpers/chart";

const isDarkTheme = false;

const initialOptions = {
    chart: {
        animation: false,
        zoomType: false,
        wrapZoomType: false,
        backgroundColor:
            isDarkTheme === "true"
                ? {
                      linearGradient: {x1: 0, y1: 0, x2: 1, y2: 1},
                      stops: [
                          [0, "#3a3934"],
                          [1, "#36342a"],
                      ],
                  }
                : "#FFFFFF",
        height: 450,
    },
    credits: {
        enabled: false,
    },
    plotOptions: {
        line: {
            lineWidth: 1,
            states: {
                hover: {
                    lineWidth: 1,
                },
            },
        },
        series: {
            animation: false,
            connectNulls: false,
        },
    },
    title: {
        text: "",
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        type: "datetime",
        labels: {formatter: ChartHelper.formatXAxisFFT},
    },
    yAxis: {
        opposite: false,
        title: {
            align: "middle",
            text: "",
        },
        ...(isDarkTheme === "true" ? {gridLineColor: "rgba(200,200,200,0.4)", gridLineWidth: 0.5} : {}),
    },
    rangeSelector: {
        enabled: false,
    },
    tooltip: {
        enabled: false,
        ...(isDarkTheme === "true" ? {backgroundColor: "rgba(54, 54, 50, 0.9)", style: {color: "#ddd"}} : {}),
    },
    series: [],
    navigation: {
        buttonOptions: {
            enabled: false,
        },
    },
};

export const useFftChartOptions = () => {
    const [chartConfig] = useState(() => _cloneDeep(initialOptions));
    return chartConfig;
};
