import React from "react";
import PropTypes from "prop-types";
import {useSelectedTab} from "../../../stores/zustand/AlertConditionStore";
import {FFT_TABS} from "../constants/fftTabs";

const AlertTabs = ({tabConfig, onTabChange, value}) => {
    const currentTab = useSelectedTab();

    return (
        <div className="d-flex form-group align-items-center">
            {FFT_TABS[currentTab] && <label className="col-4 mb-0">FFT Alert Type:</label>}
            <div className="axis-block-container flex-auto">
                {Object.entries(tabConfig).map(([key, item]) => (
                    <div
                        key={item.key}
                        className={"axis-block axis-block-manage " + (key === value ? "active" : "")}
                        onClick={() => onTabChange(key)}
                    >
                        <span>{item.title}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
AlertTabs.propTypes = {
    tabConfig: PropTypes.object,
    onTabChange: PropTypes.func,
    value: PropTypes.string,
};
export default AlertTabs;
