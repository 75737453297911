import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {get as _get, filter as _filter} from "lodash";
import Highlighter from "react-highlight-words";
import Loader from "../../../../shared/loader/loader";
import InfiniteScroll from "react-infinite-scroller";
import {Button} from "reactstrap";
import {TableRow} from "./TableRow/TableRow";

export class DefaultLevelsTable extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showDesktop: false,
            showDesktopParent: false,
            equipmentIdToggle: null,
        };
    }

    toggleEquipment = () => {
        this.setState({
            showDesktop: !this.state.showDesktop,
        });
    };

    toggleEquipmentParent = (equipmentId) => {
        if (this.state.equipmentIdToggle === equipmentId) {
            this.setState({
                equipmentIdToggle: null,
            });
        } else {
            this.setState({
                equipmentIdToggle: equipmentId,
            });
        }
    };

    render() {
        const {list, units, handleSetValueModal, search, loader, axisLabels, onNextPage, hasMore, auth, location} = this.props;
        const {showDesktop, equipmentIdToggle, showDesktopParent} = this.state;

        return (
            <div className="wrapper-default-table">
                <table className="table table-bordered sticky-wrapper default-levels-table">
                    {search.length === 0 && (
                        <thead className="sticky-table-header">
                            <tr className="adaptive-row-table-all-edit">
                                <th className="bg-gray-block font-weight-bold">
                                    {_get(location, "name", "ALL EQUIPMENT")}{" "}
                                    <Button
                                        onClick={this.toggleEquipment}
                                        className="btn-toogle-desktop"
                                        style={{padding: "6px 6px", position: "absolute", top: "3px", right: "17px"}}
                                        size="xs"
                                    >
                                        <img
                                            src="/assets/pic/icon-dropdown.svg"
                                            alt=""
                                        />
                                    </Button>
                                </th>
                                <th className={showDesktop ? "bg-gray-block" : "bg-gray-block hide-mobile"}>
                                    Temperature, {_get(units, "temp", "N/A")}
                                    {!!list.length && (
                                        <div className="action-icon-tbl">
                                            {auth.userCan("editAlarms") && (
                                                <button
                                                    className="btn btn-icon btn-sm btn-primary"
                                                    onClick={() =>
                                                        handleSetValueModal({
                                                            show: true,
                                                            equipment: {},
                                                            installationPoint: {},
                                                            readingTypes: {
                                                                chartType: 1,
                                                                name: "Temperature",
                                                                alias: "temp",
                                                                list: ["1"],
                                                                units: _get(units, "temp", "N/A"),
                                                            },
                                                        })
                                                    }
                                                >
                                                    <i className="fa fa-pencil-alt" />
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </th>
                                <th className={showDesktop ? "bg-gray-block" : "bg-gray-block hide-mobile"}>
                                    Acceleration, {_get(units, "acc", "N/A")}
                                    {!!list.length && (
                                        <div className="action-icon-tbl">
                                            {auth.userCan("editAlarms") && (
                                                <button
                                                    className="btn btn-icon btn-sm btn-primary"
                                                    onClick={() =>
                                                        handleSetValueModal({
                                                            show: true,
                                                            equipment: {},
                                                            installationPoint: {},
                                                            readingTypes: {
                                                                chartType: 2,
                                                                alias: "acc",
                                                                name: "Acceleration",
                                                                list: ["3", "6", "22"],
                                                                units: _get(units, "acc", "N/A"),
                                                            },
                                                        })
                                                    }
                                                >
                                                    <i className="fa fa-pencil-alt" />
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </th>
                                <th className={showDesktop ? "bg-gray-block" : "bg-gray-block hide-mobile"}>
                                    Velocity, {_get(units, "vel", "N/A")}
                                    {!!list.length && (
                                        <div className="action-icon-tbl">
                                            {auth.userCan("editAlarms") && (
                                                <button
                                                    className="btn btn-icon btn-sm btn-primary"
                                                    onClick={() =>
                                                        handleSetValueModal({
                                                            show: true,
                                                            equipment: {},
                                                            installationPoint: {},
                                                            readingTypes: {
                                                                chartType: 3,
                                                                name: "Velocity",
                                                                alias: "vel",
                                                                list: ["4", "7", "23"],
                                                                units: _get(units, "vel", "N/A"),
                                                            },
                                                        })
                                                    }
                                                >
                                                    <i className="fa fa-pencil-alt" />
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </th>
                                <th className={showDesktop ? "bg-gray-block" : "bg-gray-block hide-mobile"}>
                                    PK-PK, {_get(units, "pkpk", "N/A")}
                                    {!!list.length && (
                                        <div className="action-icon-tbl">
                                            {auth.userCan("editAlarms") && (
                                                <button
                                                    className="btn btn-icon btn-sm btn-primary"
                                                    onClick={() =>
                                                        handleSetValueModal({
                                                            show: true,
                                                            equipment: {},
                                                            installationPoint: {},
                                                            // TODO: investigate pk-pk chart type. I'm not sure that the right value is 4
                                                            readingTypes: {
                                                                chartType: 5,
                                                                name: "PK-PK",
                                                                alias: "pkpk",
                                                                list: ["16", "18", "27"],
                                                                units: _get(units, "pkpk", "N/A"),
                                                            },
                                                        })
                                                    }
                                                >
                                                    <i className="fa fa-pencil-alt" />
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </th>
                            </tr>
                        </thead>
                    )}
                    <InfiniteScroll
                        loadMore={() => {
                            if (list.length > 0) {
                                onNextPage();
                            }
                        }}
                        hasMore={!!hasMore}
                        loader={
                            list.length > 0 ? (
                                <tr key="loader">
                                    <td
                                        colSpan="5"
                                        className="text-center"
                                    >
                                        <Loader />
                                    </td>
                                </tr>
                            ) : (
                                ""
                            )
                        }
                        element="tbody"
                    >
                        {list.length > 0 ? (
                            list.map((equipment) => {
                                const showAll =
                                    equipment.showAll === false &&
                                    equipment.installationPoints.length !==
                                        _filter(equipment.installationPoints, (installationPoint) => installationPoint.foundBySearch).length &&
                                    search.trim().length > 1;
                                return (
                                    <React.Fragment key={equipment.id}>
                                        <tr className="bg-gray-block adaptive-row-equipment">
                                            <td className="font-weight-bold">
                                                <Highlighter
                                                    searchWords={[search.trim()]}
                                                    autoEscape={true}
                                                    textToHighlight={equipment.name}
                                                />
                                                <Button
                                                    onClick={() => this.toggleEquipmentParent(equipment.id)}
                                                    className={
                                                        equipment.id === equipmentIdToggle && showDesktopParent
                                                            ? "btn-toogle-desktop rotateDeg"
                                                            : " btn-toogle-desktop"
                                                    }
                                                    style={{padding: "6px 6px", position: "absolute", top: "4px", right: "17px"}}
                                                    size="xs"
                                                >
                                                    <img
                                                        src="/assets/pic/icon-dropdown.svg"
                                                        alt=""
                                                    />
                                                </Button>
                                            </td>
                                            <td className={equipment.id === equipmentIdToggle ? "pr-30" : "pr-30 hide-mobile"}>
                                                Temperature, {_get(units, "temp", "N/A")}
                                                {!!equipment.installationPoints.length && (
                                                    <div className="action-icon-tbl">
                                                        {auth.userCan("editAlarms") && (
                                                            <button
                                                                className="btn btn-icon btn-sm btn-primary"
                                                                disabled={showAll}
                                                                onClick={() =>
                                                                    handleSetValueModal({
                                                                        show: true,
                                                                        equipment: {
                                                                            id: equipment.id,
                                                                            name: equipment.name,
                                                                            asset_code: equipment.asset_code,
                                                                        },
                                                                        installationPoint: {},
                                                                        readingTypes: {
                                                                            chartType: 1,
                                                                            name: "Temperature",
                                                                            list: ["1"],
                                                                            alias: "temp",
                                                                            units: _get(units, "temp", "N/A"),
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                <i className="fa fa-pencil-alt" />
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                            <td className={equipment.id === equipmentIdToggle ? "pr-30" : "pr-30 hide-mobile"}>
                                                Acceleration, {_get(units, "acc", "N/A")}
                                                {!!equipment.installationPoints.length && (
                                                    <div className="action-icon-tbl">
                                                        {auth.userCan("editAlarms") && (
                                                            <button
                                                                className="btn btn-icon btn-sm btn-primary"
                                                                disabled={showAll}
                                                                onClick={() =>
                                                                    handleSetValueModal({
                                                                        show: true,
                                                                        equipment: {
                                                                            id: equipment.id,
                                                                            name: equipment.name,
                                                                            asset_code: equipment.asset_code,
                                                                        },
                                                                        installationPoint: {},
                                                                        readingTypes: {
                                                                            chartType: 2,
                                                                            name: "Acceleration",
                                                                            alias: "acc",
                                                                            list: ["3", "6", "22"],
                                                                            units: _get(units, "acc", "N/A"),
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                <i className="fa fa-pencil-alt" />
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                            <td className={equipment.id === equipmentIdToggle ? "pr-30" : "pr-30 hide-mobile"}>
                                                Velocity, {_get(units, "vel", "N/A")}
                                                {!!equipment.installationPoints.length && (
                                                    <div className="action-icon-tbl">
                                                        {auth.userCan("editAlarms") && (
                                                            <button
                                                                className="btn btn-icon btn-sm btn-primary"
                                                                disabled={showAll}
                                                                onClick={() =>
                                                                    handleSetValueModal({
                                                                        show: true,
                                                                        equipment: {
                                                                            id: equipment.id,
                                                                            name: equipment.name,
                                                                            asset_code: equipment.asset_code,
                                                                        },
                                                                        installationPoint: {},
                                                                        readingTypes: {
                                                                            chartType: 3,
                                                                            name: "Velocity",
                                                                            alias: "vel",
                                                                            list: ["4", "7", "23"],
                                                                            units: _get(units, "vel", "N/A"),
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                <i className="fa fa-pencil-alt" />
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                            <td className={equipment.id === equipmentIdToggle ? "pr-30" : "pr-30 hide-mobile"}>
                                                PK-PK, {_get(units, "pkpk", "N/A")}
                                                {!!equipment.installationPoints.length && (
                                                    <div className="action-icon-tbl">
                                                        {auth.userCan("editAlarms") && (
                                                            <button
                                                                className="btn btn-icon btn-sm btn-primary"
                                                                disabled={showAll}
                                                                onClick={() =>
                                                                    handleSetValueModal({
                                                                        show: true,
                                                                        equipment: {
                                                                            id: equipment.id,
                                                                            name: equipment.name,
                                                                            asset_code: equipment.asset_code,
                                                                        },
                                                                        installationPoint: {},
                                                                        readingTypes: {
                                                                            chartType: 5,
                                                                            name: "PK-PK",
                                                                            alias: "pkpk",
                                                                            list: ["16", "18", "27"],
                                                                            units: _get(units, "pkpk", "N/A"),
                                                                        },
                                                                    })
                                                                }
                                                            >
                                                                <i className="fa fa-pencil-alt" />
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                        {equipment.installationPoints.length > 0 ? (
                                            <React.Fragment>
                                                {equipment.installationPoints.map((installationPoint) => (
                                                    <TableRow
                                                        key={installationPoint.id}
                                                        installationPoint={installationPoint}
                                                        units={units}
                                                        search={search}
                                                        equipment={equipment}
                                                        handleSetValueModal={handleSetValueModal}
                                                        axisLabels={axisLabels}
                                                        auth={auth}
                                                    />
                                                ))}
                                                {showAll === true && (
                                                    <tr>
                                                        <td
                                                            colSpan={5}
                                                            className="text-center"
                                                        >
                                                            <button
                                                                className="link link-primary"
                                                                onClick={() => this.props.showAllSearchResults(equipment)}
                                                            >
                                                                <b>Show All</b>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    className="text-center"
                                                >
                                                    Installation points not set for this Equipment
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })
                        ) : (
                            <React.Fragment>
                                {loader === false && (
                                    <tr>
                                        <td
                                            colSpan={5}
                                            className="text-center"
                                        >
                                            Default Alert Levels Not Found
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        )}
                    </InfiniteScroll>
                </table>
            </div>
        );
    }
}

DefaultLevelsTable.propTypes = {
    list: PropTypes.array,
    search: PropTypes.string,
    units: PropTypes.object,
    location: PropTypes.object,
    loader: PropTypes.bool,
    handleSetValueModal: PropTypes.func,
    showAllSearchResults: PropTypes.func,
    onNextPage: PropTypes.func,
    hasMore: PropTypes.bool,
    axisLabels: PropTypes.object,
    showDesktop: PropTypes.bool,
    auth: PropTypes.object,
};
