import {useCurrentAlertCondition} from "../../../stores/zustand/AlertConditionStore";
import Helper from "../../../helpers/helper";
import {useChartFftAverageDataQuery} from "../../../hooks/api/fftReadings/useChartFftAverageDataQuery";

export const useEnvelopeAverage = () => {
    const currentAlert = useCurrentAlertCondition();
    const {installationPointId} = Helper.getHashParams();

    const optionsForQuery = {
        axisId: currentAlert.axisId,
        chartType: currentAlert.chartType,
        fftEnvelopeCalcType: currentAlert.fftEnvelopeCalcType,
        followingType: currentAlert.followingType,
        ...(+currentAlert.followingType === 2 ? {avgLastRCount: currentAlert.avgLastRCount} : {}),
        ...(+currentAlert.followingType === 3 ? {avgLastDCount: currentAlert.avgLastDCount} : {}),
        ...(+currentAlert.followingType === 4 ? {avgDateFrom: currentAlert.avgDateFrom, avgDateTo: currentAlert.avgDateTo} : {}),
    };

    return useChartFftAverageDataQuery({
        installationPointId: installationPointId,
        followingType: currentAlert.followingType,
        options: optionsForQuery,
    });
};
