import React, {useRef} from "react";
import {useFftChartOptions} from "../../hooks/charts/useFftChartOptions";
import {useFftReadingOptions} from "../../hooks/charts/useFftReadingOptions";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {Loader} from "../../../../shared";
import {useBandNavigation} from "../../hooks/charts/useBandNavigation";
import {useAlertOptions} from "../../hooks/charts/useAlertOptions";
import {useBandAxisCut} from "../../hooks/charts/useBandAxisCut";
import {useBandFrequencyConverter} from "../../hooks/charts/useBandFrequencyConverter";

export const ChartBand = () => {
    const chartRef = useRef(null);
    const chartBandNavigationRef = useRef(null);

    const chartDefaultOptions = useFftChartOptions();
    const {options: optionsWithReadings, readingsLoaded} = useFftReadingOptions({options: chartDefaultOptions});
    const {options: optionsWithFrequency} = useBandFrequencyConverter({options: optionsWithReadings});
    const {options: optionsWithAlerts} = useAlertOptions({options: optionsWithFrequency, chartRef});

    const {options: optionsWithCut} = useBandAxisCut({options: optionsWithAlerts});

    const {options: optionsWithBand} = useBandNavigation({options: optionsWithFrequency, chartRef: chartBandNavigationRef});

    if (!readingsLoaded) {
        return <Loader />;
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={"chart"}
                options={optionsWithCut}
                updateArgs={[true, true, true]}
                ref={chartRef}
            />
            <HighchartsReact
                highcharts={Highcharts}
                constructorType="chart"
                ref={chartBandNavigationRef}
                updateArgs={[true, true, false]}
                options={optionsWithBand}
            />
        </>
    );
};
