import React from "react";
import AlertSensorSelect from "../../shared/alertSensorSelect";
import PropTypes from "prop-types";
import {useChartType} from "../../hooks/helpers/chart";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import {AlertList} from "../AlertList/AlertList";

const TabLinear = ({chartTypes}) => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {chartType} = useChartType(chartTypes);

    return (
        <>
            <AlertSensorSelect
                chartType={chartType}
                equipment={equipment}
            />
            <AlertList variant="simple" />
        </>
    );
};
TabLinear.propTypes = {
    chartTypes: PropTypes.array,
    equipment: PropTypes.object,
    axisLabels: PropTypes.object,
};
export default TabLinear;
