import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import SelectWrapper from "../../../helpers/select-wrapper";
import RmsOdTemplate from "../../../modals/alert/parts/rms-od-template";
import EmailTemplate from "../../../modals/alert/parts/email-template";
import AlertActions from "../../../modals/alert/components/AlertActions/AlertActions";
import {AlertLabel} from "../AlertLabel/AlertLabel";
import {get as _get} from "lodash";
import Helper from "../../../helpers/helper";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {DefaultAlertActions} from "../DefaultAlertActions/DefaultAlertActions";
import TachometerRpmTemplate from "../../../modals/alert/parts/tachometer-rpm-template";

const validErrorMsg = null;

const actionTypeMap = {
    singularAlert: AlertActions,
    alertDefaults: DefaultAlertActions,
};

const TachometerAlertTemplate = ({
    alert,
    variant,
    onChange,
    isCurrent,
    originalAlert,
    onSelect,
    withoutEmailTemplate = false,
    actionsType = "singularAlert",
    percentageValue,
    units,
    onDelete,
    withoutOnDemand,
    onDemandSettings,
    alertType = "linear",
    index,
    error,
    onInputChange,
    onDeleteBlankImage,
}) => {
    const [percentage, setPercentage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const {type: chartType = "1"} = Helper.getHashParams();
    const chartTypes = useChartTypesStore();

    const alias = _get(chartTypes, [chartType, "alias"]) || "";

    useEffect(() => {
        if (!percentageValue || !isCurrent) {
            return;
        }

        calcPercentage();
    }, [alert.value, percentageValue]);

    const update = (values) => {
        onChange({...alert, ...values});
    };

    const calcPercentage = (value, commit) => {
        if (!percentageValue) {
            return;
        }
        const newPercentage =
            value || (percentageValue ? Math.round((-(percentageValue - alert.value) / (percentageValue / 100)) * 100) / 100 : 0).toString();
        if (commit) update({value: (Math.round((percentageValue / 100) * (100 + +newPercentage) * 10000) / 10000).toString()});
        setPercentage(newPercentage);
    };

    const ActionRenderer = actionTypeMap[actionsType];

    return (
        <div
            className={`form-group mb-3 alert-level-block-big alert-level-block${isCurrent ? " current" : ""}`}
            onClick={() => {
                if (isCurrent) {
                    return;
                }
                onSelect();
            }}
        >
            <div className="row flex-xl-nowrap justify-content-end align-items-start">
                <div className="mr-auto">
                    <AlertLabel variant={variant} />
                </div>
                <div className={variant === "caution" ? "col-12 col-md-6 col-xl-2 mb-3 mb-md-0" : "col-12 col-md-3 col-xl-2 mb-3 mb-md-0"}>
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.condition || ""}
                        onChange={(ev) => onChange({...alert, condition: ev.target.value})}
                        disabled={!isCurrent}
                        name={"level"}
                    >
                        {Object.keys(alert.availableConditions || {}).map((key) => (
                            <option
                                key={key}
                                value={key}
                            >
                                {key}
                            </option>
                        ))}
                    </SelectWrapper>
                </div>
                {variant !== "caution" && alert.axisId > 0 && (
                    <div className={"col-12 col-md-4 col-xl-3 mb-3 mb-md-0"}>
                        <div className="input-group">
                            <input
                                className="form-control request-form-control"
                                type="number"
                                step={1}
                                id={`${variant}-${index}-percentage-input`}
                                value={percentageValue ? percentage || "" : null}
                                title="A percentage of caution level"
                                onChange={(ev) => {
                                    calcPercentage(ev.target.value, true);
                                    onInputChange?.();
                                }}
                                disabled={!isCurrent || !percentageValue}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">%</span>
                            </div>
                        </div>
                    </div>
                )}
                <div className={variant === "caution" ? "col-12 col-md-6 col-xl-5" : "col-12 col-md-5 col-xl-5"}>
                    <div className={"input-group input-group-sm"}>
                        <input
                            className={`form-control request-form-control${isCurrent ? " current-alert-value" : ""} ${
                                (errorMessage?.input && isCurrent) || error ? "is-invalid" : ""
                            }`}
                            type="number"
                            value={alert.value.toString() || ""}
                            step={0.1}
                            id={`${variant}-${index}-input`}
                            min={0}
                            onChange={(ev) => {
                                onChange({...alert, value: ev.target.value});
                                onInputChange?.();
                            }}
                            disabled={!isCurrent}
                        />
                        {!!units && (
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">{alertType === "delta-t" ? "" + units : units}</span>
                            </div>
                        )}
                    </div>

                    {((errorMessage?.input && isCurrent) || error) && (
                        <span
                            className="error-msg d-block color-danger mt-1 text-right"
                            style={{marginLeft: errorMessage?.input || error ? -90 : 0}}
                        >
                            {error || errorMessage?.input}
                        </span>
                    )}
                </div>
            </div>
            <div className="row mt-2 align-items-start">
                <div className="col-12 col-md-5 mb-1 mb-md-0">
                    <span className="trigger-type label-horizontal-input d-block mt-2">Trigger Type</span>
                </div>
                <div className="col-12 col-md-7">
                    <SelectWrapper
                        style={{
                            width: "100%",
                            display: "block",
                        }}
                        value={alert.notificationType || ""}
                        onChange={(ev) => onChange({...alert, notificationType: +ev.target.value})}
                        disabled={!isCurrent}
                        name={"notification-type"}
                    >
                        <option value={1}>Alert only when reading exceeds the last active alert</option>
                        <option value={2}>Alert for every reading</option>
                    </SelectWrapper>
                </div>
            </div>
            <div className="row mt-2 align-items-start">
                <div className="col-md-5">
                    <span>
                        Consecutive Readings Required <br /> Before Trigger
                    </span>
                </div>
                <div className="col-12 col-md-7">
                    <input
                        id={`${variant}-${index}-consecutive-readings-input`}
                        className={`form-control request-form-control ${
                            alert.requireReadings === "" && validErrorMsg && isCurrent
                                ? "is-invalid"
                                : alert.requireReadings !== "" &&
                                  (alert.requireReadings < 1 || +alert.requireReadings !== parseInt("" + alert.requireReadings)) &&
                                  validErrorMsg &&
                                  isCurrent
                                ? "is-invalid"
                                : ""
                        }`}
                        value={alert.requireReadings || alert.requireReadings === 0 ? alert.requireReadings : ""}
                        onChange={(ev) =>
                            onChange({
                                ...alert,
                                requireReadings: ev.target.value === "" ? "" : +ev.target.value,
                                require_readings: ev.target.value === "" ? "" : +ev.target.value,
                            })
                        }
                        disabled={!isCurrent}
                        type={"number"}
                        min={1}
                    />
                    {alert.requireReadings === "" && validErrorMsg && isCurrent && (
                        <span className="error-msg d-block color-danger mt-1 text-right">This field is required.</span>
                    )}
                    {alert.requireReadings !== "" &&
                        (alert.requireReadings < 1 || +alert.requireReadings !== parseInt("" + alert.requireReadings)) &&
                        validErrorMsg &&
                        isCurrent && (
                            <span className="error-msg d-block color-danger mt-1 text-right">
                                This field only accepts integers and cannot be less than 1.
                            </span>
                        )}
                </div>
            </div>
            {alertType === "delta-t" && (
                <div className="row mt-2 align-items-start">
                    <div className="col-md-5">
                        <span className="d-block mt-2">Average Deviation Period</span>
                    </div>
                    <div className="col-12 col-md-7">
                        <div className={"input-group input-group-sm"}>
                            <input
                                id={`${variant}-${index}-average-deviation-input`}
                                className={`form-control request-form-control ${
                                    alert.groupInterval === "" && validErrorMsg && isCurrent
                                        ? "is-invalid"
                                        : alert.groupInterval !== "" &&
                                          (alert.groupInterval < 1 || +alert.groupInterval !== parseInt("" + alert.groupInterval)) &&
                                          validErrorMsg &&
                                          isCurrent
                                        ? "is-invalid"
                                        : ""
                                }`}
                                value={alert.groupInterval ? alert.groupInterval : ""}
                                onChange={(ev) => update({groupInterval: ev.target.value})}
                                disabled={!isCurrent}
                                type={"number"}
                                min={1}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text request-form-control">hours</span>
                            </div>
                        </div>
                        {alert.groupInterval === "" && validErrorMsg && isCurrent && (
                            <span className="error-msg d-block color-danger mt-1 text-right">This field is required and cannot be less than 1.</span>
                        )}
                        {alert.groupInterval !== "" &&
                            (alert.groupInterval < 1 || +alert.groupInterval !== parseInt("" + alert.groupInterval)) &&
                            validErrorMsg &&
                            isCurrent && (
                                <span className="error-msg d-block color-danger mt-1 text-right">
                                    This field only accepts integers and cannot be less than 1.
                                </span>
                            )}
                    </div>
                </div>
            )}
            <TachometerRpmTemplate
                alert={alert}
                isCurrent={isCurrent}
                update={update}
                validErrorMsg={errorMessage}
            />
            {alertType !== "delta-t" && !withoutOnDemand && (
                <RmsOdTemplate
                    alert={alert}
                    alias={alias}
                    isCurrent={isCurrent}
                    update={update}
                    onDemandSettings={onDemandSettings}
                />
            )}
            {!withoutEmailTemplate && (
                <EmailTemplate
                    alert={alert}
                    update={update}
                />
            )}
            <div>
                <ActionRenderer
                    isCurrent={isCurrent}
                    alert={alert}
                    originalAlert={originalAlert}
                    onDelete={onDelete}
                    setErrorMessage={setErrorMessage}
                    onDeleteBlankImage={onDeleteBlankImage}
                />
            </div>
        </div>
    );
};

TachometerAlertTemplate.propTypes = {
    alert: PropTypes.object,
    originalAlert: PropTypes.object,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    onDeleteBlankImage: PropTypes.func,
    onSelect: PropTypes.func,
    isCurrent: PropTypes.bool,
    withoutEmailTemplate: PropTypes.bool,
    actionsType: PropTypes.oneOf(Object.values(actionTypeMap)),
    percentageValue: PropTypes.number,
    index: PropTypes.number,
    units: PropTypes.string,
    alertType: PropTypes.string,
    error: PropTypes.string,
    onDelete: PropTypes.func,
    withoutOnDemand: PropTypes.bool,
    onDemandSettings: PropTypes.object,
};

export default TachometerAlertTemplate;
