import React from "react";
import Helper from "../../../../../../helpers/helper";
import auth from "../../../../../../services/auth";
import {useHistory} from "react-router";
import {useChartStateStoreContext} from "../../store/hooks/useStoreContext";
import {shallow} from "zustand/shallow";

const ManageAlertButtons = () => {
    const {seriesList, selectedPoint, chartType} = useChartStateStoreContext((state) => state.settings, shallow);

    const isLoading = useChartStateStoreContext((state) => state.isLoading);
    const history = useHistory();

    const addAlert = () => {
        history.push(
            Helper.setHashParams({
                modal: "alert",
                type: chartType,
                axisId: seriesList.selected[0],
                installationPointId: selectedPoint,
            })
        );
    };

    if (!auth.userCan("editAlarms")) {
        return null;
    }

    return (
        <span className={`${isLoading ? "disabled" : ""}`}>
            <span
                className={`alert-link ${isLoading ? "pe-none" : ""}`}
                onClick={addAlert}
            >
                <i className="fa fa-pen in-blue-circle" />
                Manage Alerts
            </span>
        </span>
    );
};

export default ManageAlertButtons;
