import {useQuery} from "@tanstack/react-query";
import ApiReading from "../../../api/reading";

const getChartFftAverageDataKey = ({installationPointId, followingType, ...options}) => [
    "FFT_AVERAGE_DATA",
    {installationPointId, followingType, ...options},
];

export const useChartFftAverageDataQuery = ({installationPointId, followingType, options}) => {
    return useQuery({
        queryFn: ({signal}) => ApiReading.getChartFFtAverageData(installationPointId, followingType, options, signal),
        queryKey: getChartFftAverageDataKey({installationPointId, followingType, ...options}),
        select: (res) => res.response,
        enabled: !!followingType && +followingType !== 1,
    });
};
