/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import {useAlertConditionListQuery} from "../../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {useInstallationPointIds} from "../../../../hooks/helpers/useInstallationPointIds";
import {get as _get} from "lodash";
import {useAlertConditionStoreActions, useCurrentAlertGroup} from "../../../../stores/zustand/AlertConditionStore";
import AlertSensorMultipleSelect from "../../shared/alertSensorMultipleSelect";
import {useChartComputed} from "../../hooks/helpers/chart";
import AlertGroupManagement from "../../shared/alertGroupManagement";
import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import useAxisLabelQuery from "../../../../hooks/api/axisLabel/useAxisLabelQuery";
import {AlertList} from "../AlertList/AlertList";

const TabDelta = ({chartTypes}) => {
    const {equipmentItem: equipment} = useEquipmentByParams();

    return (
        <>
            <AlertGroupManagement
                equipment={equipment}
                chartTypes={chartTypes}
            />
            <AlertSensorMultipleSelect
                equipment={equipment}
                chartTypes={chartTypes}
            />
            <AlertList variant="delta" />
        </>
    );
};

TabDelta.propTypes = {
    chartTypes: PropTypes.array,
    equipment: PropTypes.object,
    axisLabels: PropTypes.object,
};

export default TabDelta;
