import {useEquipmentByParams} from "../../../../hooks/api/equipment/useEquipmentQuery";
import {useChartType} from "../../hooks/helpers/chart";
import AlertSensorSelect from "../../shared/alertSensorSelect";
import {AlertList} from "../AlertList/AlertList";
import PropTypes from "prop-types";
import React from "react";

const TabTachometer = ({chartTypes}) => {
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {chartType} = useChartType(chartTypes);

    return (
        <>
            <AlertSensorSelect
                chartType={chartType}
                equipment={equipment}
            />
            <AlertList variant="tachometer" />
        </>
    );
};
TabTachometer.propTypes = {
    chartTypes: PropTypes.array,
    equipment: PropTypes.object,
    axisLabels: PropTypes.object,
};
export default TabTachometer;
