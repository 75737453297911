import {useAlertConditionListQuery} from "../../../hooks/api/alertCondition/useAlertConditionListQuery";
import {filter as _filter} from "lodash";
import {createAlert} from "./helpers/createAlert";
import {useChartComputed, useChartType} from "./helpers/chart";
import {useChartTypesStore} from "../../../stores/zustand/ChartSelectedChartTypeStore";
import {useEquipmentByParams} from "../../../hooks/api/equipment/useEquipmentQuery";
import {useInstallationPoint} from "./useInstallationPoint";
import useAlertLevelsList from "../../../hooks/api/Alert/useAlertLevelList";
import {useCurrentAlertGroup, useSelectAlertShowAdditionalAlert, useSelectedTab} from "../../../stores/zustand/AlertConditionStore";
import {useMemo} from "react";
import {fftAlertTypes} from "../../../constants/constants";
import {CONDITIONS_MAP, getAlertConditions} from "../../../helpers/alerts/getAlertConditions";
import {ALERT_TABS_NAME} from "../constants/tabs";
import {AXIS_NAME} from "../../../constants/axis";

// TODO: investigate why constant cause webpack crash
const tabMapForFft = {
    band: fftAlertTypes.BAND,
    envelope: fftAlertTypes.ENVELOPE,
};

const alertMap = {
    band: "band",
    envelope: "envelope",
    linear: "simple",
    "delta-t": "simple",
    "tachometer-rpm": "tachometer",
};

export const useTabAlerts = ({withAlertGroup = false} = {}) => {
    const chartTypes = useChartTypesStore();
    const {chartType, axisId, readingType} = useChartComputed(chartTypes);
    const {isChartFFT} = useChartType(chartTypes);
    const {equipmentItem: equipment} = useEquipmentByParams();
    const {alertLevelsList: levels, isLoading: isLevelLoading, isRefetching: isLevelRefetching} = useAlertLevelsList();
    const alertGroup = useCurrentAlertGroup();
    const additionalAlerts = useSelectAlertShowAdditionalAlert();

    const installationPoint = useInstallationPoint(equipment, chartType);
    const {id: installationPointId} = installationPoint;
    const {
        alertConditions: alertList,
        alertListByGroups,
        isRefetching: isAlertListRefetching,
        isLoading: isAlertListLoading,
        isFetched,
        allAxisGroup,
    } = useAlertConditionListQuery(chartType, withAlertGroup ? alertGroup.points : installationPointId, withAlertGroup ? null : installationPointId);

    const currentTab = useSelectedTab();

    const groupById = alertListByGroups?.[alertGroup.id] || [];
    // TODO: remake computing for delta-t, simple alerts
    const alerts = useMemo(
        () =>
            levels.flatMap((level) => {
                let alerts = [];

                if (currentTab === ALERT_TABS_NAME.DELTA_T) {
                    alerts = groupById.filter((item) => item.alertLevel.id === level.id);
                } else if (+axisId === AXIS_NAME.ALL) {
                    alerts = allAxisGroup.filter((item) => item.alertLevel.id === level.id);
                } else {
                    alerts = _filter(
                        alertList,
                        (alert) =>
                            +alert.alert_level_id === +level.id &&
                            (isChartFFT
                                ? +alert.axis_id === axisId && +alert.fftAlertType === +tabMapForFft[currentTab]
                                : +alert.reading_type_id === readingType &&
                                  !!alert.is_tachometer_rpm === (currentTab === ALERT_TABS_NAME.TACHOMETER_RPM)) &&
                            !alert.group_id
                    );
                }

                if (
                    (currentTab === ALERT_TABS_NAME.BAND || (currentTab === ALERT_TABS_NAME.TACHOMETER_RPM && +chartType !== 14)) &&
                    +axisId === AXIS_NAME.ALL
                ) {
                    alerts = [];
                }

                if (additionalAlerts[level.name] || !alerts.length) {
                    alerts.push(
                        createAlert({
                            variant: alertMap[currentTab],
                            axisId,
                            chartType,
                            equipment,
                            readingType,
                            level,
                            installationPoint,
                            id: `${level.name}_created`,
                        })
                    );
                }

                return alerts.map((item, index, list) => {
                    if (item.fftAlertType === fftAlertTypes.ENVELOPE) {
                        return item;
                    }

                    if (currentTab === ALERT_TABS_NAME.BAND || (currentTab === ALERT_TABS_NAME.TACHOMETER_RPM && +chartType !== 14)) {
                        return {...item, availableConditions: CONDITIONS_MAP, condition: item.condition || Object.keys(CONDITIONS_MAP)[0]};
                    }

                    const availableConditions = getAlertConditions(item, index, list);
                    const condition = item.condition || Object.keys(availableConditions)[0];

                    return {...item, availableConditions, condition};
                });
            }),
        [
            isLevelLoading,
            isLevelRefetching,
            isChartFFT,
            isAlertListRefetching,
            isAlertListLoading,
            axisId,
            readingType,
            additionalAlerts,
            currentTab,
            alertList,
            alertGroup,
        ]
    );

    return {alerts, isLoading: isLevelLoading || isAlertListLoading, isFetched};
};
